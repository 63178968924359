import React from 'react';
import './DialogBox.styles.scss';

const DeleteAccountDialog = ({ isOpen, onClose,clickPrimaryButton,buttonVisible,dialogBoxText,textPrimaryButton,textSecondaryButton,dialogboxIcon }) => {
  console.log(isOpen);
  if (isOpen === null) return null;
  if (!isOpen) return null;

  return (
    <div className="overlay">
      <div className="dialog">
        <div className='dialog-box-header-wrapper'>
            <img src={dialogboxIcon} className='icon-image'></img>
            <p className="dialog-box-message">{dialogBoxText}</p>
        </div>
        {buttonVisible ?(<div className="dialog-box-buttons">
          <button className="confirmButton" onClick={clickPrimaryButton}>{textPrimaryButton}</button>
          <button className="cancelButton" onClick={onClose}>{textSecondaryButton}</button>
        </div>) : null}
      </div>
    </div>
  );
};

export default DeleteAccountDialog;
