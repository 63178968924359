import { useState, useEffect } from 'react'

export function useFormValidation() {
  const [formErrors, setFormErrors] = useState({userName: '', email: '', password: ''});
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    if(validateForm(formErrors)){
      console.log("ent1: ",btnDisabled)
        setBtnDisabled(false);
    } else {
      console.log("ent2: ",btnDisabled)
        setBtnDisabled(true);
    }
    },[formErrors])

  const validEmailRegex = 
  // eslint-disable-next-line no-useless-escape
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  const checkForFormErrors = ( name, value ) => {
    let errors = {...formErrors};
    switch (name) {
      case 'userName': 
        errors.userName = 
            value.length < 4
            ? 'Full Name must be 4 characters long!'
            : '';
        break;
      case 'email': 
        errors.email = 
          validEmailRegex.test(value)
            ? ''
            : 'Kindly enter a valid email';
        break;
      case 'password': 
        errors.password = 
          value.length < 6
            ? 'Password must be 6 characters long!'
            : '';
        break;
      default:
        break;
    }

    setFormErrors(errors);
  }

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }


    return {
        formErrors,
        checkForFormErrors,
        validateForm,
        btnDisabled,
        setBtnDisabled,
    }
}
