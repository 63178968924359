    import React from 'react';

const SpeechBubble = ({ fillColor = '#FFECBF', className }) => (
    <svg className={className} version="1.1" viewBox="0 0 2048 778" width="100%" height="100%" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <path transform="translate(48)" d="m0 0h1939l4 4 9 6 7 8 5 16 7 3 10 4 8 6 7 9 4 6v445l-7 10-7 8-8 5-10 2-66 1h-1258l-34 1-7 3-8 6-6 7-13 22-16 28-14 24-30 52-14 24-16 28-13 22-8 9-9 4-9 2h-12l-10-4-9-6-7-9-8-13-6-5-11-6-8-8-12-19-14-24-16-28-11-19-14-24-15-26-14-24-8-14-2-2-221-1-10-1-14-9-9-10-5-15-11-4-9-6-6-5-7-10-2-4-1-6v-417l1-16 4-10 6-8 9-8z" fill={fillColor}/>
    <path transform="translate(48)" d="m0 0h1939l4 4 9 6 7 8 5 16 7 3 10 4 8 6 7 9 4 6v445l-7 10-7 8-8 5-10 2-66 1h-1258l-34 1-7 3-8 6-6 7-13 22-16 28-14 24-30 52-14 24-16 28-13 22-8 9-9 4-9 2h-12l-10-4-9-6-7-9-8-13-6-5-11-6-8-8-12-19-14-24-16-28-11-19-14-24-15-26-14-24-8-14-2-2-221-1-10-1-14-9-9-10-5-15-11-4-9-6-6-5-7-10-2-4-1-6v-417l1-16 4-10 6-8 9-8zm14 12-11 1-8 7-5 8-1 3v437l3 5 7 7 5 3 4 1 221 1 16 3 12 6 7 6 10 15 16 29 10 17 14 24 13 23 24 42 15 26 17 28 8 9 8 4h13l6-3 8-7 8-13 13-23 6-11 10-17 28-48 10-18 14-24 15-26 12-21 7-8 8-7 12-5 5-1 28-1h1315l19-1 11-11 3-5v-440l-7-8-8-6-2-1z" fill="#030202"/>
    <path transform="translate(61,13)" d="m0 0h183l21 1v-1h57l4 1 57-1h1534v1l-9 1-32 1-774 1h-1009l-20 2-10 3-5 3-2 4-4 3h-2v-2l-3 1-1-3 4-6v-2l-1-3 3-3h8z" fill={fillColor}/>
    <path transform="translate(1966,14)" d="m0 0h19l12 11 1 2v440l-6 8-7 7h-13l-9-3v-2l10-1h6v2l5-3 3-4 3-7h2l1-4v5l1 1v-342l1-37v-26l-1-18-1 5h-1l-1-9-6-12v-2h-3l4 10-1 3v3l-2-1-5-10-7-9-3-5z" fill={fillColor}/>
    <path transform="translate(43,23)" d="m0 0 1 2-3 9v34l-1 181v162l-2 1v-384z" fill={fillColor}/>
    <path transform="translate(1966,14)" d="m0 0h19l12 11 1 2v50l-1 10h-1l-2-44-1 5h-1l-1-9-6-12v-2h-3l4 10-1 3v3l-2-1-5-10-7-9-3-5z" fill={fillColor}/>
    <path transform="translate(62,12)" d="m0 0h319v1l-5 1-53 1-1-1h-54l-4 1-24-1h-43l-135-1z" fill={fillColor}/>
    <path transform="translate(1763,13)" d="m0 0h154v1l-9 1-32 1h-115l2-1z" fill={fillColor}/>
    <path transform="translate(265,13)" d="m0 0h57l4 1 5 1v1h-65z" fill={fillColor}/>
    <path transform="translate(1946,478)" d="m0 0h14v1l-13 3-1 1h-47l-1-3 43-1 5 1z" fill={fillColor}/>
    <path transform="translate(602,490)" d="m0 0h2l-1 3-8 7-5 5h-2l-2 5-6 11h-2l-1 5h-3l1-5v-3l7-7 4-9 11-11z" fill={fillColor}/>
    <path transform="translate(1989,470)" d="m0 0 2 1-2 5-2 4-2 2h-13l-9-3v-2l10-1h6v2l5-3z" fill={fillColor}/>
    <path transform="translate(38,468)" d="m0 0 6 1 11 11 14 1v1l-17 1-8-6-6-6z" fill={fillColor}/>
    <path transform="translate(1940,18)" d="m0 0h22l7 3 8 8 2 4-1 4-6-8-10-7-2-2-20-1z" fill={fillColor}/>
    <path transform="translate(90,21)" d="m0 0h5l-4 3-22 4-3 3-7 3-2 1 1-6 11-4z" fill={fillColor}/>
    <path transform="translate(1946,478)" d="m0 0h14v1l-15 3h-47v-2l43-1 5 1z" fill={fillColor}/>
    <path transform="translate(602,490)" d="m0 0h2l-1 3-8 7h-3l-2-2 7-7z" fill={fillColor}/>
    <path transform="translate(557,555)" d="m0 0 1 3-11 19-2-1 2-6 4-4 3-8z" fill={fillColor}/>
    <path transform="translate(51,13)" d="m0 0h10l-2 2h-7l-7 8-5 3 2-4 5-5z" fill={fillColor}/>
    <path transform="translate(558,557)" d="m0 0 1 3-10 17-2-2z" fill={fillColor}/>
    <path transform="translate(588,505)" d="m0 0 2 1-12 21-2-3 2-3h2l2-5z" fill={fillColor}/>
    <path transform="translate(525,604)" d="m0 0h2l-2 4-7 12-2 4-2-1 6-11z" fill={fillColor}/>
    <path transform="translate(497,652)" d="m0 0 1 2-5 11-3 1 2-7z" fill={fillColor}/>
    <path transform="translate(52,14)" d="m0 0h4l-1 5-5 1-1-3z" fill={fillColor}/>
    </svg>

);

export default SpeechBubble;
