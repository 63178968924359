import React, { useState } from 'react';
import './ProfilePage.styles.scss';
import { Button, ButtonBase } from '@material-ui/core';
import EditImg from '../../images/tabler_edit.png';
import FormInput from '../../components/FormInput/FormInput.component';
import Bugsnag from '@bugsnag/js';
import { useFormValidation } from '../../customHooks/formValidationHook';
import Swal from 'sweetalert2';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import SecondaryCustomButton from '../../components/SecondaryCustomButton/SecondaryCustomButton.component';
import deleteImg from '../../images/delete-white-icon.svg';
import DeleteAccountDialog from '../../components/DialogBox/DialogBox.component';
import DeleteIcon from '../../images/material-symbols_delete (1).svg';
import TickIcon from '../../images/weui_done2-filled.svg';
import MarkImage from '../../images/mdi_error.svg';
import SpeechBubble from '../../components/SpeechBubble/SpeechBubble.component';

function ProfilePage({ user, subscription }) {
    const { formErrors,validateForm,checkForFormErrors,btnDisabled} = useFormValidation();

    const [username, setUsername] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [editMode, setEditMode] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isDeleteSuccess, setDeleteSuccess] = useState(null);

    const handleOpenDialog = () => setDialogOpen(true);
    const handleCloseDialog = () => setDialogOpen(false);
    const handleSuccessClose = () => {
        setDeleteSuccess(null);
        setDialogOpen(false);
    }
    const handleChange = event => {
        const { value, name } = event.target;
        checkForFormErrors(name, value);
        if (name === 'userName') {
            setUsername(value);
        } else if (name === 'email') {
            setEmail(value);
        }
    };
    const onClickEdit = () => {
        checkForFormErrors("userName",username)
        checkForFormErrors("email", email);
        setEditMode(true);
    };

    const onClickCancel = () => {
        setUsername(user.name);
        setEmail(user.email);
        setEditMode(false);

    };
    const onClickLoadSetting = async () => {
        try {

                const token = window.localStorage.getItem("token");
                if (token) {
                    await fetch(`${process.env.REACT_APP_API_ENDPOINT}manage-payment-portal`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            token: token,
                        },
                    }).then(async (result) => {
                        const res = await result.json()
                        if (res.url) {
                            window.location.href = res.url;
                        }
                    }).catch(err => console.log("error: fetch API on selected plan =>", err))
                }
        } catch (error) {
            console.log("error on click selected plan =>", error)
        }
    }
    const onProfileUpdate = () => {
        const token = window.localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_API_ENDPOINT}user/update-user-profile`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify({ 
                formInput: { email, name: username }
            })
        }) 
        .then(response => response.json())
        .then(data => {
            setEditMode(false)
            this.props.toggleModal();
            this.props.loadUser({ ...this.props.user, ...this.state })
        })
        .catch(err => {
            console.log({err});
            Bugsnag.notify(err);
        });
    };
    const handleConfirmDelete = () => {
        const token = window.localStorage.getItem('token');
    
        fetch(`${process.env.REACT_APP_API_ENDPOINT}user/`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'token': token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              localStorage.clear();
              setDeleteSuccess(true);
              window.location = '/login';
            } else {
              setDeleteSuccess(false);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            alert("An error occurred while deleting the account.");
          });
    
        // onClose();
      };
    const onAccountDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: (login) => {
                const token = window.localStorage.getItem('token');
                return fetch(`${process.env.REACT_APP_API_ENDPOINT}user/`, {
                    method: 'delete',
                    headers: {
                        'Content-Type': 'application/json',
                        'token': token
                    }
                }).then(response => {
                    if (!response.ok && response.status !== 400) {
                        throw new Error(response.statusText)
                    }
                    return response.json()
                })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
            },
        })
            .then((result) => {
                if (result.isConfirmed) {
                    if (result.value.success) {
                        localStorage.clear();
                        let timerInterval;
                        Swal.fire({
                            title: 'Deleted!',
                            html: '<span style="color:orange;">We are looking forward to see you again.</span><br/><br/><sup>Redirecting to Home in <b></b> seconds.</sup>',
                            timer: 10000,
                            icon: 'success',
                            confirmButtonText: 'Redirect now',
                            timerProgressBar: true,
                            allowOutsideClick: false,
                            didOpen: () => {
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                    b.textContent = Math.ceil(Swal.getTimerLeft() / 1000)
                                }, 600)
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                                window.location = '/login';
                            }
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'You have 1 active subscription.',
                            text: `You can only delete your account once your subscription is over.`,
                            footer: '<a href="/pixie/pricing">Check your Subscriptions here</a>'
                        })
                    }
                }
            })
    }

    const onPlans = () =>{
        window.location.href = "/pixie/pricing"
    }

    return (
        <div className="profile-main">
            <div className='profile-container'>
                <div className="profile-heading">
                    Profile Details
                </div>
                {user.type === "BASIC" && !editMode && (
                    <div className='edit-profile' onClick={onClickEdit}><img src={EditImg} alt="Edit" /></div>
                )}
            </div>
            <div className='profile-box-wrapper'>
                <div className='form-profile'>
                <div className='details-container-name'>
                    {editMode &&(formErrors.userName &&                     
                    <div className="speech-bubble-wrapper-profile">
                        <SpeechBubble
                        className={"speech-bubble"}
                        fillColor={'#FFB8B8'}
                        />
                        <div className='speech-bubble-content-wrapper'>
                            <p className='speech-bubble-content'>{formErrors.userName}</p>
                        </div>
                    </div>)}
                    <div className='name-container'>Name</div>
                    <div>
                        <FormInput  name="userName" className={`box-input-signup details-text ${formErrors.userName && editMode? 'error-box' : ''} ${editMode ? 'edit-mode' : ''}`} disable={!editMode} value={username} handleChange={handleChange}/>
                        {/* {editMode &&(formErrors.userName && <span className='error'>{formErrors.userName}</span>)} */}
                    </div>
                </div>
                <div className='details-container-email'>
                    {/* <SpeechBubble
                        className={"speech-bubble"}
                        fillColor={'#FFB8B8'}
                        />
                        <div className='speech-bubble-content-wrapper'>
                            <p className='speech-bubble-content'>Kindly enter a valid Email</p>
                        </div> */}
                    {editMode &&(formErrors.email &&                     
                    <div className="speech-bubble-wrapper-profile">
                        <SpeechBubble
                        className={"speech-bubble"}
                        fillColor={'#FFB8B8'}
                        />
                        <div className='speech-bubble-content-wrapper'>
                            <p className='speech-bubble-content'>{formErrors.email}</p>
                        </div>
                    </div>)}
                    <div className='email-container'>Email</div>
                    <div>
                        <FormInput type="email" name="email" disable={!editMode} className={`box-input-signup details-text ${formErrors.email && editMode? 'error-box' : ''} ${editMode ? 'edit-mode' : ''}`} value={email} handleChange={handleChange}/>
                        {/* {editMode &&(formErrors.email && <span className='error'>{formErrors.email}</span>)} */}
                    </div>
                </div>
                {/* <div className='payment-details'>
                    <div className='payment-container'>Credit/Debit Card</div>
                    <div className='billing' onClick={subscription.isSubscribed?onClickLoadSetting:onPlans}>Billing Portal</div>
                </div> */}
                </div>
                <div className='profile-buttons-wrapper'>
                {editMode ? (
                    <div className="actions-btn">
                        <div className='save-cancel'>
                            <SecondaryCustomButton customClasses={'profile-custom-button'} orange={true} disabled={btnDisabled} type='submit' black={true} onClick={onProfileUpdate}>
                                Save
                            </SecondaryCustomButton>
                            <SecondaryCustomButton customClasses={'profile-custom-button'} black={true} onClick={onClickCancel}>
                                Cancel
                            </SecondaryCustomButton>
                        </div>
                    </div>
                ) : (
                    user.type === "BASIC" && (
                        <CustomButton className="delete-btn-wrapper" onClick={handleOpenDialog}>
                            <img className="icon-delete-white" src={deleteImg} alt="Delete Icon" />
                            <div className="delete-btn">Delete Account</div>
                        </CustomButton>
                    )
                )}
                </div>
            </div>
            
            {
                isDeleteSuccess !== null ? (            
                <>
                <DeleteAccountDialog isOpen={isDeleteSuccess} onClose={handleCloseDialog} clickPrimaryButton={handleConfirmDelete} dialogBoxText={"Your account has successfully been Deleted!"} buttonVisible={false} dialogboxIcon={TickIcon}/>
                <DeleteAccountDialog isOpen={!isDeleteSuccess} onClose={handleSuccessClose} clickPrimaryButton={handleConfirmDelete} dialogBoxText={"Sorry! We can’t delete your account since you have an Active Subscription"} buttonVisible={true} textPrimaryButton={"Check Active Plan"} textSecondaryButton={"Cancel"} dialogboxIcon={MarkImage}/>
                </>
            ):  <DeleteAccountDialog isOpen={isDialogOpen} onClose={handleSuccessClose} clickPrimaryButton={handleConfirmDelete} dialogBoxText={"Are you sure you want to delete this account?"} buttonVisible={true} textPrimaryButton={"Yes"} textSecondaryButton={"No"} dialogboxIcon={DeleteIcon}/>
            }

        </div>
    );
}

export default ProfilePage;
