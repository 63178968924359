import React from "react";
import "./Credits.styles.scss";
import convertToWebPImage from '../../images/convert-to-webp.png';
import imageOptimizer from '../../images/image optimizer.png';
const moment = require('moment');
function Credits({userImageCount,userPlanQuota,remainingCredits,currentSubscription}) {
    console.log(userImageCount);
    console.log(userPlanQuota);
    console.log(currentSubscription);
    let imageOptimizationsDone;
    let creditsGiven;
    console.log(remainingCredits)
    if(remainingCredits && remainingCredits.length > 0){
      const prvAllowedImages = remainingCredits.map((allowedImages) => allowedImages.creditsLeft);
      const sumOfPrvAllowedImages = prvAllowedImages.reduce((acc, curr) => acc + curr, 0);
      const prvUsedImages = remainingCredits.map((allowedImages) => allowedImages.usedCredits);
      const sumOfprvUsedImages = prvUsedImages.reduce((acc, curr) => acc + curr, 0);
      imageOptimizationsDone = userImageCount + sumOfprvUsedImages;
      creditsGiven = userPlanQuota + sumOfPrvAllowedImages;
    }else{
      imageOptimizationsDone = userImageCount;
      creditsGiven = userPlanQuota
    }

    const overage = imageOptimizationsDone - creditsGiven;
    let overagePrice;
    if(overage > 0){
        overagePrice = overage * currentSubscription?.usage_price
    }

    return(
        <div className="credits-main">
            <h1 className="credits-heading">Credits</h1>
            <div className="credits-details-section">
                <div className="credits-left-section">
                    <div className="credits-box">
                        <div className="credits-data">Used Credits: &nbsp; {currentSubscription?.isSubscribed? imageOptimizationsDone: 'None'}</div>
                        <div className="credits-data">
                            Overage: &nbsp; 
                            {currentSubscription?.isSubscribed ? (
                                overage > 0 ? (
                                <>
                                    {`${overage} ($${currentSubscription.usage_price}/credit) = ${overagePrice}`}
                                    <br />
                                    <span className="overage-subtext">to be expected with the next invoice</span>
                                </>
                                ) : (
                                "No Overage"
                                )
                            ) : (
                                'None'
                            )}
                        </div>
                        <div className="credits-data">Credits reset on: &nbsp; {currentSubscription?.isSubscribed? (moment(currentSubscription.startDate).format("DD MMM YYYY")) : 'None'}</div>
                    </div>
                </div>
                <div className="credits-right-section">
                    <div className="credit-details-wrapper">
                        <div className="credits-details-heading">How Credits work</div>
                        <div className="credits-points-wrapper">
                            <ul className="credit-details-points">
                                <li>Already optimized images are tracked and not optimized again</li>
                                <li>Each image optimization uses one credit</li>
                            </ul>
                        </div>
                    </div>
                    <div className="credits-box-section">
                        <div className="credit-details-box">
                            <img className="inner-box-image" src={imageOptimizer}></img>
                            <div className="credit-details-text-wrapper">
                                <div className="credit-details-text-rhs">
                                    Each image compressed + resized in Optimization Option
                                </div>
                                <div className="credit-details-text-equal">=</div>
                                <div className="credit-details-text-lhs">1 credit utilization</div>
                            </div>
                        </div>
                        <div className="credit-details-box">
                            <img className="inner-box-image" src={convertToWebPImage}></img>
                            <div className="credit-details-text-wrapper">
                                <div className="credit-details-text-rhs">
                                    Each image converted to WebP
                                </div>
                                <div className="credit-details-text-equal">=</div>
                                <div className="credit-details-text-lhs">1 credit utilization</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Credits;