import React, { useState, useRef, useEffect } from 'react';
import DropdownImage from "../../images/dropdown-arrow.svg";
import './CustomDropdown.styles.scss';

const CustomDropdown = ({ caseObj, field, handleOptionChange, dropdownId, openDropdownId, setOpenDropdownId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Don't Resize");
    const [dropdownDirection, setDropdownDirection] = useState('down');
    const dropdownHeaderRef = useRef(null);
    const dropdownListRef = useRef(null);
    const topDownSpace = 7.59;

    useEffect(() => {
        if (isOpen) {
            setOpenDropdownId(dropdownId);
        }
    }, [isOpen, dropdownId, setOpenDropdownId]);

    useEffect(() => {
        const closeDropdownOnOutsideClickOrScroll = (e) => {
            if (dropdownListRef.current && !dropdownListRef.current.contains(e.target) &&
                dropdownHeaderRef.current && !dropdownHeaderRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', closeDropdownOnOutsideClickOrScroll);
            document.addEventListener('scroll', closeDropdownOnOutsideClickOrScroll, true);
        }

        return () => {
            document.removeEventListener('mousedown', closeDropdownOnOutsideClickOrScroll);
            document.removeEventListener('scroll', closeDropdownOnOutsideClickOrScroll, true);
        };
    }, [isOpen]);

    useEffect(() => {
        if (openDropdownId !== dropdownId) {
            setIsOpen(false);
        }
    }, [openDropdownId, dropdownId]);

    const toggleDropdown = () => {
        if (!isOpen) {
            adjustDropdownDirection();
        }
        setIsOpen(!isOpen);
    };

    const adjustDropdownDirection = () => {
        if (dropdownHeaderRef.current) {
            const headerRect = dropdownHeaderRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            const dropdownHeight = dropdownListRef.current ? dropdownListRef.current.offsetHeight : 364;
            const spaceBelow = viewportHeight - headerRect.bottom;
            const spaceAbove = headerRect.top;

            if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
                setDropdownDirection('up');
            } else {
                setDropdownDirection('down');
            }
        }
    };

    const handleOptionClick = (value, fieldId, displayName) => {
        setSelectedOption(displayName);
        
        const customEvent = {
            target: {
                value,
                selectedOptions: [{ dataset: { field: fieldId } }]
            }
        };
        handleOptionChange(customEvent);
        setIsOpen(false);
    };

    const dropdownMaxHeight = Math.max(
        window.innerHeight - dropdownHeaderRef.current?.getBoundingClientRect().bottom,
        dropdownHeaderRef.current?.getBoundingClientRect().top
    ) > 364 ? null : Math.max(
        window.innerHeight - dropdownHeaderRef.current?.getBoundingClientRect().bottom,
        dropdownHeaderRef.current?.getBoundingClientRect().top
    );

    return (
        <div className="selector-class dropdown-container">
            <div onClick={toggleDropdown} className="dropdown-header" ref={dropdownHeaderRef}>
                <span>{selectedOption}</span>
                <img src={DropdownImage} alt="Dropdown Arrow" className="dropdown-arrow" />
            </div>
            {isOpen && (
                <div
                    ref={dropdownListRef}
                    className="dropdown-list"
                    style={{
                        top: dropdownDirection === 'down'
                            ? dropdownHeaderRef.current.getBoundingClientRect().top + dropdownHeaderRef.current.getBoundingClientRect().height + topDownSpace
                            : `calc(0% + ${dropdownHeaderRef.current.getBoundingClientRect().top - (dropdownMaxHeight || (dropdownListRef.current ? dropdownListRef.current.offsetHeight : 364))-topDownSpace}px)`,
                        maxHeight: dropdownMaxHeight || 364,
                        width: dropdownHeaderRef.current.getBoundingClientRect().width,
                    }}
                >
                    {caseObj.map((echOpt) => {
                        const parts = echOpt.optionName.split(':');
                        var displayText = parts.length === 2 ? parts[1].trim() : echOpt.optionName;
                        if (displayText.endsWith('px')) {
                            displayText = displayText.slice(0, -2); 
                        }
                        return (
                            <div
                                key={echOpt.id}
                                onClick={() => handleOptionClick(echOpt.valueName, field.id, displayText)}
                                className="dropdown-list-item"
                                data-field={field.id}
                            >
                                {displayText}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
