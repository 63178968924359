import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";
import { useHistory, useLocation } from "react-router-dom";
import ActionAlerts from "../../components/Alert/Alert.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import FormInput from "../../components/FormInput/FormInput.component";
import PixieIcon from '../../images/pixie icon.png';
import HappyImage from '../../images/right-happy-image.png';
import SadImage from '../../images/SadImage.png';
import PixieText from '../../images/Pixie.svg';
import { useFormValidation } from "../../customHooks/formValidationHook";
import SpeechBubble from '../../components/SpeechBubble/SpeechBubble.component';
import LoaderDots from '../../components/DotsLoader/Loader.component';
import AlertMessage from "../../components/AlertMessage/AlertMessage.component";
import "./ResetPassword.styles.scss";

const ResetPassword = ({ isSignedIn }) => {
  const [userEmail, setUserEmail] = useState("");
  const [btnText, setBtnText] = useState("Submit");
  const [message, setMessage] = useState({ msg: "", type: "" });
  const [showForm, setShowForm] = useState(true);
  const [isFilled, setIsFilled] = useState(false);
  const [speechBubbleText, setSpeechBubbleText] = useState({ text: 'Enter your email to reset your password!', type: 'statement' });
  const {
    formErrors,
    validateForm,
    checkForFormErrors,
    btnDisabled,
    setBtnDisabled,
  } = useFormValidation();

  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (isSignedIn) {
      history.replace(from);
    }
  }, [from, history, isSignedIn]);

  const handleChange = (event) => {
    const { value, name } = event.target;
    checkForFormErrors(name, value);
    setUserEmail(value);
    setIsFilled(value.trim() !== '');
  };

  useEffect(() => {
    if (formErrors.userName) {
        setSpeechBubbleText({ text: formErrors.userName, type: 'error' });
    } else if (formErrors.email) {
        setSpeechBubbleText({ text: formErrors.email, type: 'error' });
    } else if (formErrors.password) {
        setSpeechBubbleText({ text: formErrors.password, type: 'error' });
    } else {
        setSpeechBubbleText({ text: 'Enter your details and create an Account with us!', type: 'statement' });
    }
}, [formErrors]);

  const onSubmit = (event) => {
    event.preventDefault();
    if (validateForm(formErrors)) {
      setBtnDisabled(true);
      setBtnText(<LoaderDots />);
      setSpeechBubbleText({ text: "Kindly Wait..", type: "statement" });
      fetch(`${process.env.REACT_APP_API_ENDPOINT}user/recover`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userEmail,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setUserEmail("");
          if (data.success) {
            setBtnText("Sent");
            setMessage({
              msg: "Successfully sent, Please check your Email",
              type: "success",
            });
            setSpeechBubbleText({ text: "Successfully sent, Please check your Email", type: 'statement' });
            setShowForm(false);
          } else {
            console.log(data.message);
            setMessage({ msg: data.message, type: "error" });
            setSpeechBubbleText({ text: data.message, type: 'statement' });
            setBtnText("Submit");
          }
        })
        .catch((err) => {
          console.log(err);
          Bugsnag.notify(err);
        });
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-container-left">
        <div className="signup-form">
          <div className='logo'>
            <img className="icon" src={PixieIcon}/>
            <img src={PixieText}/>
          </div>
          <div className='heading-conatiner'>
            <h2 class="title">Forgot Password?</h2>
            <p>Enter your email address</p>
          </div>
        {/* {message.msg ? (
          <ActionAlerts text={message.msg} type={message.type} />
        ) : null} */}
        {showForm ? (
          <div className='form-container'>
            <form onSubmit={onSubmit} className='signup-fields'>
            <label htmlFor="name" className='label-text'>Email*</label>
              <FormInput
                className={`box-input-signup`}
                name="email"
                type="email"
                value={userEmail}
                handleChange={handleChange}
                required
              />
              {/* {formErrors.email && (
                <span className="error">{formErrors.email}</span>
              )} */}
              <div className="buttons">
                <div className="center tb-margin-20">
                  <CustomButton type="submit" disabled={btnDisabled}>
                    {btnText}
                  </CustomButton>
                </div>
              </div>
            </form>
          </div>
        ) : message.msg !== "" && (<AlertMessage message={message.msg} error={message.type}></AlertMessage>)}
      </div>
      </div>
      <div className="signup-container-right">
            <div className='content-container'>
                {/* <div class="speech-bubble">
                  Enter your email to reset your password
                </div> */}
                    <div className="speech-bubble-wrapper" >
                        <SpeechBubble
                            className={"speech-bubble"}
                            fillColor={speechBubbleText.type === 'error' ? '#FFB8B8' : '#FFECBF'}
                        />
                        <div className='speech-bubble-content-wrapper'
                            style={{ padding: speechBubbleText.text === "Kindly Wait.." && '3.5vh 3.906vw 8.5365vh 3.97135vw' }}>
                            <p className='speech-bubble-content'>{speechBubbleText.text}</p>
                        </div>
                    </div>
                    {
                        speechBubbleText.type === 'error' ? 
                        <img src={SadImage} className='right-image' alt="Right Image"></img>:
                        <img src={HappyImage} className='right-image' alt="Right Image"></img>
                    }            </div>
        </div>
    </div>
  );
};

export default ResetPassword;
