import React, { useState, useEffect, useContext, useRef } from "react";
import { getTokenFromLS } from "../../lib/helpers"; 
import './Logs.styles.scss';
import { Link } from 'react-router-dom';
import { saveData,getData,removeFromIndexedDB } from '../../dataFiles/indexedDB';
import CircularProgressIndicator from '../../components/CircularProgress/CircularProgress.component';
import curvedVector from '../../images/curved-vector.svg';
import { useJobHandler } from "../../customHooks/jobHandlerHook";
import { CollectionContext } from "../../contexts/CollectionContext";
import LinearProgress from '../../components/LinearProgressItem/LinearProgressItem.components';

const UserLogsPage = () => {
  const [userLogs, setUserLogs] = useState([]);
  const [logsEmpty, setLogsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [siteData, setSiteData] = useState([]);
  const [progressValue, setProgressValue] = useState(0);
  const [totalImagesData, setTotalImagesData] = useState({ totalImagesOptimizing: 0, totalImagesOptimized: 0 });
  const lineRefs = useRef([]);
  const {
    toOptimizeData
  } = useContext(CollectionContext);
  const { jobsInfo } =
    useJobHandler();
  useEffect(() => {
    fetchLogs();
    fetchSiteData();
  }, []);

  const fetchSiteData = async () => {
    try {
      const data = await getData('sites');
      setSiteData(data); // Store the site data
    } catch (error) {
      console.error('Error fetching site data:', error);
    }
  };

  useEffect(() => {
    const updateHeights = () => {
      lineRefs.current.forEach((lineRef) => {
        if (lineRef) {
          const logsTableContentWrapper = lineRef.closest('.logs-box').querySelector('.logs-table');
          if (logsTableContentWrapper) {
            const collectionNames = logsTableContentWrapper.querySelectorAll('.collection-name');
            const curveVectors = logsTableContentWrapper.querySelectorAll('.curve-vector');
            const lastFieldName = logsTableContentWrapper.querySelector('.logs-table-content-wrapper:last-child .logs-data-container:last-child .meta-item:last-child .fixed-width-122px');
            const lastFieldNameHeight = lastFieldName ? lastFieldName.offsetHeight : 0;
            const isSmallScreen = window.innerWidth < 2400;

            const adjustmentSmall = isSmallScreen 
              ? (lastFieldNameHeight > 20 ? '1.6vh' : '1.6vh') 
              : (lastFieldNameHeight > 60 ? '1.8vh' : '1.6vh');
            
            const adjustemetHeight = isSmallScreen 
              ? (lastFieldNameHeight > 20 ? 10 : 0) 
              : (lastFieldNameHeight > 60 ? 10 : 0);
            lineRef.style.top = adjustmentSmall;
            
            const firstCollectionName = collectionNames[0];
            const lastCurveVector = curveVectors[curveVectors.length - 1];
            
            if (firstCollectionName && lastCurveVector) {
              // Get the top positions of both elements
              const topOfFirstCollectionName = firstCollectionName.getBoundingClientRect().top;
              const topOfLastCurveVector = lastCurveVector.getBoundingClientRect().top + adjustemetHeight;
              // Calculate the height
              const newHeight = topOfLastCurveVector - topOfFirstCollectionName;
  
              // Set the height for lineRef
              lineRef.style.height = `${newHeight}px`;
            }
          }
        }
      });
    };
  
    const handleUpdateHeights = () => {
      requestAnimationFrame(updateHeights);
    };
  
    setTimeout(handleUpdateHeights, 0);
  
    window.addEventListener('resize', handleUpdateHeights);
    return () => window.removeEventListener('resize', handleUpdateHeights);
  }, [userLogs]);
  

  useEffect(() => {
    const calculateTotalImages = () => {
      let totalImagesOptimizing = 0;
      let totalImagesOptimized = 0;
      let totalFields = 0;
      let optimizedFields = 0;
  
      if (jobsInfo && typeof jobsInfo === 'object' && Object.keys(jobsInfo).length > 0) {
        Object.keys(jobsInfo).forEach((collectionKey) => {
          const jobArray = jobsInfo[collectionKey]; // Get the array for each collection
          if (Array.isArray(jobArray)) {
            jobArray.forEach((job) => {
              job.collectionFieldData.forEach((field) => {
                const imageCount = field.isCheckedItems.length > 0 ? field.isCheckedItems.length : field.imageCount;
                totalImagesOptimizing += imageCount;
                totalFields++;
  
                if (job.success) {
                  totalImagesOptimized += imageCount;
                  optimizedFields++;
                }
              });
            });
          }
        });
      } else {
        console.log("jobsInfo is empty or not a valid object");
      }
  
      setTotalImagesData({ totalImagesOptimizing, totalImagesOptimized });
  
      const progress = totalFields > 0 ? (100 / totalFields) * optimizedFields : 0;
      setProgressValue(progress);
    };
  
    calculateTotalImages();
  }, [jobsInfo]);

  const fetchLogs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-user-logs`, {
        headers: {
          'Content-Type': 'application/json',
          token: getTokenFromLS(),
        },
      });
      const data = await response.json();
      if (data.length === 0) {
        setLogsEmpty(true);
      }
      const sortedLogs = sortLogsByDateTime(data);
      console.log("sortedLogs: ",sortedLogs)
      setUserLogs(sortedLogs);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user logs:', error);
      setLogsEmpty(true);
      setIsLoading(false);
    }
  };
  
  const sortLogsByDateTime = (logs) => {
    return logs.sort((a, b) => {
      const dateTimeA = new Date(a.updatedAt);
      const dateTimeB = new Date(b.updatedAt);
      return dateTimeB - dateTimeA;
    });
  };

  const getUserTimezoneDate = (extractedDate) => {
    var date = new Date(extractedDate);
    var userOffset = new Date().getTimezoneOffset();
    userOffset = -userOffset;
    var userOffsetMillisecs = userOffset * 60 * 1000;
    var userDate = new Date(date.getTime() + userOffsetMillisecs);
    var formattedDate = userDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
    var formattedTime = userDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
    return formattedDate + ' ' + formattedTime;
  };
  const findSiteNameById = (siteId) => {
    if (!siteData || siteData.length === 0) {
      return 'Unknown Site';
    }
    
    const site = siteData.find(site => site.siteId === siteId);
    return site ? site.name : 'Unknown Site';
  };
  const ShowCollectionDetails = ({log}) =>{
    const totalOriginalSize = log.meta.reduce((acc, metaItem) => acc + metaItem.originalImagesSize, 0);
    const totalOptimizedSize = log.meta.reduce((acc, metaItem) => acc + metaItem.optimizedImagesSize, 0);
    const totalSavedSize = totalOriginalSize - totalOptimizedSize;
    return (
    <div className='meta-item'>
    <p className='data-text'>
      {log.meta[0].collectionName}
    </p>
    <p className='data-text'>
      {covertToKB(totalOriginalSize)}
    </p>
    <p className='data-text add-padding-left'>
      {covertToKB(totalOptimizedSize)}
    </p>
    <p className='data-text add-padding-left' >
      {covertToKB(totalSavedSize)}
    </p>
    {/* Assuming that 'metaItem.totalSavedBytes' actually represents the difference between original and optimized sizes */}
    <p className='add-margin-right detail-class' >
      <Link to={`/logs/${log.jobId}`} className="details-link">
        Details
      </Link>
    </p>
  </div>)
  }
  const groupLogsByDateAndCollection = () => {
    const groupedLogs = {};
    userLogs.forEach((log) => {
      const key = log.updatedAt.slice(0, -8); 
      if (!log.meta) {
        return;
      }
      const collectionName = Array.isArray(log.meta) ? log.meta[0].collectionName : log.meta.collectionName;
      const displayKey = log.optimizationId || collectionName;
      if (!groupedLogs[key]) {
        groupedLogs[key] = {};
      }
      if (!groupedLogs[key][displayKey]) {
        groupedLogs[key][displayKey] = [];
      }
      groupedLogs[key][displayKey].push(log);
    });
    return groupedLogs;
  };
  const extractSiteName = (logs) => {
    if (logs.length > 0 && logs[0].siteId) {
      return findSiteNameById(logs[0].siteId);
    }
    return "Unknown Site";
  };

  const covertToKB = (size) =>{
    return (size/1024).toFixed(2)+'KB'
  }
  const covertToMB = (size) =>{
    return (size / 1024).toFixed(2) + 'MB';
  }
  const groupedLogs = groupLogsByDateAndCollection();
  console.log("logsJobInfo: ",jobsInfo);
  console.log("logsToOptimizeData: ",toOptimizeData);

  return (
    <div className='main-box'>
      {isLoading ? (
        <div className='loader-div'>
          <CircularProgressIndicator value={null} className='check-box-btn'/>
        </div>
      ) : logsEmpty ? (
        <div className='empty'>
          <div className='empty-header'>No Optimizations Done Here.</div>
          <div className='empty-text'>
            You can <a href='/pixie'>optimize your Webflow sites.</a>
          </div>
        </div>
      ) : (
        <div className='logs-container'>
          {(jobsInfo && typeof jobsInfo === 'object' && Object.keys(jobsInfo).length > 0) ? (
          <div className="active-job-container">
            <div className="active-job-tag-wrapper">
              <div className="active-job-tag-text">ACTIVE JOB</div>
            </div>
            <LinearProgress num={progressValue}></LinearProgress>
            <div className="active-job-content-wrapper">
              <div className="active-job-row-content">
                <p className="optimizing-total-images">Total Images Optimizing:</p>
                <p className="optimizing-total-images-count">{totalImagesData.totalImagesOptimizing}</p>
              </div>
              <div className="active-job-row-content">
                <p className="optimizing-total-images">Images Optimized:</p>
                <p className="optimizing-total-images-count">{totalImagesData.totalImagesOptimized}</p>
              </div>
            </div>
          </div>
          ): null}
          {Object.keys(groupedLogs).map((dateTimeKey, groupIndex) => (
            <div key={groupIndex} className="logs-box">
                <div className='heading-date'>{getUserTimezoneDate(dateTimeKey)}</div>
                {Object.keys(groupedLogs[dateTimeKey]).map((collectionName, logIndex) => {
                  const currentLogs = groupedLogs[dateTimeKey][collectionName];
                   return (
                  
                  <div className='logs-data-wrapper'>
                  <div className='left-box'>
                   {/* Add line */}
                    <div className="details-box">
                      <div className='box-heading'>
                        <div className='detail-key'>SITE NAME:</div>
                        <div className='detail-value'>{extractSiteName(currentLogs)}</div>
                      </div>
                      <div className='box-heading'>
                        <div className='detail-key'>ID:</div>
                        <div className='detail-value'>{collectionName}</div>
                      </div>
                      <div className='box-heading'>
                        <div className='detail-key'>IMAGES:</div>
                        <div className='detail-value'>{groupedLogs[dateTimeKey][collectionName].reduce((acc, log) => {
                  return acc + log.meta.reduce((metaAcc, metaItem) => metaAcc + metaItem.totalImagesOptimized, 0);
                }, 0)}</div>
                      </div>
                    </div>
                    {/* <p className='optimization-type'>{groupedLogs[dateTimeKey][collectionName][0].optimizationId ? groupedLogs[dateTimeKey][collectionName][0].type + ' @ ' + getUserTimezoneDate(dateTimeKey): null}</p> */}
                  </div>
                  <div className='line' ref={el => lineRefs.current[groupIndex] = el}></div>
                  <div className='right-box'>
                    <div className='logs-table'>
                      {groupedLogs[dateTimeKey][collectionName].map((log, logIndex) => (
                      
                      <div className='logs-table-content-wrapper'>
                        <div className='logs-heading-wrapper'>
                          <div className='square'></div>
                          <p className='collection-name'>{log.meta[0].collectionName}</p>
                        </div>
                        <div className='logs-table-data-wrapper'>
                        <div className='logs-heading'>
                          <p className='fixed-width-122px'>Field Name</p>
                          <p className='fixed-width-122px'>Original Size</p>
                          <p className='fixed-width-122px'>Optimized Size</p>
                          <p className='fixed-width-122px'>Saved Size</p>
                          <p className='fixed-width-122px'>Action</p>
                        </div>
                        <div className='meta-items-wrapper'>
                          {Array.isArray(log.meta) ? (
                              log.meta.map((metaItem, metaIndex) => (
                                <div className='logs-data-container'>
                                  <img className='curve-vector' src={curvedVector}></img>
                                  <div className='meta-item'>
                                    <p className='fixed-width-122px'>{metaItem.slugName}</p>
                                    <p className='fixed-width-122px'>{covertToKB(metaItem.originalImagesSize)}</p>
                                    <p className='fixed-width-122px'>{covertToKB(metaItem.optimizedImagesSize)}</p>
                                    <p className='fixed-width-122px'>{covertToKB(metaItem.totalSavedBytes)}</p>
                                    <p className='fixed-width-122px'>
                                      <Link to={`/logs/${metaIndex}/${log.jobId}` } className='details-link'>
                                        Details
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              ))
                            
                          ) : (
                            <>
                              {/* Display individual meta items */}
                              <img src={curvedVector}></img>
                              <div className='meta-item'>
                                <p className='fixed-width-122px'>{log.meta.slugName}</p>
                                <p className='fixed-width-122px'>{covertToKB(log.meta.originalImagesSize)}</p>
                                <p className='fixed-width-122px'>{covertToKB(log.meta.optimizedImagesSize)}</p>
                                <p className='fixed-width-122px'>{covertToKB(log.meta.totalSavedBytes)}</p>
                                <p className='fixed-width-122px'>
                                  <Link to={`/logs/00/${log.jobId}`} className='details-link'>
                                    Details
                                  </Link>
                                </p>
                              </div>
                            </>
                          )}
                          </div>
                          </div>
                      </div>
                    ))}
                    </div>
                  </div>
                  </div>
                )})}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserLogsPage;
