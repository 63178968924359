import React from 'react';
import PropTypes from 'prop-types';

const AlertMessage = ({ message, error }) => {
  const style = {
    borderRadius: '5px',
    border: "2px solid #000",
    display: 'flex',
    padding: "1.8892vh 1.9244vw 1.9047vh 1.9531vw",
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: error === 'error' ? '#FFB8B8' : '#FFECBF', 
    color: "#000",
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
  };

  return (
    <div style={style}>
      {message}
    </div>
  );
};

AlertMessage.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

AlertMessage.defaultProps = {
  error: false,
};

export default AlertMessage;
