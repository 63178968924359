import React, { useEffect, useState } from "react";
import NavMenu from "../NavMenu/NavMenu.component";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./Navbar.styles.scss";
import logo from '../../images/logo.png';
import GrpPhoto from '../../images/Group 82.png'
import Modal from 'react-modal';
import cancel from '../../images/cancel.jpeg';
import goBackButton from '../../images/go-back-icon.svg';
import CustomButton from "../CustomButton/CustomButton.component";
import { removeFromIndexedDB } from "../../dataFiles/indexedDB";
import SearchBar from "../SearchBar/SearchBar.component";
// import UserQuotaBox from "../UserQuotaBox/UserQuotaBox.component";
// import UserQuotaContextProvider from "../../contexts/UserQuotaContext";

const Navbar = ({ onRouteChange, isSignedIn, toggleModal, userImageCount, userPlanQuota, remainingCredits, userName, showCode, currentSubscription, dataLoaded,searchQuery,handleSearch,clearSearch  }) => {
  const [isNewLinkAble, setIsNewLinkAble] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(true);
  const [allPlan, setAllPlan] = useState([]);
  const [optimizationInProgress, setOptimizationInProgress] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const currentPath = location.pathname;
  const pixieProduct = location.pathname.includes("/pixie");

  const onCloseNewLink = () => {
    localStorage.setItem("newLink_disable", JSON.stringify(true));
    setIsNewLinkAble(false);
  };
  const handleGetAPlanClick = () => {
    history.push("/pixie/pricing");
  };

  let imageOptimizationsDone;
  let creditsGiven;
  if (remainingCredits && remainingCredits.length > 0) {
    const prvAllowedImages = remainingCredits.map((allowedImages) => allowedImages.creditsLeft);
    const sumOfPrvAllowedImages = prvAllowedImages.reduce((acc, curr) => acc + curr, 0);
    const prvUsedImages = remainingCredits.map((allowedImages) => allowedImages.usedCredits);
    const sumOfprvUsedImages = prvUsedImages.reduce((acc, curr) => acc + curr, 0);
    imageOptimizationsDone = userImageCount + sumOfprvUsedImages;
    creditsGiven = userPlanQuota + sumOfPrvAllowedImages;
  } else {
    imageOptimizationsDone = userImageCount;
    creditsGiven = userPlanQuota;
  }


  useEffect(() => {
    const isAble = localStorage.getItem("newLink_disable");
    isAble ? setIsNewLinkAble(false) : setIsNewLinkAble(true);
  }, []);

  useEffect(() => {

    const handleOptimizationInProgress = () => {
      setOptimizationInProgress(true);
    };

    document.addEventListener('optimizationInProgress', handleOptimizationInProgress);

    return () => {
      document.removeEventListener('optimizationInProgress', handleOptimizationInProgress);
    };
  }, []);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}subscription/getAllPlans`);
        const data = await response.json();
        if (data?.success) {
          setAllPlan(data.plans);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPlans();
  }, []);
  

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(31, 31, 31, .9)',
    },
  };
  const handleGoBack = () => {
    if (location.pathname.includes('/logs/')) {
      history.push('/logs');
    } else {
      localStorage.removeItem('optimizationStatus')
      localStorage.removeItem("jobId");
      localStorage.removeItem('totalFields');
      removeFromIndexedDB("collectionDataState").catch((error) =>{
        console.error("Error clearing collectionDataState:", error);
      })
      history.push('/pixie');
    }
  };
  function closeModal() {
    localStorage.setItem("newLink_disable", JSON.stringify(true));
    setIsNewLinkAble(false);
    setIsOpen(false);
  }

  return (
    <div className="font-one">
      <div className={`navbar-block`} id="main-navbar">
        <div className={`navbar-wrapper ${(currentPath !== "/pixie" && currentPath !== "/convert-to-webp") ? "nav-bg-color" : ""}`}>
        {(currentPath === "/pixie" || currentPath === "/convert-to-webp" ) ? <SearchBar handleSearch={handleSearch} searchQuery={searchQuery} clearSearch={clearSearch} />: (currentPath.startsWith("/logs/") || (currentPath.startsWith("/pixie/")) && optimizationInProgress) ? (
          <div className="goback-wrapper" ><img src={goBackButton} className="go-back-icon" onClick={handleGoBack}></img><p className="go-back-text">Go Back</p></div>
        ): <div></div>}
        {
          currentSubscription.isSubscribed ? (
            <div className="data-main">
              <div className="data-details-wrapper">
                <p className="data-title">CREDITS IN PLAN:</p>
                <p className="data-value">{creditsGiven}</p>
              </div>
              <div className="data-details-wrapper">
                <p className="data-title">CREDITS USED:</p>
                <p className="data-value">{imageOptimizationsDone}</p>
              </div>
            </div>
          ) : (
            <div className="no-plan-wrapper">
              <div className="data-main no-plan-width">
                <div className="data-details-wrapper">
                  <p className="data-title">CREDITS IN PLAN:</p>
                  <p className="data-value">NO PLAN</p>
                </div>
              </div>
              <span className="get-a-plan" onClick={handleGetAPlanClick}>Get a Plan</span>
            </div>
          )
        }
        </div>
      </div>
    </div>
  );
};

export default Navbar;
