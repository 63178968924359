import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Modal from "../../components/Modal/Modal.component";
import SiteBox from "../../components/SiteBox/SiteBox.component";
import { parseJSON } from "../../lib/helpers";
import plus from "../../images/add.svg";
import HomeImage from "../../images/home-image.svg"
import "./AddSite.styles.scss";
import Bugsnag from "@bugsnag/js";
import SkeletonUI from "../../components/SkeletonUI/SkeletonUI.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import SpeechBubble from '../../components/SpeechBubble/SpeechBubble.component';
import OauthBtn from "../../components/OauthBtn/OauthBtn.component";
import SearchBar from "../../components/SearchBar/SearchBar.component"
import StepOneImage from '../../images/step1_image.svg';
import StepTwoImage from '../../images/step2_icon.svg';
import addImage from '../../images/add_icon.svg';
import SecondaryCustomButton from '../../components/SecondaryCustomButton/SecondaryCustomButton.component';
import { saveData,getData,removeFromIndexedDB } from '../../dataFiles/indexedDB';
const clientId = process.env.REACT_APP_WEBFLOW_CLIENT_ID || "";
const url = `https://webflow.com/oauth/authorize?client_id=${clientId}&response_type=code&scope=authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20sites%3Aread`;
function AddSite({ searchQuery }) {
    const [name, setName] = useState("");
    const [siteIds, setSiteIds] = useState([]);
    const [sitesData, setSitesData] = useState([]);
    const [checkSite,setCheckSite] = useState(true);
    const [skeletonLoad, setSkeletonLoad] = useState(true);
    const [fetchSites, setFetchSites] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [searchQuery, setSearchQuery] = useState("");
    const [filteredSites, setFilteredSites] = useState([]);
    const [stepImage, setStepImage] = useState(StepOneImage);
    const [showAddSite,setShowAddSite] = useState(false);
    const [addSiteBlockContent, setAddSiteBlockContent] = useState(
        <div className="add-site-block box-design">
            <img src={addImage} className="add-image" alt="Add Image" />
            <p className="block-text">Add your first Webflow project to get started!</p>
        </div>
    );
    // const adjustSpeechBubbleSize = () => {
    //     console.log('speech Bubble 2')
    //     const speechBubbles = document.querySelectorAll('.speech-bubble-wrapper');
    //     console.log(speechBubbles)
    //     if(speechBubbles.length > 0){
    //         const width = speechBubbles[0].offsetWidth;
    //         const height = speechBubbles[0].offsetHeight;
    //         speechBubbles[0].style.width = `${width + 62}px`;
    //         speechBubbles[0].style.height = `${height + 38}px`;
    //     }
    //   };
    
    //   useEffect(() => {
    //     console.log('speech Bubble 1')
    //     adjustSpeechBubbleSize();
    //     window.addEventListener('resize', adjustSpeechBubbleSize);
    //     return () => window.removeEventListener('resize', adjustSpeechBubbleSize);
    //   }, [sitesData]);
    const closeOpenModal = () => {
        setIsModalOpen(prevState => !prevState);
    }
    const addSiteClick = () =>{
        setShowAddSite(true);
    }
    const handleContainerClick = () => {
        setStepImage(StepTwoImage);
        setAddSiteBlockContent(
            <div className="add-site-block">
                <div className="text-content">
                    <p className="block-text">Add your first Webflow project to get started!</p>
                    <p className="block-sub-text">A Webflow project can be added through OAuth or an API key.</p>
                </div>
                <div className="site-add-buttons">
                    <a href={url} className="custom-link">
                        <SecondaryCustomButton orange={true}>OAuth (recommended)</SecondaryCustomButton>
                    </a>
                    <Link className="custom-links" to="/pixie/add-api-key">
                        <SecondaryCustomButton>API Key</SecondaryCustomButton>
                    </Link>
                </div>
            </div>
        );
    };
    const removeSite = async (siteId) => {
        const token = localStorage.getItem('token');
      
        try {
          const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}api/delete-site/${siteId}`, {
            method: 'DELETE',
            headers: {
              'token': `${token}`,
              'Content-Type': 'application/json'
            }
          });
      
          if (!response.ok) {
            throw new Error('Failed to delete the site');
          }
      
          const result = await response.json();
          if (result.success) {
            const {
                user: { sites, username },
            } = result;
            // if (sites instanceof Array && sites.length < 1) {
                // window.location = url;
            // }
            setName(username);
            console.log(sites)
            setSiteIds(sites);
            setFetchSites(true);
            if(sites.length == 0){
                setCheckSite(false)
            }
        }
        } catch (error) {
          console.error('Error:', error);
        }
      };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-username-sites`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: window.localStorage.getItem("token"),
            },
        })
            .then(parseJSON) // parse it to Json
            .then((data) => {
                if (data.success) {
                    const {
                        user: { sites, username },
                    } = data;
                    // if (sites instanceof Array && sites.length < 1) {
                        // window.location = url;
                    // }
                    setName(username);
                    console.log(sites)
                    setSiteIds(sites);
                    setFetchSites(true);
                    if(sites.length == 0){
                        setCheckSite(false)
                    }
                }
            })
            .catch(err => {
                console.log({ err });
                Bugsnag.notify(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fetchData = (siteObj) => {
        return fetch(
            `${process.env.REACT_APP_API_ENDPOINT}api/user-sites/${siteObj._id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: window.localStorage.getItem("token"),
                },
            }
        )
        .then(response => {
            if (response.status === 429) {
                return new Promise((resolve) => {
                    setTimeout(resolve, 5000); 
                }).then(() => fetchData(siteObj));
            }else if(response.status === 401){
                setSiteIds(prevIds => prevIds.filter(site => site._id !== siteObj._id.toString()));
            }
            return response;
        })
        .then(parseJSON)
        .then((data) => data);
    };
    
    useEffect(() => {
        const fetchAndSaveSites = async () => {
            if (siteIds.length && fetchSites) {
                setFetchSites(false);
                try {
                    const listArr = await Promise.all(siteIds.map(siteObj => fetchData(siteObj)));
                    const filteredData = listArr.reduce((acc, siteIn) => {
                        const { success, mappedSitesData } = siteIn;
                        if (success) {
                            acc = [...acc, ...mappedSitesData];
                        }
                        return acc;
                    }, []);
                    
                    console.log(filteredData);
                    
                    const uniqueSites = Object.values(
                        filteredData.reduce((acc, site) => {
                            acc[site.siteUniqueId] = site;
                            return acc;
                        }, {})
                    );
                    
                    console.log("US: ", uniqueSites);
                    
                    await saveData('sites', uniqueSites);
                    setSkeletonLoad(false);
                    setSitesData(uniqueSites);
                    setCheckSite(uniqueSites.length > 0);
                } catch (err) {
                    console.log({ err });
                    Bugsnag.notify(err);
                    setCheckSite(false);
                }
            }
        };
    
        fetchAndSaveSites();
    }, [fetchSites]);
    

    // const handleSearch = (event) => {
    //     const query = event.target.value;
    //     setSearchQuery(query);
      
    //     const filtered = sitesData.filter((site) =>
    //       site.name.toLowerCase().includes(query.toLowerCase())
    //     );
      
    //     setFilteredSites(filtered);
    //   };
    useEffect(() => {
        const displayedSites = searchQuery
          ? sitesData.filter((site) => site.name.toLowerCase().includes(searchQuery.toLowerCase()))
          : sitesData;
    
        setFilteredSites(displayedSites);
      }, [searchQuery, sitesData]);
      const displayedSites = searchQuery ? filteredSites : sitesData;
      return (
        <>
            {/* {sitesData.length > 0 && (
                <SearchBar handleSearch={handleSearch} searchQuery={searchQuery}/>
            )} */}
            {/* <div className={`flex-wrap-block ${siteIds.length > 0 && checkSite ? 'margin-top-10' : ''}`}> */}
            {showAddSite ? (<div className="add-site-block align-add-site-block">
                <div className="text-content">
                    <p className="block-text">Add your Webflow project to get started!</p>
                    <p className="block-sub-text">A Webflow project can be added through OAuth or an API key.</p>
                </div>
                <div className="site-add-buttons">
                    <a href={url} className="custom-link">
                        <SecondaryCustomButton orange={true}>OAuth (recommended)</SecondaryCustomButton>
                    </a>
                    <Link className="custom-links" to="/pixie/add-api-key">
                        <SecondaryCustomButton>API Key</SecondaryCustomButton>
                    </Link>
                </div>
            </div>) : (
            <>
            {skeletonLoad && siteIds.length
                ? 
                siteIds.map((site) => (
                <SkeletonUI key={site._id} height={'21.41vh'} width={'16.27vw'} />
                ))
            : (
                <>
                {/* <div className="site-box-scroll-container">
                <div className="flex-wrap-block "> */}
                {sitesData.length ? (
                    <div className="sites-page-wrapper">
                    <div className="site-box-wrapper">
                        <div className="add-site-box " onClick={addSiteClick}>
                            <div className="add-site-box-ele">
                                <img className="add-site-image" src={addImage}></img>
                                <p className="add-site-text">Add a Webflow Site</p>
                            </div>
                        </div>
                    </div>
                {searchQuery !== "" ? (
                    displayedSites.map((ele) => (
                        <div className="site-data-wrapper" key={ele.siteUniqueId}>
                        {
                        ele.reauth === true && (
                            <div className="speech-bubble-wrapper position-absolute">
                                <SpeechBubble
                                className={"speech-bubble"}
                                fillColor={'#FFECBF'}
                                />
                                <div className='speech-bubble-content-wrapper'><p className='speech-bubble-content-sitebox'>Kindly Re-authorize your site</p></div>
                            </div>
                        )}
                        <div className="site-box-wrapper">
                            <SiteBox siteInfo={ele} reauth={ele.reauth===true?true:false} onReauthorize={addSiteClick} removeSite={removeSite}/>
                        </div>
                    </div>
                    ))
                ) : (
                    sitesData.map((ele) => (
                        <div className="site-data-wrapper" key={ele.siteUniqueId}>
                            {                        
                            ele.reauth === true && (
                            <div className="speech-bubble-wrapper position-absolute">
                                <SpeechBubble
                                className={"speech-bubble"}
                                fillColor={'#FFECBF'}
                                />
                                <div className='speech-bubble-content-wrapper'><p className='speech-bubble-content-sitebox'>Kindly Re-authorize your site</p></div>
                            </div>
                            )}  
                            <div className="site-box-wrapper">
                                <SiteBox siteInfo={ele} reauth={ele.reauth===true?true:false} onReauthorize={addSiteClick} removeSite={removeSite}/>
                            </div>
                        </div>

                    ))
                )}
                </div>
                ): 
            (
            <>
            {checkSite ? (null) : (
                <div className="empty-site-page">
                    <div className="content-container" onClick={handleContainerClick}>
                        <img src={stepImage} className="step-image" alt="Step Image" />
                        {addSiteBlockContent}
                    </div>
                </div>
            )}
            </>
            )
        }
        </>
        )}
        </>
        // </div>
        // </div>
        
            )
            }
            {/* </div> */}
            {
                isModalOpen && (
                        <MultiOptionBox toggleModal={closeOpenModal} />
                )
            }
        </>
    );
}


const MultiOptionBox = ({ toggleModal }) => {
    return (
        <div className="box-outline" id="modal-div">
            <div className="inner-box">
                <div onClick={toggleModal} className="modal-close">
                    <CloseSharpIcon fontSize="large" style={{ color: '#FACE48' }} />
                </div>

                <h3 className="title">
                    Add your Webflow site
                </h3>
                <p className="desc">
                    It is as simple as signing in your Webflow account.
                </p>
                <div className="btn-box">

                    <OauthBtn />
                    <span className="or">or</span>
                    <Link className="custom-links" to="/pixie/add-api-key">
                        <CustomButton black={true}>Add API Key</CustomButton>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default AddSite;
