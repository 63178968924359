import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.styles.scss';

const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.className = 'modal-overlay';
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
    this.updateModalPosition();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isExpanded !== this.props.isExpanded) {
      this.updateModalPosition();
    }
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
    modalRoot.style.left = '0';
    modalRoot.style.width = '100%';
  }

  updateModalPosition() {
    if (this.props.isExpanded) {
      modalRoot.style.left = '22%';
      modalRoot.style.width = '78%';
    } else {
      modalRoot.style.left = '8.1%';
      modalRoot.style.width = '91.9%';
    }
  }

  render() {
    const { children } = this.props;

    return ReactDOM.createPortal(
      <div className="modal-content">
        {children}
      </div>,
      this.el
    );
  }
}

export default Modal;