import CheckCross from "../../images/check-cross.svg";
import Completed from "../../images/completed.svg";
import MainImage from "../../images/main-image.svg";
import MultiImage from "../../images/multi-image.svg";
import RichTextImage from "../../images/rich-text.svg";
import LinearProgress from "../../components/LinearProgressItem/LinearProgressItem.components";
import CircularProgressIndicator from "../../components/CircularProgress/CircularProgress.component";
import React, { useState, useEffect, useContext, useRef } from "react";

const Results = ({toOptimizeData,jobsInfo,optimizedStatus,totalFields,optimized,type,results,bulkOptimization}) =>{
    const progress = (100 / totalFields) * optimized;
    const [resultArray, setResultArray] = useState(null);

    const IconByStatus = (status, field, process) => {
        if (status === "completed") {
          return (
            <img
              className="check-box-btn"
              src={Completed}
              alt="CheckSquare icon"
              style={{
                // marginLeft: "0.65vw",
                // marginRight: "0.32vw",
                // marginTop: "0.13vh",
                height: "100%",
                width: "100%",
              }}
            />
          );
        } else if (status === "failed") {
          return (
            <img
              className="check-box-btn"
              src={CheckCross}
              alt="CheckCross icon"
              style={{
                // marginLeft: "0.65vw",
                // marginRight: "0.32vw",
                // marginTop: "0.13vh",
                height: "100%",
                width: "100%",
              }}
            />
          );
        } else if (status === "running") {
          return (
            <CircularProgressIndicator
              // value={process}
              className="check-box-btn"
              width="19.097px"
              height="19.097px"
              style={{
                color: "black",
                // height: "100%",
                // width: "100%",
                // marginLeft: "0.65vw",
                // marginRight: "0.32vw",
              }}
            />
          );
        } else {
          return (
            // <Checkbox
            //   id={field.id}
            //   icon={
            //     <img className="check-box-btn" src={CheckEmpty} alt="Check icon" />
            //   }
            //   checked={field.isChecked}
            //   onChange={(event) =>
            //     handleIndividualFieldCheckBoxChange(event, field.id)
            //   }
            //   checkedIcon={
            //     <img className="check-box-btn" src={CheckChange} alt="Check icon" />
            //   }
            //   name="field.name"
            // />
            null
          );
        }
      };

    const ResultWrapper = ({ index, name, imageUrl,field,itemId, jobsInfo,jobKey }) => {
        console.log(jobKey )
        console.log(jobsInfo[jobKey])
        return (
          <div key={index} className="result-wrapper">
            <div className="content-info-wrap">
              <div className="tags-wrapper">
                <div className="text-18">{name}</div>
                <div className="field-type">
                  {field.type === 'Image' && (
                    <div className="image-icon-result">
                      <img height="100%" width="100%" src={MainImage} alt="main" className="icon-16" />
                      <span className="text-14">Image</span>
                    </div>
                  )}
                  {field.type === 'MultiImage' && (
                    <div className="image-icon-result">
                      <img height="100%" width="100%" src={MultiImage} alt="main" className="icon-16" />
                      <span className="text-14">Multi-Image</span>
                    </div>
                  )}
                  {field.type === 'RichText' && (
                    <div className="image-icon-result">
                      <img height="100%" width="100%" src={RichTextImage} alt="main" className="icon-16" />
                      <span className="text-14">Rich Text</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="result-field-img">
                <img style={{ height: '9.64469rem', width: '100%' }} src={imageUrl} alt={name} />
              </div>
            </div>
            <div className="width-100 p-btm-45">
              {jobsInfo[jobKey]?.map(({ collectionFieldData, state, progress, successData }) =>
                collectionFieldData.map(
                  (fieldData) =>
                    fieldData.id === field.id && (
                      console.log('Data'),
                      <div className="status-wrapper" key={fieldData.id}>
                        <div className="result-state-wrapper">
                          <div className="text-13">Status:</div>
                          <div className="status-tag text-13">{state}</div>
                          <div className="result-progress">
                            {IconByStatus(state, field, progress)}
                          </div>
                        </div>
                        {successData?.optimizationResults &&
                          successData?.optimizationResults.map((resData) => {
                            if (resData.slug === field.slug) {
                              const { compressedImageURL } = resData;
                              console.log(itemId);
                              const matchingCompressedData = compressedImageURL.find(
                                (compressedData) => compressedData.itemId === itemId
                              );
                              console.log(matchingCompressedData);
                              if (matchingCompressedData) {
                                return (
                                  console.log('entered'),
                                  <div className="optimized-info-wrapper" key={matchingCompressedData.itemId}>
                                    <div className="result-state-wrapper">
                                      <div className="text-13">Saved:</div>
                                      <div className="text-13">
                                        {(matchingCompressedData.savedBytes / 1024).toFixed(2) + 'KB'}
                                      </div>
                                    </div>
                                    <div className="result-state-wrapper">
                                      <div className="text-13">New size:</div>
                                      <div className="text-13">
                                        {(matchingCompressedData.optimizedSize / 1024).toFixed(2) + 'KB'}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                            return null;
                          })}
                      </div>
                    )
                )
              )}
            </div>
          </div>
        );
      };
      useEffect(() => {
        if (toOptimizeData == null) return;
        if (jobsInfo === null) return;
    
        let resultDom = [];
        console.log("toOptimizeData: ",toOptimizeData);
        for (const key in toOptimizeData) {
          console.log(key);
          const { fields, itemsToOptimize } = toOptimizeData[key];
          console.log("Results Field: ",fields);
          console.log("Item Data Results: ",itemsToOptimize);
          const result = itemsToOptimize.map((item, index) => (
            <>
            {console.log(item)}
              {fields?.map((field, fieldIndex) => (
                <>
                {console.log(field)}
                {field.type === 'Image' && (
                  console.log(key),
                  <ResultWrapper
                    index={index}
                    name={item.fieldData.name}
                    imageUrl={item.fieldData[field.slug]?.url}
                    field={field}
                    itemId={item.id}
                    jobsInfo={jobsInfo}
                    jobKey={key}
                  />
              )}
              {field.type === 'MultiImage' && (
                <>
                  {item.fieldData[field.slug] &&
                    item.fieldData[field.slug].map((url, subIndex) => (
                      ((bulkOptimization === false && url.isChecked) || (bulkOptimization === true)) && (
                        <ResultWrapper
                        index={index}
                        name={item.fieldData.name}
                        imageUrl={url?.url}
                        field={field}
                        itemId={item.id}
                        jobsInfo={jobsInfo}
                        jobKey={key}
                      />
                    )
                    ))}
                </>
              )}
              {field.type === 'RichText' && (
                <>
                  {item.fieldData[field.slug+'-pixie-optimized'] &&
                    item.fieldData[field.slug+'-pixie-optimized'].map((url, subIndex) => (
    
                       (
                        <ResultWrapper
                        index={index}
                        name={item.fieldData.name}
                        imageUrl={url?.url}
                        field={field}
                        itemId={item.id}
                        jobsInfo={jobsInfo}
                        jobKey={key}
                      />
                    )
                    ))}
                  {/* <div
                    style={{ height: '100%' }}
                    dangerouslySetInnerHTML={{
                      __html: item.fieldData[field.slug],
                    }}
                  /> */}
                </>
              )}
                </>
              ))}
            </>
          ));
          resultDom.push(result);
        }
    
        setResultArray(resultDom);
      }, [toOptimizeData, jobsInfo]);

    return (
        <div className="result-container">
        <div className="result-left-block min-height-45 scroll-bar">
          <div className="result-details-heading">Images</div>
          <div className="results-array-wrapper">
          {
            resultArray &&
            resultArray.map((res) => {
              return res;
            })}
          </div>
        </div>
        <div className="result-right-block">
          {(
            <div className="result-right-wrapper">
              {/* <p>{`(Saved ${savedImage} on optimising ${results.totalImagesOptimized} images)`}</p> */}
              <LinearProgress num={progress} type={type}/>
              {optimizedStatus ? (
                <div className="tag-28">
                  {(((results.originalImagesSize-results.optimizedImagesSize)/results.originalImagesSize)*100).toFixed(2)}% saved
                </div>
              ) : (
                <div className="tag-28">Loading...</div>
              )}
              <div className="stats-wrapper">
                <div className="stats-block">
                  <div className="font-28">
                    {optimizedStatus ? (
                      (
                        results.originalImagesSize / 1024
                      ).toFixed(2) + "KB"
                    ) : (
                      <CircularProgressIndicator
                        className="check-box-btn"
                        width="24px"
                        height="24px"
                        style={{
                          color: "black",
                        }}
                      />
                    )}
                  </div>
                  <div className="font-15">Total Size</div>
                </div>
                <div className="stats-block">
                  <div className="font-28">
                  {optimizedStatus ? (
                      (
                        results.optimizedImagesSize / 1024
                      ).toFixed(2) + "KB"
                    ) : (
                      <CircularProgressIndicator
                        className="check-box-btn"
                        width="24px"
                        height="24px"
                        style={{
                          color: "black",
                        }}
                      />
                    )}
                  </div>
                  <div className="font-15">Optimized to</div>
                </div>
                <div className="stats-block">
                  <div className="font-28">
                  {optimizedStatus ? (
                      (
                        (results.originalImagesSize-results.optimizedImagesSize) / 1024
                      ).toFixed(2) + "KB"
                    ) : (
                      <CircularProgressIndicator
                        className="check-box-btn"
                        width="24px"
                        height="24px"
                        style={{
                          color: "black",
                        }}
                      />
                    )}
                  </div>
                  <div className="font-15">Saved</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default Results;