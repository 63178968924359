import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import phoneIcon from '../../images/optimization result icon.svg';
import clockIcon from '../../images/clock icon.svg';

const useStyles = makeStyles({
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3.658vh",
    width: "100%",
    background: "transparent"
  },
  loaderWrapper: {
    width: "100%",
    background: "#FFF",
    padding: "0.271vh 0.1953vw",
    display: "flex",
    justifyContent: "center",
    borderRadius: "1em",
  },
  processIndicator: {
    color: "#000",
    fontFamily: "Inter, sans-serif",
    fontSize: "1.6666666667em",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1,
    background: "transparent"
  },
  wrapper: {
    display: "flex",
    padding: "0px 1.267vw",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.759vh",
    alignSelf: "stretch",
    background: "transparent"
  },
  image: {
    background: "transparent"
  },
  linearProgress: {
    maxWidth: "30.794vw",
    width: "100%",
    maxWidth: "33.6805555556em",
    height: "0.9027777778em",
    flexShrink: 0,
    borderRadius: "1em",
    backgroundColor: "#FFF",
    "& .MuiLinearProgress-bar": {
      borderRadius: "1em",
      backgroundColor: "#FFAB69",
    },
  },
});

export default function LinearDeterminate({ num, type }) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    setProgress((oldProgress) => {
      if (oldProgress === 100) {
        return 0;
      }
      return num;
    });
  }, [num]);
  console.log(type);
  return (
    <div className={classes.progressContainer}>
      {type !== undefined ? 
      (<div className={classes.wrapper}>
        <img className={classes.image} src={progress === 100 ? phoneIcon : clockIcon} alt={progress === 100 ? "Phone Icon" : "Clock Icon"} />
        <div className={classes.processIndicator}>
          {progress === 100
            ? type === 'convert'
              ? 'Conversion Completed!'
              : 'Optimization Completed!'
            : type === 'convert'
              ? 'Converting...'
              : 'Optimizing...'}
        </div>
      </div>): null
      }
      <div className={classes.loaderWrapper}>
        <LinearProgress
          variant="determinate"
          value={progress}
          className={classes.linearProgress}
        />
      </div>
    </div>
  );
}
