import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./SiteBox.styles.scss";
import "./OptimisationTypeModal.style.scss";
import Modal from "../../components/Modal/Modal.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import OauthBtn from "../../components/OauthBtn/OauthBtn.component";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import CMSIMAGE from "../../images/cms.svg";
import ITEMIMAGE from "../../images/items.svg";
import OptionIcon from "../../images/option_icon.svg";
import phoneIcon from '../../images/optimization result icon.svg';
import collectionImage from '../../images/collection-optimization.png';
import itemImage from '../../images/item-optimization.png';

const SiteBox = ({ siteInfo, reauth,onReauthorize,removeSite  }) => {
  console.log(siteInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { previewUrl, name, siteId, siteUniqueId } = siteInfo;
  const [optimisationModal, setOptimisationModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const optionsBoxRef = useRef(null);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  
  const closeOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeOptimisationModal = () => {
    setOptimisationModal(!optimisationModal);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsBoxRef.current && !optionsBoxRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (reauth === true) {
    return (
      <div className="box-wrapper">
        <div className="site-box">
        <div className="site-info">
            <span className="site-name">{name}</span>
            <img
              src={OptionIcon}
              className="option-icon"
              onClick={toggleOptions}
            />
          </div>
          <div
            className="site-img-block reauthorize-content"
            style={{ backgroundImage: `url(${previewUrl})` }}
          ></div>
        </div>

        {/* {isModalOpen && (
          <Modal>
            <MultiOptionBox toggleModal={closeOpenModal} />
          </Modal>
        )} */}
        {console.log(showOptions)}
        {showOptions && (
          <div
            className="options-box"
          >
            <div className="options" onClick={onReauthorize}>Re-authorize</div>
            <div className="options" onClick={() => removeSite(siteInfo.siteId)}>Remove</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {optimisationModal && (
        // <Modal>
          <SelectOptimisationType
            toggleModal={closeOptimisationModal}
            siteId={siteId}
            siteUniqueId={siteUniqueId}
          />
        // </Modal>
      )}
      <div className="box-wrapper">
        <div className="site-box" >
          <div className="site-info">
            <span className="site-name">{name}</span>
            <img
              src={OptionIcon}
              className="option-icon"
              onClick={toggleOptions}
            />
          </div>
          <div
            className="site-img-block"
            style={{ backgroundImage: `url(${previewUrl})` }}
            onClick={closeOptimisationModal}
          ></div>
        </div>
        {showOptions && (
          <div
            ref={optionsBoxRef}
            className="options-box"
            // style={{ top: optionsPosition.y, left: optionsPosition.x }}
          >
            <div className="options" onClick={onReauthorize}>Re-authorize</div>
            <div className="options" onClick={() => removeSite(siteInfo.siteId)}>Remove</div>
          </div>
        )}
      </div>
    </>
  );
};

const MultiOptionBox = ({ toggleModal }) => {
  return (
    <div className="box-outline" id="modal-div">
      <div className="inner-box">
        <div onClick={toggleModal} className="modal-close">
          <CloseSharpIcon fontSize="large" style={{ color: "#FACE48" }} />
        </div>
        <h3 className="title">Resync your Webflow site</h3>
        <p className="desc">
          We have shifted to Webflow’s v2 API for a better experience. Please
          re-sync your Webflow project to continue.
        </p>
        <div className="btn-box">
          <OauthBtn />
          <span className="or">or</span>
          <Link className="custom-links" to="/pixie/add-api-key">
            <CustomButton black={true}>Add API Key</CustomButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

const SelectOptimisationType = ({ toggleModal, siteId, siteUniqueId }) => {
  const location = useLocation();
  const path = location.pathname;
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        toggleModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleModal]);
  return (
    <div className="box-outline bg-grey" id="modal-div">
      <div className="modal-container" ref={modalRef}>
        {/* <div onClick={toggleModal} className="modal-close">
          <CloseSharpIcon fontSize="large" style={{ color: "#FACE48" }} />
        </div> */}
        <div className="modal-wrapper">
          <div className="model-header">
            <img src={phoneIcon} className="phone-image"></img>
            <h3 className="modal-title">
              Select how you would like to{" "}
              {path.includes("/convert-to-webp") ? "convert" : "optimize"} the images
            </h3>
          </div>
          <div className="modal-btn-wrapper">
          {/* <div
            className="site-img-block"
            style={{ backgroundImage: `url(${previewUrl})` }}
            onClick={closeOptimisationModal}
          ></div> */}
            <Link
              className="modal-link-block"
              to={`/pixie/${siteId}/collections/${siteUniqueId}?type=${
                path.includes("/convert-to-webp") ? "convert" : "optimize"
              }`}
            >
              <div
                className="option-box-img-block"
                style={{ backgroundImage: `url(${itemImage})` }}
              ></div>
              <div className="collection-text-wrapper"><p className="collection-type-text">Whole Collection</p></div>
            </Link>
            <Link
              className="modal-link-block"
              to={`/pixie/${siteId}/itemoptimization/${siteUniqueId}?type=${
                path.includes("/convert-to-webp") ? "convert" : "optimize"
              }`}
            >
              <div
                className="option-box-img-block"
                style={{ backgroundImage: `url(${collectionImage})` }}
              ></div>
              <div className="collection-text-wrapper"><p className="collection-type-text">Specific Images</p></div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteBox;
