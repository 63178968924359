import React, { useState, useEffect, useContext, useRef } from "react";
import { parseJSON } from "../../lib/helpers";
import { Link, useParams, useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import { saveData,getData,removeFromIndexedDB } from '../../dataFiles/indexedDB';
import ActionAlerts from "../../components/Alert/Alert.component";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import UpArrow from '../../images/Polygon 1.svg'
import SecondaryCustomButton from "../../components/SecondaryCustomButton/SecondaryCustomButton.component";
import CheckChange from "../../images/Checked (1).svg";
import CheckSquare from "../../images/check-square.svg";
import CheckCross from "../../images/check-cross.svg";
import CheckEmpty from "../../images/Checked (2).svg";
import CheckPartial from "../../images/Group 2608549.png";
import RichTextIcon from '../../images/rich-text-icon.svg';
import ImageIcon from '../../images/image-icon.svg';
import MultiImageIcon from '../../images/multi-image-icon.svg';
import CheckGrey from "../../images/check-non.svg";
import MainImage from "../../images/main-image.svg";
import MultiImage from "../../images/multi-image.svg";
import RichTextImage from "../../images/rich-text.svg";
import Modal from "../../components/Modal/Modal.component";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Loading from "../../images/loading.svg";
import Completed from "../../images/completed.svg";
import "./ItemOptimization.styles.scss";
import LinearProgress from "../../components/LinearProgressItem/LinearProgressItem.components";
import { CollectionContext } from "../../contexts/CollectionContext";
import { useJobHandler } from "../../customHooks/jobHandlerHook";
import CircularProgressIndicator from "../../components/CircularProgress/CircularProgress.component";
import Results from "../../components/Results/Results.component";
import Back from "../../images/Arrow back.svg";
import CollectionFieldsWithOptions from "../../components/CollectionFieldsWithOptions/CollectionFieldsWithOptions.component";
import { Tune } from "@material-ui/icons";
import HelpIcon from "@material-ui/icons/Help";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
// import OptimizationTypeBox from "../../components/OptimizationTypeBox/OptimizationTypeBox.component";

function ItemOptimization() {
  const {
    fetchCollections,
    setAppApiKey,
    setAppSiteId,
    submitChange,
    isBtnDisabled,
    message,
    btnText,
    showOptimizeBtn,
    showPricingBtn,
    collectionData,
    updateCollectionData,
    toOptimizeData,
    setSelectedMultiImage
  } = useContext(CollectionContext);
  const { setJobIds, jobsInfo, invalidIds, failedJobsArr, totalJobs } =
    useJobHandler();
  let { api, siteId } = useParams();
  const history = useHistory();
  const [sitesData, setSitesData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [disable, setDisable] = useState(true);
  const [buttonClick, setButtonClick] = useState(false);
  const [checkOptimization, setCheckOptimization] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [optimizedStatus, setOptimizedStatus] = useState(false);
  const [optimizedJobs, setOptimizedJobs] = useState([]);
  const [optimized, setOptimized] = useState(0);
  const [results, setResults] = useState({
    originalImagesSize: 0,
    optimizedImagesSize: 0,
    totalImagesOptimized: 0,
  });
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [showProgress, setShowProgress] = useState(false);
  const [optimizationInProgress, setOptimizationInProgress] = useState(false);
  const [fieldsId, setFieldsId] = useState([]);
  const [jobsInLs, setJobsInLs] = useState(false);
  const [ids, setIds] = useState([]);
  const [totalFields, setTotalFields] = useState(0);
  const [errState, setErrState] = useState(false);
  const [showOptimizedJobs, setShowOptimizedJobs] = useState(false);
  const [selectedFieldData, setSelectedFieldData] = useState(null);
  const [selectedCollectionID, setSelectedCollectionID] = useState(null);
  const [selectedItemID, setSelectedItemID] = useState(null);
  const [resultArray, setResultArray] = useState(null);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState([]);
  const [selectedImagesCount, setSelectedImagesCount] = useState(0);
  const [emptyItems, setEmptyItems] = useState([]);
  const [selectedRichText,setSelectedRichText] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const dropdownRef = useRef(null);
  const dropdownIconRef = useRef(null);
  const [allSelected, setAllSelected] = useState(false);
  const [allSelectedItems, setAllSelectedItems] = useState(false);
  const [allSelectedFields, setAllSelectedFields] = useState(false);

  // console.log(collectionData);
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  console.log(type)

  const progress = (100 / totalFields) * optimized;
  const savedImageInBytes =
    results.originalImagesSize - results.optimizedImagesSize;
  const savedImage = (savedImageInBytes / 1024).toFixed(2) + " KB";
  const handleCollectionClick = (collectionDetails, index) => {
    // console.log(collectionDetails);
    setSelectedCollection(collectionDetails);
    setSelectedCollectionID(collectionDetails.collectionDetails.id);
  };
  const closeOpenModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };
  const handleClickRichText = () => {
    closeOpenModal()
    handleOptimizeClick(true)
  }  
  const handleClick = () => {
    console.log('optimize');
    handleOptimizeClick(false);
  };
  useEffect(() => {
    if (invalidIds.length) {
      const doIdsExist = invalidIds.filter((id) => !ids.includes(id));
      if (!doIdsExist.length) {
        setErrState(true);
      }
    }
  }, [invalidIds]);
  useEffect(() => {
    setOptimized(0);
    setResults({
      originalImagesSize: 0,
      optimizedImagesSize: 0,
      totalImagesOptimized: 0,
    });
    checkAndUpdateOptimizedValues();
  }, [jobsInfo]);
  
  const MultiOptionBox = ({ toggleModal }) => {
    return (
      <div className="box-outline" id="modal-div">
        <div className="inner-box">
          <div onClick={toggleModal} className="modal-close">
            <CloseSharpIcon fontSize="large" style={{ color: "#FACE48" }} />
          </div>
  
          <h3 className="title">Rich Text Element</h3>
          <p className="desc">
          You have selected a Rich Text Element within your CMS. Currently Webflow's API does not support embed code elements and these will be removed from these fields upon processing.
          </p>
          <div className="btn-box">
            <CustomButton onClick={handleClickRichText} black={true}>Continue</CustomButton>
          </div>
        </div>
      </div>
    );
  };

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setShowDropdown((prev) => !prev);
  };
  
  const handleOutsideClick = (event) => {
    if (
      dropdownRef.current && 
      !dropdownRef.current.contains(event.target) &&
      dropdownIconRef.current &&
      !dropdownIconRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };
  
  useEffect(() => {  
    if (showDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
  
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showDropdown]);

  useEffect(() => {
    const fetchSites = async () => {
        const data = await getData('sites');
        if (data) {
            const filteredSites = data.filter(site => site.siteUniqueId !== siteId);
            setSiteList(filteredSites);
        }
    };

    fetchSites();
}, [siteId]);
const handleSiteClick = (siteId, siteUniqueId) => {
  localStorage.removeItem('optimizationStatus')
  localStorage.removeItem("jobId");
  localStorage.removeItem('totalFields');
  removeFromIndexedDB("collectionDataState").catch((error) =>{
    console.error("Error clearing collectionDataState:", error);
  })
  history.push(`/pixie/${siteId}/itemoptimization/${siteUniqueId}?type=${type}`);
  setShowDropdown(false);
  window.location.reload()
};
  useEffect(() => {
    // console.log("totalFields: ", typeof totalFields);
    // console.log("optmized: ", typeof optimized);
    // const totalFieldsInLS = parseInt(localStorage.getItem('totalFields'));
    // console.log("totalFieldsInLS: ",typeof(totalFieldsInLS))
    // && totalFields === totalFieldsInLS
    if (totalFields > 0 && optimized === totalFields) {
      // console.log("entered Optimized");
      setOptimizedStatus(true);
    }
    // console.log(optimizedStatus);
    if (optimized > 0 && !showResults) {
      setShowResults(true);
    }
    const token = window.localStorage.getItem("token");
    // if (token) onLoadUser(token)

    if (totalJobs > 0 && totalJobs === failedJobsArr.length) {
      setShowResults(true);
    }
  }, [optimized, totalFields, totalJobs, failedJobsArr]);
  useEffect(() => {
    if (errState) {
      setOptimized(0);
      setOptimizedStatus(false);
    }
  }, [errState]);
  const checkAndUpdateOptimizedValues = () => {
    let hasTotalFields = 0;
    let optimizedNumOfFields = 0;
    const updatedFieldsId = [];
    Object.keys(jobsInfo).forEach((col) => {
      jobsInfo[col].forEach(({ collectionFieldData, success, successData }) => {
        for (const collectionField of collectionFieldData) {
          if (!updatedFieldsId.includes(collectionField.id)) {
            updatedFieldsId.push(collectionField.id);
          }
        }
        hasTotalFields = hasTotalFields + collectionFieldData.length;
        if (success) {
          optimizedNumOfFields =
            optimizedNumOfFields + collectionFieldData.length;
          // const { optimizedImagesSize, originalImagesSize, totalImagesOptimized } = successData.optimizationResults;
          const summedResults = successData.optimizationResults.reduce(
            (accumulator, currentItem) => {
              accumulator.originalImagesSize += currentItem.originalImagesSize;
              accumulator.optimizedImagesSize +=
                currentItem.optimizedImagesSize;
              accumulator.totalImagesOptimized +=
                currentItem.totalImagesOptimized;
              return accumulator;
            },
            {
              originalImagesSize: 0,
              optimizedImagesSize: 0,
              totalImagesOptimized: 0,
            }
          );

          setResults((prevResults) => {
            return {
              originalImagesSize: (prevResults.originalImagesSize +=
                summedResults.originalImagesSize),
              optimizedImagesSize: (prevResults.optimizedImagesSize +=
                summedResults.optimizedImagesSize),
              totalImagesOptimized: (prevResults.totalImagesOptimized +=
                summedResults.totalImagesOptimized),
            };
          });
        }
      });
    });
    setFieldsId(updatedFieldsId);
    setTotalFields(hasTotalFields);
    setOptimized(optimizedNumOfFields);
  };
  const handleOptimizeClick = async (richTextCheck) => {
    // setIsBtnDisabled(true);
    // setBtnText('Please Wait..');
    // if((richTextCheck === false && selectedRichText <= 0) || richTextCheck === true){
      let conversion;
      if(type === 'convert'){
        conversion = true;
      }else{
        conversion = false;
      }
    const jobsArray = await submitChange(true,conversion);
    // console.log(jobsArray);
    if (jobsArray) {
      setOptimizedJobs(jobsArray);
      setShowOptimizedJobs(true);
      setOptimizationInProgress(true);
      setShowProgress(true);
      setJobIds(jobsArray);
      setIds(jobsArray);
      const optimizationEvent = new Event("optimizationInProgress");
      document.dispatchEvent(optimizationEvent);
      // localStorage.setItem("optimizationStatus", true);
      // localStorage.setItem("jobId", JSON.stringify(jobsArray));
    } else {
      // setBtnText('Error');
      // setShowOptimizeBtn(false);
    }
    // }
    // else{
    //   closeOpenModal()
    // }
  };
  // const ResultWrapper = ({ index, name, imageUrl,field,itemId, jobsInfo,jobKey }) => {
  //   console.log(jobKey )
  //   console.log(jobsInfo[jobKey])
  //   return (
  //     <div key={index} className="result-wrapper">
  //       <div className="content-info-wrap">
  //         <div className="tags-wrapper">
  //           <div className="text-18">{name}</div>
  //           <div className="field-type">
  //             {field.type === 'Image' && (
  //               <div className="image-icon-result">
  //                 <img height="100%" width="100%" src={MainImage} alt="main" className="icon-16" />
  //                 <span className="text-14">Image</span>
  //               </div>
  //             )}
  //             {field.type === 'MultiImage' && (
  //               <div className="image-icon-result">
  //                 <img height="100%" width="100%" src={MultiImage} alt="main" className="icon-16" />
  //                 <span className="text-14">Multi-Image</span>
  //               </div>
  //             )}
  //             {field.type === 'RichText' && (
  //               <div className="image-icon-result">
  //                 <img height="100%" width="100%" src={RichTextImage} alt="main" className="icon-16" />
  //                 <span className="text-14">Rich Text</span>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //         <div className="result-field-img">
  //           <img style={{ height: '9.64469rem', width: '100%' }} src={imageUrl} alt={name} />
  //         </div>
  //       </div>
  //       <div className="width-100 p-btm-45">
  //         {jobsInfo[jobKey]?.map(({ collectionFieldData, state, progress, successData }) =>
  //           collectionFieldData.map(
  //             (fieldData) =>
  //               fieldData.id === field.id && (
  //                 console.log('Data'),
  //                 <div className="status-wrapper" key={fieldData.id}>
  //                   <div className="result-state-wrapper">
  //                     <div className="text-13">Status:</div>
  //                     <div className="status-tag text-13">{state}</div>
  //                     <div className="result-progress">
  //                       {IconByStatus(state, field, progress)}
  //                     </div>
  //                   </div>
  //                   {successData?.optimizationResults &&
  //                     successData?.optimizationResults.map((resData) => {
  //                       if (resData.slug === field.slug) {
  //                         const { compressedImageURL } = resData;
  //                         console.log(itemId);
  //                         const matchingCompressedData = compressedImageURL.find(
  //                           (compressedData) => compressedData.itemId === itemId
  //                         );
  //                         console.log(matchingCompressedData);
  //                         if (matchingCompressedData) {
  //                           return (
  //                             console.log('entered'),
  //                             <div className="optimized-info-wrapper" key={matchingCompressedData.itemId}>
  //                               <div className="result-state-wrapper">
  //                                 <div className="text-13">Saved:</div>
  //                                 <div className="text-13">
  //                                   {(matchingCompressedData.savedBytes / 1024).toFixed(2) + 'KB'}
  //                                 </div>
  //                               </div>
  //                               <div className="result-state-wrapper">
  //                                 <div className="text-13">New size:</div>
  //                                 <div className="text-13">
  //                                   {(matchingCompressedData.optimizedSize / 1024).toFixed(2) + 'KB'}
  //                                 </div>
  //                               </div>
  //                             </div>
  //                           );
  //                         }
  //                       }
  //                       return null;
  //                     })}
  //                 </div>
  //               )
  //           )
  //         )}
  //       </div>
  //     </div>
  //   );
  // };
  // useEffect(() => {
  //   if (toOptimizeData == null) return;
  //   if (jobsInfo === null) return;

  //   let resultDom = [];
  //   for (const key in toOptimizeData) {
  //     console.log(key);
  //     const { fields, itemsToOptimize } = toOptimizeData[key];
  //     console.log("Results Field: ",fields);
  //     console.log("Item Data Results: ",itemsToOptimize);
  //     const result = itemsToOptimize.map((item, index) => (
  //       <>
  //         {fields?.map((field, fieldIndex) => (
  //           <>
  //           {field.type === 'Image' && (
  //             console.log(key),
  //             <ResultWrapper
  //               index={index}
  //               name={item.fieldData.name}
  //               imageUrl={item.fieldData[field.slug]?.url}
  //               field={field}
  //               itemId={item.id}
  //               jobsInfo={jobsInfo}
  //               jobKey={key}
  //             />
  //         )}
  //         {field.type === 'MultiImage' && (
  //           <>
  //             {item.fieldData[field.slug] &&
  //               item.fieldData[field.slug].map((url, subIndex) => (
  //                 url.isChecked && (
  //                   <ResultWrapper
  //                   index={index}
  //                   name={item.fieldData.name}
  //                   imageUrl={url?.url}
  //                   field={field}
  //                   itemId={item.id}
  //                   jobsInfo={jobsInfo}
  //                   jobKey={key}
  //                 />
  //               )
  //               ))}
  //           </>
  //         )}
  //         {field.type === 'RichText' && (
  //           <>
  //             {item.fieldData[field.slug+'-pixie-optimized'] &&
  //               item.fieldData[field.slug+'-pixie-optimized'].map((url, subIndex) => (

  //                  (
  //                   <ResultWrapper
  //                   index={index}
  //                   name={item.fieldData.name}
  //                   imageUrl={url?.url}
  //                   field={field}
  //                   itemId={item.id}
  //                   jobsInfo={jobsInfo}
  //                   jobKey={key}
  //                 />
  //               )
  //               ))}
  //             {/* <div
  //               style={{ height: '100%' }}
  //               dangerouslySetInnerHTML={{
  //                 __html: item.fieldData[field.slug],
  //               }}
  //             /> */}
  //           </>
  //         )}
  //           </>
  //         ))}
  //       </>
  //     ));
  //     resultDom.push(result);
  //   }

  //   setResultArray(resultDom);
  // }, [toOptimizeData, jobsInfo]);

  const IconByStatus = (status, field, process) => {
    if (status === "completed") {
      return (
        <img
          className="check-box-btn"
          src={Completed}
          alt="CheckSquare icon"
          style={{
            // marginLeft: "0.65vw",
            // marginRight: "0.32vw",
            // marginTop: "0.13vh",
            height: "100%",
            width: "100%",
          }}
        />
      );
    } else if (status === "failed") {
      return (
        <img
          className="check-box-btn"
          src={CheckCross}
          alt="CheckCross icon"
          style={{
            // marginLeft: "0.65vw",
            // marginRight: "0.32vw",
            // marginTop: "0.13vh",
            height: "100%",
            width: "100%",
          }}
        />
      );
    } else if (status === "running") {
      return (
        <CircularProgressIndicator
          // value={process}
          className="check-box-btn"
          size="30px"
          style={{
            color: "black",
            // height: "100%",
            // width: "100%",
            // marginLeft: "0.65vw",
            // marginRight: "0.32vw",
          }}
        />
      );
    } else {
      return (
        <Checkbox
          id={field.id}
          icon={
            <img className="check-box-btn" src={CheckEmpty} alt="Check icon" />
          }
          checked={field.isChecked}
          onChange={(event) =>
            handleIndividualFieldCheckBoxChange(event, field.id)
          }
          checkedIcon={
            <img className="check-box-btn" src={CheckChange} alt="Check icon" />
          }
          name="field.name"
        />
      );
    }
  };
  const handleMultiImageChecked = async (event,itemId,collectionId,fileId,fieldSlug,index) =>{
    const Checked = event.currentTarget.checked;
    const updatedCollectionData = collectionData.map(collection =>{
      let fieldData
      if(collectionId === collection.collectionDetails.id){
        collection.itemData.map((item) =>{
          if(item.id === itemId){
            fieldData = item.fieldData[fieldSlug]
            fieldData.map((imageData)=>{
              if(imageData.fileId === fileId){
                if (Checked) {
                  setSelectedMultiImage((prvId) => {
                    if (!prvId.includes(fileId)) {
                      return [...prvId, fileId];
                    }else{
                      return [...prvId]
                    }
                  });
                  updateImageCount(collection, itemId, {type:"MultiImage"}, true,1);
                } else if(Checked === false) {
                  setSelectedMultiImage((prvId) => prvId.filter(id => id !== fileId));
                  updateImageCount(collection, itemId, {type:"MultiImage"}, false,1);
                }                
                imageData.isChecked = Checked
              }
            })
          }
          return item
        })
        collection.collectionFields.map(field => {
          if(field.slug === fieldSlug){
            if (Checked === true) {
              field.isChecked = Checked;
              if (!field.isCheckedItems.includes(itemId)) {
                field.isCheckedItems.push(itemId);
              }
            } else if (Checked === false) {
              const check = fieldData.some((field)=>{
                return field.isChecked
              })
              if(!check){

              if (field.isCheckedItems.length > 1) {
                const indexOfItemId = field.isCheckedItems.indexOf(
                  itemId
                );
                if (indexOfItemId != -1) {
                  field.isCheckedItems.splice(indexOfItemId, 1);
                }
              } else {
                const indexOfItemId = field.isCheckedItems.indexOf(
                  itemId
                );
                if (indexOfItemId != -1) {
                  field.isCheckedItems.splice(indexOfItemId, 1);
                }
                field.isChecked = Checked;
              }
            }
          }
            return field;

          }
        })
      }
      return collection
    })
    updateCollectionData(updatedCollectionData)
    const updatedItemChecked = updateCheckBoxItemDataInArr(collectionData,itemId,"isChecked",Checked,collectionId)
    updateCollectionData(updatedItemChecked)

  }
  const handleSelectAll = (selectAll) => {
    console.log(selectAll)
    collectionData.forEach((collection,index) => {
        const event = {
          currentTarget: {
            checked: selectAll,
            type: 'checkbox',
          },
        };
  
        handleCollectionCheckboxChange(
          collection.collectionDetails.id,
          event,                        
          collection,               
          index               
        );
    });
    setAllSelected(selectAll);
  };
  
  const handleCollectionCheckboxChange = async (
    collectionId,
    event,
    selectedCollectionData,
    index
  ) => {
    console.log(collectionId);
    console.log(event);
    console.log(selectedCollectionData);
    console.log(index);

    if (
      (selectedCollectionID == null ||
        selectedCollectionID !== selectedCollectionData.collectionDetails.id) &&
      event.currentTarget.type !== "checkbox"
    ) {
      collectionData.map((collection) => {
        if (collection.collectionDetails.id === collectionId) {
          collection.itemData.map((item) => {
            const fieldData = item.fieldData;
            let emptyCount = 0;
            collection.collectionFields.map((field) => {
              if(type === 'convert'){
                if (
                  (field.type === "Image" &&
                    (fieldData[field.slug]?.url === null || fieldData[field.slug]?.pixieConverted === true)) ||
                  (field.type === "MultiImage" &&
                    fieldData[field.slug]?.length <= 0) ||
                  (field.type === "RichText" &&
                    (fieldData[field.slug + "-pixie-optimized"]?.length <= 0 || (fieldData[field.slug + "-pixie-optimized"]?.every((data)=>{return data.pixieConverted})))) ||
                  fieldData[field.slug] === undefined
                ) {
                  emptyCount += 1;
                }else{
                  if(field.type === "MultiImage"){
                    const imagesData = fieldData[field.slug]
                    let countImage = 0
                    imagesData.map((image)=>{
                      if(image.pixieConverted === false){
                        countImage += 1
                      }
                    })
                    if(countImage === 0){
                      emptyCount += 1;
                    }
                  }
                }
              }else{
                if (
                  (field.type === "Image" &&
                    (fieldData[field.slug]?.url === null || fieldData[field.slug]?.pixieOptimized === true || fieldData[field.slug]?.pixieConverted === true)) ||
                  (field.type === "MultiImage" &&
                    fieldData[field.slug]?.length <= 0) ||
                  (field.type === "RichText" &&
                    (fieldData[field.slug + "-pixie-optimized"]?.length <= 0 || (fieldData[field.slug + "-pixie-optimized"]?.every((data)=>{return data.pixieOptimized})) || (fieldData[field.slug + "-pixie-optimized"]?.every((data)=>{return data.pixieConverted})))) ||
                  fieldData[field.slug] === undefined
                ) {
                  emptyCount += 1;
                }else{
                  if(field.type === "MultiImage"){
                    const imagesData = fieldData[field.slug]
                    let countImage = 0
                    imagesData.map((image)=>{
                      if(image.pixieOptimized === false){
                        countImage += 1
                      }else if(image.pixieConverted === false){
                        countImage += 1
                      }
                    })
                    if(countImage === 0){
                      emptyCount += 1;
                    }
                  }
                }
              }
            });
            if (emptyCount === collection.collectionFields.length) {
              setEmptyItems((prevEmptyItems) => {
                if (!prevEmptyItems.includes(item.id)) {
                  const updatedEmptyItems = [...prevEmptyItems, item.id];
                  return updatedEmptyItems;
                }
                return prevEmptyItems;
              });
            }
          });
        }
      });
      handleCollectionClick(selectedCollectionData, index);
      setSelectedItemID(null);
    } else {
      let CHECKED = event.currentTarget.checked;
      // console.log(CHECKED)
      // console.log(selectedCollectionIds)
      if (CHECKED === true && selectedCollectionIds?.includes(collectionId)) {
        CHECKED = false;
      }
      const updatedCollectionData = collectionData.map((collection) => {
        if (!optimizationInProgress) {
          if (collection.collectionDetails.id === collectionId) {
            collection.itemData = collection.itemData.map((item) => {
              const fieldData = item.fieldData;

              let emptyCount = 0;
              collection.collectionFields = collection.collectionFields.map(
                (field) => {
                  if (
                    ((((type === 'convert') && (field.type === "Image" &&
                      fieldData[field.slug]?.url !== null && fieldData[field.slug]?.pixieConverted !== true) ||
                      (field.type === "MultiImage" &&
                        fieldData[field.slug]?.length > 0) ||
                      (field.type === "RichText" &&
                        fieldData[field.slug + "-pixie-optimized"]?.length >
                          0 && !(fieldData[field.slug + "-pixie-optimized"]?.every((data)=>{return data.pixieConverted}))))) 
                          
                          || (((type === 'optimize') && (field.type === "Image" &&
                          fieldData[field.slug]?.url !== null && fieldData[field.slug]?.pixieOptimized !== true && fieldData[field.slug]?.pixieConverted !== true) ||
                          (field.type === "MultiImage" &&
                            fieldData[field.slug]?.length > 0) ||
                          (field.type === "RichText" &&
                            fieldData[field.slug + "-pixie-optimized"]?.length >
                              0 && !(fieldData[field.slug + "-pixie-optimized"]?.every((data)=>{return data.pixieOptimized})) && !(fieldData[field.slug + "-pixie-optimized"]?.every((data)=>{return data.pixieConverted})))))) &&
                    fieldData[field.slug] !== undefined
                  ) {
                    if (CHECKED === true) {
                      if(field.type === 'RichText'){
                        setSelectedRichText(prvSelected => prvSelected + 1);
                      }
                      if(field.type === 'MultiImage'){
                        const imagesData = fieldData[field.slug]
                        let countImage = 0
                        imagesData.map((image)=>{
                          if((type === 'convert' && image.pixieConverted === false) || (type === 'optimize' && image.pixieConverted === false && image.pixieOptimized === false)){
                              setSelectedMultiImage((prvId) => {
                                console.log(prvId)
                                if (!prvId.includes(image.fileId)) {
                                  return [...prvId, image.fileId];
                                }else{
                                  return [...prvId]
                                }
                              });             
                            image.isChecked = CHECKED
                            countImage += 1
                          }
                        })
                        if(countImage !== 0){
                          field.isChecked = CHECKED;
                          if (!field.isCheckedItems.includes(item.id)) {
                            field.isCheckedItems.push(item.id);
                            updateImageCount(collection, item.id, field, true,countImage);
                          }
                        }else{
                          emptyCount += 1
                        }
                      }else{
                        field.isChecked = CHECKED;
                        if (!field.isCheckedItems.includes(item.id)) {
                          field.isCheckedItems.push(item.id);
                          updateImageCount(collection, item.id, field, true,0);
                        }
                      }
                    } else if (CHECKED === false) {
                      if (field.isCheckedItems.length > 1) {
                        const indexOfItemId = field.isCheckedItems.indexOf(
                          item.id
                        );
                        if (indexOfItemId != -1) {
                          field.isCheckedItems.splice(indexOfItemId, 1);
                        }
                      } else {
                        const indexOfItemId = field.isCheckedItems.indexOf(
                          item.id
                        );
                        if (indexOfItemId != -1) {
                          field.isCheckedItems.splice(indexOfItemId, 1);
                        }
                        field.isChecked = CHECKED;
                      }
                      if(field.type === 'RichText'){
                        setSelectedRichText(prvSelected => prvSelected - 1);
                      }
                      if(field.type === 'MultiImage'){
                        const imagesData = fieldData[field.slug]
                        let imgCount = 0
                        imagesData.map((image)=>{
                          setSelectedMultiImage((prvId) => prvId.filter(id => id !== image.fileId));
                          if(image.isChecked){
                            imgCount += 1
                          }
                          image.isChecked = CHECKED
                        })
                        updateImageCount(collection, item.id, field, false,imgCount);
                      }else{
                        updateImageCount(collection, item.id, field, false,0);
                      }
                    }
                    return field;
                  } else {
                    emptyCount += 1;
                    return field;
                  }
                }
              );
              if (emptyCount !== collection.collectionFields.length) {
                item.isChecked = CHECKED;
              } else {
                if (!emptyItems.includes(item.id)) {
                  const updatedEmptyItems = [...emptyItems, item.id];
                  setEmptyItems(updatedEmptyItems);
                }
              }

              return item;
            });
            if (CHECKED === true) {
              let collectionArray = selectedCollectionIds;
              collectionArray.push(collectionId);
              setSelectedCollectionIds(collectionArray);
            } else if (CHECKED === false) {
              let collectionArray = selectedCollectionIds;
              // console.log(collectionArray.indexOf(collectionId))
              let slicedArray = collectionArray.slice(
                collectionArray.indexOf(collectionId),
                0
              );
              // console.log(slicedArray)
              setSelectedCollectionIds(slicedArray);
            }
          }
        }
        return collection;
      });

      updateCollectionData(updatedCollectionData);
      const isAllSelected = collectionData.every((collection) =>
        collection.itemData.every((item) => item.isChecked)
      );
    
      setAllSelected(isAllSelected);
    }
  };
  // ** Method for updating the image count
  function updateImageCount(ele, itemId, fieldData, addOrSub,multiImageCount) {
    let imagesCount = 0;
    ele.itemData.forEach((item) => {
      if (item.id === itemId) {
        if (fieldData.type === "Image") {
          imagesCount = imagesCount + 1;
        } else if (fieldData.type === "MultiImage") {
          imagesCount = imagesCount + multiImageCount;
        } else if (fieldData.type === "RichText") {
          let richTextCheck =
            item.fieldData[fieldData.slug + "-pixie-optimized"];
            if(richTextCheck){
              imagesCount = imagesCount + richTextCheck.length;
            }
        }
      }
    });
    if (addOrSub) {
      setSelectedImagesCount((prevCount) => prevCount + imagesCount);
    } else if (!addOrSub) {
      setSelectedImagesCount((prevCount) => prevCount - imagesCount);
    }
  }

  // ** individual checkbox changes
  const updateCheckBoxFieldDataInArr = (
    arrData,
    fieldId,
    key,
    value,
    itemId
  ) => {
    return arrData.map((ele) => {
      ele.collectionFields = ele.collectionFields.map((fieldData) => {
        if (value === true) {
          if (fieldData.id === fieldId) {
            fieldData[key] = value;
            if (!fieldData.isCheckedItems.includes(itemId)) {
              fieldData.isCheckedItems.push(itemId);
              // ** update the selected images count
            }
            if(fieldData.type === 'RichText'){
              setSelectedRichText(prvSelected => prvSelected + 1);
            }
            if(fieldData.type === 'MultiImage'){
              ele.itemData.map((item) =>{
                if(item.id === itemId){
                  const imagesData = item.fieldData[fieldData.slug]
                  imagesData.map((image)=>{
                      setSelectedMultiImage((prvId) => {
                        if (!prvId.includes(image.fileId)) {
                          return [...prvId, image.fileId];
                        }else{
                          return [...prvId]
                        }
                      });
                    image.isChecked = value
                  })
                  updateImageCount(ele, itemId, fieldData, true,imagesData.length);
                }
              })
            }else{
              updateImageCount(ele, itemId, fieldData, true,0);
            }
          }
        } else if (value === false) {
          if (fieldData.id === fieldId) {
            // console.log("len:",fieldData.isCheckedItems.length)
            if (fieldData.isCheckedItems.length > 1) {
              const indexOfItemId = fieldData.isCheckedItems.indexOf(itemId);
              if (indexOfItemId != -1) {
                fieldData.isCheckedItems.splice(indexOfItemId, 1);
              }
            } else {
              const indexOfItemId = fieldData.isCheckedItems.indexOf(itemId);
              if (indexOfItemId != -1) {
                fieldData.isCheckedItems.splice(indexOfItemId, 1);
              }
              fieldData[key] = value;
            }
            if(fieldData.type === 'RichText'){
              setSelectedRichText(prvSelected => prvSelected - 1);
            }
            if(fieldData.type === 'MultiImage'){
              ele.itemData.map((item) =>{
                if(item.id === itemId){
                  const imagesData = item.fieldData[fieldData.slug]
                  imagesData.map((image)=>{
                    setSelectedMultiImage((prvId) => prvId.filter(id => id !== image.fileId));
                    image.isChecked = value
                  })
                  updateImageCount(ele, itemId, fieldData, false,imagesData.length);
                }
              })
            }else{
              updateImageCount(ele, itemId, fieldData, false);
            }
          }
        }

        return fieldData;
      });
      return ele;
    });
  };
  const updateCheckBoxItemDataInArr = (arrData, fieldId, key, value,collectionId) => {
    return arrData.map((ele) => {
      console.log(ele);
      if(ele.collectionDetails.id === collectionId){
      if(value === true){
        ele.itemData = ele.itemData.map((fieldData) => {
          if (fieldData.id === fieldId) {
            fieldData[key] = value;
          }
          return fieldData;
        });
      }else if(value === false){
        const check = ele.collectionFields.some((field)=>{
          return field.isCheckedItems.includes(fieldId)
        })
        if(!check){
          ele.itemData = ele.itemData.map((fieldData) => {
            if (fieldData.id === fieldId) {
              fieldData[key] = value;
            }
            return fieldData;
          });
        }
      }
    }
      return ele;
    });
    
  };
  const showFieldData = (fieldData, itemFieldData, collectionId, itemId) => {
    const data = fieldData.map((field) => {
      if (field.type === "RichText") {
        return {
          data: itemFieldData[field.slug],
          urlData: itemFieldData[field.slug + "-pixie-optimized"],
        };
      } else {
        return itemFieldData[field.slug];
      }
    });
    // console.log("FieldData: ",data);
    setSelectedFieldData({ collectionId, data });
    setSelectedItemID(itemId);
  };

  const handleIndividualCheckBoxChange = async (
    event,
    id,
    collectionFields,
    fieldData,
    collectionID,
    itemChecked
  ) => {
    // console.log(collectionData);
    if (
      (selectedItemID == null || selectedItemID != id) &&
      event.currentTarget.type !== "checkbox"
    ) {
      showFieldData(collectionFields, fieldData, collectionID, id);
    } else {
      if (!optimizationInProgress) {
        let CHECKED = await event.currentTarget.checked;
        // console.log(CHECKED);
        // console.log(itemChecked)
        if (CHECKED === true && itemChecked === true) {
          CHECKED = false;
        }
        collectionFields.map((field) => {
          // console.log(field.type)
          // console.log("FDI:",fieldData[field.slug])
          // console.log((field.type==="Image"&&(fieldData[field.slug]?.url!==null || fieldData[field.slug]!==undefined)) || (field.type==="MultiImage" && (fieldData[field.slug]?.length>0 || fieldData[field.slug]!==undefined)))
          if (
            ((field.type === "Image" && fieldData[field.slug]?.url !== null) ||
              (field.type === "MultiImage" &&
                fieldData[field.slug]?.length > 0) ||
              (field.type === "RichText" &&
                fieldData[field.slug + "-pixie-optimized"]?.length > 0)) &&
            fieldData[field.slug] !== undefined
          ) {
            const fieldsCheckUpdated = updateCheckBoxFieldDataInArr(
              collectionData,
              field.id,
              "isChecked",
              CHECKED,
              id
            );
            updateCollectionData(fieldsCheckUpdated);
          }
        });
        // console.log(CHECKED);
        const newArr = updateCheckBoxItemDataInArr(
          collectionData,
          id,
          "isChecked",
          CHECKED,
          collectionID
        );
        updateCollectionData(newArr);

      }
    }
    // console.log("selColl: ", selectedCollection);
    updateAllSelectedItemsState();
  };
  const updateAllSelectedItemsState = () => {
    const allSelected = collectionData.some((collection) =>
      collection.collectionDetails.id === selectedCollectionID &&
      collection.itemData.every((item) => item.isChecked)
    );
    setAllSelectedItems(allSelected);
  };
  const handleItemSelectAll = async (selectAll) => {
    for (let collection of collectionData) {
      // Check if the current collection matches the selected collection
      if (collection.collectionDetails.id === selectedCollectionID) {
        for (let item of collection.itemData) {
          if(!emptyItems.includes(item.id)){

          const event = {
            currentTarget: {
              checked: selectAll, // Simulate a checkbox event
              type: 'checkbox',
            },
          };
  
          await handleIndividualCheckBoxChange(
            event,                     // Simulated event object
            item.id,                   // Item ID
            collection.collectionFields, // Collection fields
            item.fieldData,            // Field data for the item
            collection.collectionDetails.id, // Collection ID
            item.isChecked             // Current checked state of the item
          );
        }
      }
      }
    }
  };
  
  const handleIndividualFieldCheckBoxChange = (event, id,collectionId) => {
    if (!optimizationInProgress) {
      const CHECKED = event.target.checked;
      // console.log(CHECKED);
      const newArr = updateCheckBoxFieldDataInArr(
        collectionData,
        id,
        "isChecked",
        CHECKED,
        selectedItemID
      );
      // console.log("newArr: ", newArr);
      updateCollectionData(newArr);
      const itemUpdate = updateCheckBoxItemDataInArr(
        collectionData,
        selectedItemID,
        "isChecked",
        CHECKED,
        collectionId
      );
      updateCollectionData(itemUpdate);
      // console.log("selColl: ", selectedCollection);
      // console.log("selectedItemId: ", selectedItemID);
      updateAllSelectedFieldsState();
    }
  };
  const isFieldEmpty = (field, index) => {
    return (
      (field.type === "Image" &&
        (selectedFieldData.data[index] === null ||
          selectedFieldData.data[index] === undefined)) ||
      (field.type === "MultiImage" &&
        (selectedFieldData.data[index]?.length === 0 ||
          selectedFieldData.data[index] === undefined)) ||
      (field.type === "RichText" &&
        selectedFieldData.data[index]?.urlData?.length === 0)
    );
  };
  const updateAllSelectedFieldsState = () => {
    const allSelected = collectionData.some((collection) =>
      collection.collectionDetails.id === selectedCollectionID &&
      collection.itemData.some((item) =>
        item.id === selectedItemID &&
        collection.collectionFields.every((field) => field.isChecked)
      )
    );
    setAllSelectedFields(allSelected);
  };
  const handleFieldSelectAll = (selectAll) => {
    console.log("entered");
    if (!optimizationInProgress) {
      console.log('enter')
      for (let collection of collectionData) {
        if (collection.collectionDetails.id === selectedCollectionID) {
          for (let item of collection.itemData) {
            if (item.id === selectedItemID) {
              for (let [index, field] of collection.collectionFields.entries()) {
                const fieldCheck = isFieldEmpty(field, index);
                console.log("fieldCheck: ",fieldCheck);
                if (!fieldCheck) {
                  const event = {
                    target: {
                      checked: selectAll, // Simulate a checkbox event
                    },
                  };
  
                  // Call the individual field check change function for non-empty fields
                  handleIndividualFieldCheckBoxChange(
                    event,                  // Simulated event object
                    field.id,               // Field ID
                    selectedCollectionID    // Collection ID
                  );
                }
              }
            }
          }
        }
      }
    }
  };
  
  const handleSelectAllClick = () => {
    if (!disable) {
      if (selectAllChecked === true) {
        setButtonClick(true);
      } else {
        setButtonClick(false);
      }
      setSelectAllChecked(!selectAllChecked);
    }
  };

  const backButtonRef = useRef(null);

  const handleBackButtonClick = async () => {
    if (!checkOptimization) {
      localStorage.removeItem("optimizationStatus");
      localStorage.removeItem("jobId");
      localStorage.removeItem("totalFields");
      await removeFromIndexedDB("collectionDataState");
      // localStorage.removeItem("collectionDataState");
    }
  };

  useEffect(() => {
    if (backButtonRef.current) {
      backButtonRef.current.addEventListener("click", handleBackButtonClick);
    }

    return () => {
      if (backButtonRef.current) {
        backButtonRef.current.removeEventListener(
          "click",
          handleBackButtonClick
        );
      }
    };
  }, [checkOptimization]);

  useEffect(() => {
    const handleCustomCheckboxChange = (event) => {
      const isChecked = event.detail;
      setSelectAllChecked(isChecked);
      setButtonClick(false);
    };

    document.addEventListener("checkboxChange", handleCustomCheckboxChange);

    return () => {
      document.removeEventListener(
        "checkboxChange",
        handleCustomCheckboxChange
      );
    };
  }, []);

  useEffect(() => {
    const handleDisable = () => {
      setDisable(false);
      setCheckOptimization(false);
    };

    document.addEventListener("collectionData", handleDisable);

    return () => {
      document.removeEventListener("collectionData", handleDisable);
    };
  }, []);

  useEffect(() => {
    const handleOptimizationInProgress = () => {
      setDisable(true);
      setCheckOptimization(true);
    };

    document.addEventListener(
      "optimizationInProgress",
      handleOptimizationInProgress
    );

    return () => {
      document.removeEventListener(
        "optimizationInProgress",
        handleOptimizationInProgress
      );
    };
  }, []);

  useEffect(() => {
    setAppApiKey(api);
    setAppSiteId(siteId);
    fetchCollections({ api, siteId });
    (async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}api/user-sites/${api}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
          },
        }
      );
      const data = await response.json();

      if (localStorage.getItem("optimizationStatus")) {
        localStorage.removeItem("optimizationStatus");
      }
      if (localStorage.getItem("jobId")) {
        localStorage.removeItem("jobId");
      }
      if (localStorage.getItem("totalFields")) {
        localStorage.removeItem("totalFields");
      }
      const collectionData = await getData("collectionDataState");
      if (collectionData) {
        await removeFromIndexedDB("collectionDataState")
      }
      setSitesData(data);
    })();
  }, []);
  let SiteName = "";
  if (sitesData.mappedSitesData) {
    const matchedSite = sitesData.mappedSitesData.find(
      (data) => data.siteUniqueId === siteId
    );
    SiteName = matchedSite ? matchedSite.name : "";
  }
  // const toggleSelectAll = () => {
  //   const updatedCollectionData = collectionData.map(collection => ({
  //     ...collection,
  //     collectionFields: collection.collectionFields.map(field => ({
  //       ...field,
  //       isChecked: !selectAllChecked,
  //     })),
  //   }));
  //   updateCollectionData(updatedCollectionData);
  //   setSelectAllChecked(!selectAllChecked);
  // };

  // **return selected items
  function returnSelectedItems(itemsData) {
    let selectedItem = 0;
    itemsData?.forEach((item) => {
      if (item.isChecked === true) {
        selectedItem = selectedItem + 1;
      }
    });
    // console.log(selectedItem)
    return selectedItem;
  }

  // **return selected fields
  function returnSelectedFields(itemData, selectedFieldsData) {
    console.log(selectedFieldsData);
    console.log(itemData);
    let selectedFields = 0;
    selectedFieldsData.forEach((field) => {
      if (field.isCheckedItems.includes(itemData.id)) {
        selectedFields = selectedFields + 1;
      }
    });
    return selectedFields;
  }

  return (
    <div className="section-main">
      {collectionData?.length > 0 && (
        <div className="main-block-new">
          {/* <div className="top-btn-wrapper">
            <Link to={type === 'convert' ? "/convert-to-webp" : "/pixie"}>
              <div className="back-btn-wrapper">
                <button className="back-button" ref={backButtonRef}>
                  <img src={Back}></img>
                </button>
                <div className="sub-head-20">Go Back</div>
              </div>
            </Link>
            {!optimizationInProgress && (
              <p className="sub-title-32">
                Select individual CMS items and their fields to {type=== 'convert' ? 'convert' :'optimize'}.
              </p>
            )}
            {!optimizationInProgress && (
              <div className="sub-head-20">
                Selected Images - {selectedImagesCount}
              </div>
            )}
          </div> */}
                  <div className="site-details-section">
          <div className="left-site-details-section">
              {/* <Link to={type === 'convert'? '/convert-to-webp' : '/pixie'}>
              <button className="back-button" ref={backButtonRef}><img src={Back}></img></button>
              </Link> */}
              {console.log("OIP: ",optimizationInProgress)}
              <div className="sites-upper-container">
                <div className="site-name">
                  {SiteName}
                </div>
                {!optimizationInProgress && (
                  <div className="sites-dropdown" onClick={toggleDropdown} ref={dropdownIconRef}>
                    <img className="sites-dropdown-arrow" src={UpArrow} alt="Up Arrow"/>
                  </div>
                )}
              </div>
              {showDropdown && (
                    <div className="dropdown-content" ref={dropdownRef}>
                        {siteList.map((site) => (
                          // <Link to={`/pixie/${site.siteId}/collections/${site.siteUniqueId}?type=optimize`}>
                            <div
                                key={site.siteUniqueId}
                                className="dropdown-item"
                                onClick={() => handleSiteClick(site.siteId, site.siteUniqueId)}
                            >
                                {site.name}
                            </div>
                          // </Link>
                        ))}
                    </div>
                )}
              {!optimizationInProgress ? (
                <div className="sub-heading-section">
                  <p className="head-lite">
                    Select the items you would like to {type === 'convert' ? 'convert' : 'optimize'}
                  </p>
                  <p className="info-text">
                    You will not be charged for the images that are already {type === 'convert' ? 'converted' : 'optimized'}.
                  </p>
                </div>
                ) : (
                  optimizedStatus ? 
                  <div className="result-subheading">Your optimization is completed!</div> : 
                  <div className="result-subheading">Please wait while your images are optimizing...</div>
                )}

          </div>
          {!optimizationInProgress && (
          <div className="right-site-details-section">
            <div 
              className="select-all" 
              onClick={() => handleSelectAll(!allSelected)}
            >
            {allSelected ? 'Deselect all' : 'Select all'}
            </div>
            <div className="right-down-site-section">
              {!optimizationInProgress &&
                (showPricingBtn ? (
                  <SecondaryCustomButton onClick={history.push("/pixie/pricing")} customClasses={'optimize-now'}>Plans</SecondaryCustomButton>
                  ) : (
                    <SecondaryCustomButton onClick={handleClick} customClasses={'optimize-now'}>{type === 'convert'? 'Convert Now' : 'Optimize Now'}</SecondaryCustomButton>
                  ))}
              {/* <SecondaryCustomButton customClasses={'optimize-now'}>Optimize Now</SecondaryCustomButton> */}
              <p className="selected-images">Images Selected: <span className="images-count">{selectedImagesCount}</span></p>
            </div>
            
            {/* <OptimizationTypeBox /> */}
            {/* <div className="alerts">
              {message.msg && (
                <ActionAlerts text={message.msg} type={message.type} />
              )}
            </div> */}        
            {/* <div className="nxt-prv">
            <div className="button-container">

            {showOptimizeBtn && (
              <CustomButton
                onClick={submitChange}
                mr={true}
                disabled={isBtnDisabled}
                black={!isBtnDisabled}
              >
                {btnText}
              </CustomButton>
            )}
            {showPricingBtn && (
              <Link to="/pixie/pricing">
                <CustomButton black={true}>Upgrade Plan</CustomButton>
              </Link>
            )}
            <CustomButton onClick={goBackBtn} black={true} mr={true}>
                Back
              </CustomButton>
            </div>

            </div> */}
          </div>
          )}
        </div>
          <Container style={{ margin: "0", padding: "0" }} maxWidth="xl">
            <div className="top-bottom-block-new">
              <div className="flex-container">
                {/* Left Panel */}
                {!optimizationInProgress && (
                  <div className={`left-panel panel`}>
                    {!optimizationInProgress && (
                      <div className="flex-row-head-wrapper">
                        <p className="text-24">CMS</p>
                        <p 
                          className="select-all-field" 
                          onClick={() => handleSelectAll(!allSelected)}
                        >
                          {allSelected ? 'Deselect all' : 'Select all'}
                        </p>
                        {/* <Link
                          to={{ pathname: "https://www.trypixie.io" }}
                          target="_blank"
                        >
                          <HelpOutlineOutlinedIcon fontSize="small" />
                        </Link> */}
                      </div>
                    )}
                    <div className="box-optimization-wrapper scroll-bar">
                      {!optimizationInProgress &&
                        collectionData &&
                        collectionData.map(
                          (
                            { collectionDetails, collectionFields, itemData },
                            index
                          ) => (
                            <div
                              key={collectionDetails["id"]}
                              className={`collection-item ${
                                selectedCollectionID ===
                                  collectionDetails["id"] && "is-selected"
                              }`}
                              onClick={(event) =>
                                handleCollectionCheckboxChange(
                                  collectionDetails.id,
                                  event,
                                  // event.target.checked,
                                  collectionData[index],
                                  index
                                )
                              }
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={
                                      <img
                                        className="check-box-btn"
                                        src={
                                          collectionFields.some(
                                            (field) => field.isChecked
                                          ) ||
                                          itemData.some(
                                            (item) => item.isChecked
                                          )
                                            ? CheckPartial
                                            : CheckEmpty
                                        }
                                        alt="Check icon"
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        className="check-box-btn"
                                        src={CheckChange}
                                        alt="Check icon"
                                      />
                                    }
                                    checked={
                                      collectionFields.every(
                                        (field) => field.isChecked
                                      ) &&
                                      itemData.every(
                                        (item) => item.isChecked
                                      ) &&
                                      collectionFields.length > 0
                                    }
                                    onChange={(event) =>
                                      handleCollectionCheckboxChange(
                                        collectionDetails.id,
                                        event,
                                        // event.target.checked,
                                        collectionData[index],
                                        index
                                      )
                                    }
                                  />
                                }
                              />
                              <div className="separator"></div>
                              <div className="sub-head-20">
                                {collectionDetails.displayName}
                                {returnSelectedItems(itemData) !== 0 && (
                                  <span className="ml-10">
                                    ({returnSelectedItems(itemData)})
                                  </span>
                                )}
                              </div>
                            </div>
                          )
                        )}
                    </div>
                    {/* optimization button wrapper */}

                    {/* <div className="btn-wrapper">
                      {!optimizationInProgress &&
                        (showPricingBtn ? (
                          <CustomButton
                            onClick={history.push("/pixie/pricing")}
                            black={true}
                            // style={{ width: "100px", height: "50px" }}
                          >
                            Plan
                          </CustomButton>
                        ) : (
                          <CustomButton
                            onClick={handleClick}
                            black={true}
                            // style={{ width: "100px", height: "50px" }}
                          >
                            {type === 'convert'? 'Convert' : 'Optimize'}
                          </CustomButton>
                        ))}
                    </div> */}
                  </div>
                )}

                {/* Right Panel */}
                {/* {collectionData && collectionData.map(({ collectionDetails, collectionFields ,itemData})=>(
                <> */}
                {!optimizationInProgress && (
                  <div className="top-sec-new panel">
                    <div className="flex-row-head-wrapper">
                      <p className="text-24">Items</p>
                      <p
                        className="select-all-field"
                        onClick={() => handleItemSelectAll(!allSelectedItems)}
                      >
                        {allSelectedItems ? "Deselect all" : "Select all"}
                      </p> 
                     {/* <Link
                        to={{ pathname: "https://www.trypixie.io" }}
                        target="_blank"
                      >
                        <HelpOutlineOutlinedIcon fontSize="small" />
                      </Link> */}
                    </div>
                    <div className="item-box-wrapper scroll-bar">
                      {selectedCollection &&
                        selectedCollection.itemData.map((item, index) => (
                          <div
                            key={item.id}
                            className={`item-wrapper ${
                              selectedItemID === item.id && "is-selected"
                            }`}
                            onClick={(event) =>
                              handleIndividualCheckBoxChange(
                                event,
                                item.id,
                                selectedCollection.collectionFields,
                                item.fieldData,
                                selectedCollection.collectionDetails.id,
                                item.isChecked
                              )
                            }
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={
                                    // <img
                                    //   className="check-box-btn"
                                    //   src={CheckEmpty}
                                    //   alt="Check icon"
                                    // />
                                    <img
                                      className="check-box-btn"
                                      src={
                                        (selectedCollection.collectionFields.some(
                                          (field) => field.isChecked
                                        ) &&
                                          selectedCollection.collectionFields.some(
                                            (field) =>
                                              field.isCheckedItems.includes(
                                                item.id
                                              )
                                          )) ||
                                        item.isChecked
                                          ? CheckPartial
                                          : emptyItems.includes(item.id)
                                          ? CheckGrey
                                          : CheckEmpty
                                      }
                                      alt="Check icon"
                                    />
                                  }
                                  checkedIcon={
                                    <img
                                      className="check-box-btn"
                                      src={CheckChange}
                                      alt="Check icon"
                                    />
                                  }
                                  checked={
                                    selectedCollection.collectionFields.every(
                                      (field) => field.isChecked
                                    ) &&
                                    selectedCollection.collectionFields.every(
                                      (field) =>
                                        field.isCheckedItems.includes(item.id)
                                    ) &&
                                    item.isChecked
                                  }
                                  disabled={
                                    selectedFieldData?.data?.every(
                                      (item) =>
                                        item == null ||
                                        item?.length === 0 ||
                                        item?.urlData?.length === 0
                                    ) || emptyItems.includes(item.id)
                                  }
                                  onChange={(event) =>
                                    handleIndividualCheckBoxChange(
                                      event,
                                      item.id,
                                      selectedCollection.collectionFields,
                                      item.fieldData,
                                      selectedCollection.collectionDetails.id,
                                      item.isChecked
                                    )
                                  }
                                />
                              }
                              // label={
                              //   <div className="collection-name-txt">
                              //     {item.fieldData.name}
                              //   </div>
                              // }
                            />
                            <div className="sub-head-20">
                              {item.fieldData.name}
                              {returnSelectedFields(
                                item,
                                selectedCollection?.collectionFields
                              ) !== 0 && (
                                <span className="ml-10">
                                  (
                                  {returnSelectedFields(
                                    item,
                                    selectedCollection?.collectionFields
                                  )}
                                  )
                                </span>
                              )}
                            </div>
                          </div>
                        ))}
                      {selectedCollection == null && (
                        <p className="item-box-empty-text">Please select a cms to view items.</p>
                      )}
                    </div>
                  </div>
                )}
                {!optimizationInProgress && (
                  <div className="field-sec panel">
                    <div className="flex-row-head-wrapper">
                      <p className="text-24">Fields</p>
                      <p className="select-all-field" onClick={() => handleFieldSelectAll(!allSelectedFields)}>
                        {allSelectedFields ? "Deselect all" : "Select all"}
                      </p>
                    </div>
                    {selectedItemID == null && (
                      <p className="item-box-empty-text">Please select an item to view fields.</p>
                    )}
                    <div className="fields-wrapper">
                      {selectedItemID != null &&
                        selectedCollection?.collectionFields?.length > 0 &&
                        selectedCollection.collectionFields.map(
                          (field, index) => (
                            <div className="field-flex" key={field.id}>
                              <div className="field-details">
                                <div className="checkbox-wrapper">
                                <FormControlLabel
                                  control={
                                    optimizationInProgress ? (
                                      <div className="icon-status">
                                        {jobsInfo[
                                          selectedCollection.collectionDetails
                                            .displayName
                                        ]?.map(
                                          ({
                                            collectionFieldData,
                                            state,
                                            progress,
                                          }) =>
                                            // const jobMatchesField = collectionFieldData.id === field.id;
                                            collectionFieldData.map(
                                              (fieldData) => (
                                                <div key={fieldData.id}>
                                                  {fieldData.id === field.id &&
                                                    IconByStatus(
                                                      state,
                                                      field,
                                                      progress
                                                    )}
                                                </div>
                                              )
                                            )
                                        )}
                                        {!fieldsId.includes(field.id) && (
                                          <Checkbox
                                            id={field.id}
                                            icon={
                                              <img style={{ backgroundColor: 'transparent' }}
                                                className='check-box-btn' src={CheckEmpty} alt="Check icon" />
                                            }
                                            checked={
                                              field.type === "MultiImage" ? (
                                                field.isCheckedItems.includes(selectedItemID) && 
                                                field.isChecked && 
                                                selectedFieldData.data[index].every(data => data.isChecked)
                                              ) : (
                                                field.isCheckedItems.includes(selectedItemID) && 
                                                field.isChecked
                                              )
                                            }                                            
                                            onChange={(event) =>
                                              handleIndividualFieldCheckBoxChange(
                                                event,
                                                field.id,
                                                selectedCollection.collectionDetails.id
                                              )
                                            }
                                            checkedIcon={
                                              <img style={{ backgroundColor: 'transparent' }}
                                                className='check-box-btn' src={CheckChange} alt="Check icon" />
                                            }
                                            name="field.name"
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <Checkbox
                                        id={field.id}
                                        icon={
                                          <img
                                            className="check-box-btn"
                                            src={
                                              (field.type === "Image" &&
                                                (selectedFieldData.data[
                                                  index
                                                ] === null ||
                                                  selectedFieldData.data[
                                                    index
                                                  ] === undefined)) ||
                                              (field.type === "MultiImage" &&
                                                (selectedFieldData.data[index]
                                                  ?.length === 0 ||
                                                  selectedFieldData.data[
                                                    index
                                                  ] === undefined)) ||
                                              (field.type === "RichText" &&
                                                selectedFieldData.data[index]
                                                  ?.urlData?.length === 0)
                                                ? CheckGrey
                                                : CheckEmpty
                                            }
                                            alt="Check icon"
                                            style={{ backgroundColor: 'transparent' }}
                                          />
                                        }
                                        // ** Check this for no data items
                                        checked={
                                          field.isCheckedItems.includes(
                                            selectedItemID
                                          ) &&
                                          field.isChecked &&
                                          ((field.type === "Image" &&
                                            selectedFieldData.data[index] !==
                                              null) ||
                                            (field.type === "MultiImage" &&
                                              selectedFieldData.data[index]
                                                ?.length !== 0 &&  selectedFieldData.data[index].every(data => data.isChecked)) ||
                                            (field.type === "RichText" &&
                                              selectedFieldData.data[index]
                                                ?.urlData?.length !== 0)) &&
                                          selectedFieldData.data[index] !==
                                            undefined
                                        }
                                        onChange={(event) =>
                                          handleIndividualFieldCheckBoxChange(
                                            event,
                                            field.id,
                                            selectedCollection.collectionDetails.id
                                          )
                                        }
                                        disabled={
                                          (selectedFieldData &&
                                            field.type === "Image" &&
                                            (selectedFieldData.data[index] ===
                                              null ||
                                              selectedFieldData.data[index] ===
                                                undefined)) ||
                                          (field.type === "MultiImage" &&
                                            (selectedFieldData.data[index]
                                              ?.length === 0 ||
                                              selectedFieldData.data[index] ===
                                                undefined)) ||
                                          (field.type === "RichText" &&
                                            selectedFieldData.data[index]
                                              ?.urlData?.length === 0)
                                        }
                                        checkedIcon={
                                          <img
                                            className="check-box-btn"
                                            src={CheckChange}
                                            alt="Check icon"
                                            style={{ backgroundColor: 'transparent' }}
                                          />
                                        }
                                        name="field.name"
                                      />
                                    )
                                  }
                                />
                                <div className="two-row-flex">
                                  <div className="sub-head-20 remove-padding">
                                    {(field.type === "Image" &&
                                      (selectedFieldData.data[index] === null ||
                                        selectedFieldData.data[index] ===
                                          undefined)) ||
                                    (field.type === "MultiImage" &&
                                      (selectedFieldData.data[index]?.length ===
                                        0 ||
                                        selectedFieldData.data[index] ===
                                          undefined))
                                      ? "No data."
                                      : field.displayName}
                                  </div>
                                  <div className="field-type">
                                    {field.type === "Image" && (
                                      <div className="image-icon">
                                        <img
                                          height="100%"
                                          width="100%"
                                          src={ImageIcon}
                                          alt="main"
                                          className="icon-16"
                                        />
                                        <span className="text-14">Image</span>
                                      </div>
                                    )}
                                    {field.type === "MultiImage" && (
                                      <div className="image-icon">
                                        <img
                                          height="100%"
                                          width="100%"
                                          src={MultiImageIcon}
                                          alt="main"
                                          className="icon-16"
                                        />
                                        <span className="text-14">
                                          Multi-Image
                                        </span>
                                      </div>
                                    )}
                                    {field.type === "RichText" && (
                                      <div className="image-icon">
                                        <img
                                          height="100%"
                                          width="100%"
                                          src={RichTextIcon}
                                          alt="main"
                                          className="icon-16"
                                        />
                                        <span className="text-14">
                                          Rich Text
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                </div>
                                {(selectedFieldData.data[index]
                                  ?.pixieOptimized || selectedFieldData.data[index]
                                  ?.pixieConverted)  && (
                                  <div className="optimised-by">
                                    {(selectedFieldData.data[index]
                                      ?.pixieOptimized && "Optimised") || (selectedFieldData.data[index]
                                      ?.pixieConverted && "Converted & Optimised")}
                                  </div>
                                )}
                              </div>
                              {selectedFieldData &&
                                selectedFieldData.data &&
                                selectedFieldData.collectionId ===
                                  selectedCollection.collectionDetails.id && (
                                  <>
                                  {console.log(selectedFieldData)}
                                    {field.type === "Image" &&
                                      selectedFieldData.data[index]?.url && (
                                        <div className="image-with-name">
                                          <img
                                            height="100%"
                                            width="100%"
                                            className="field-image"
                                            src={
                                              selectedFieldData.data[index]?.url
                                            }
                                            alt="data-field"
                                          />
                                          <div className="image-name">
                                            {field.displayName}
                                          </div>
                                        </div>
                                      )}
                                    {field.type === "MultiImage" && (
                                      <div>
                                        {/* {console.log(selectedFieldData.data[index])}
                                        {selectedFieldData.data[index].map((item, idx) => (
                                            (item.pixieOptimized || item.pixieConverted) && (
                                              <div key={idx} className="optimised-by" style={{marginLeft:'2.5vw',marginBottom:'2vh',display: 'inline-block'}}>
                                                {(item.pixieOptimized && "Optimised") || (item.pixieConverted && "Converted & Optimised")}
                                              </div>
                                            )
                                          ))} */}
                                        {selectedFieldData.data[index] &&
                                          selectedFieldData.data[index].map(
                                            (url, subIndex) => (
                                              <FormControlLabel
                                              control={
                                                <Checkbox
                                                  icon={
                                                    <img
                                                      className="check-box-btn"
                                                      src={CheckEmpty
                                                      }
                                                      alt="Check icon"
                                                      style={{ backgroundColor: 'transparent' }}
                                                    />
                                                  }
                                                  checkedIcon={
                                                    <img
                                                      className="check-box-btn"
                                                      src={CheckChange}
                                                      alt="Check icon"
                                                      style={{ backgroundColor: 'transparent' }}
                                                    />
                                                  }
                                                  checked={
                                                    url?.isChecked
                                                  }
                                                  onChange={(event) =>
                                                    handleMultiImageChecked(event,selectedItemID,selectedFieldData.collectionId,url?.fileId,field.slug,index)
                                                  }
                                                />
                                              }
                                              label={
                                                <>
                                                {url.pixieOptimized && (
                                                  <div className="optimised-by" style={{marginTop:'2vw',marginBottom:'1vh',display: 'inline-block'}}>Optimised</div>
                                                )}
                                                {url.pixieConverted && (
                                                  <div className="optimised-by" style={{marginTop:'2vw',marginBottom:'1vh',display: 'inline-block'}}>Converted & Optimised</div>
                                                )}
                                                <img
                                                  key={subIndex}
                                                  src={url?.url}
                                                  alt={`Image ${subIndex + 1}`}
                                                  style={{ width: "100%", display: 'block', marginBottom: '8px' }}
                                                />
                                              </>}
                                            />
                                            )
                                          )}
                                      </div>
                                    )}
                                    {field.type === "RichText" &&
                                      selectedFieldData.data[index] && (
                                        <>
                                        <div style={{ display: 'flex' }}>
                                          {selectedFieldData.data[index]?.urlData?.length > 0 && selectedFieldData.data[index]?.urlData?.every(item => item.pixieOptimized) && (
                                            <div className="optimised-by">Optimised</div>
                                          )}

                                          {selectedFieldData.data[index]?.urlData?.length > 0 && selectedFieldData.data[index]?.urlData?.every(item => item.pixieConverted) && (
                                            <div className="optimised-by">Converted & Optimised</div>
                                          )}
                                        </div>
                                          <div
                                            className="rich-text-field"
                                            style={{ height: "100%" }}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                selectedFieldData.data[index]
                                                  .data,
                                            }}
                                          />
                                        </>
                                      )}
                                  </>
                                )}
                            </div>
                          )
                        )}
                    </div>
                  </div>
                )}
                {/* Result screen */}
                {optimizationInProgress && (
                  <Results
                    toOptimizeData={toOptimizeData}
                    jobsInfo={jobsInfo}
                    optimizedStatus={optimizedStatus}
                    totalFields={totalFields}
                    optimized={optimized}
                    type={type}
                    results={results}
                    bulkOptimization={false}
                  ></Results>
                )}
              </div>
            </div>
          </Container>
        </div>
      )}
      {message.type == 'error'? message.msg=='Unable to find collections in the site. Make sure the site has CMS collections.' && collectionData.length == 0 ? (<div className='no-collection'>No Collection with images found in this site. If you think this is a mistake. <a href="https://www.trypixie.io/contact-us"> Reach out to us.</a></div>):(<div className='no-collection'> {message.msg} </div>): message.type == 'info' && collectionData.length == 0 && (<span className="loader"></span>)}
      {isModalOpen && (
        // <Modal>
          <MultiOptionBox toggleModal={closeOpenModal} />
        // </Modal>
      )}
    </div>
  );
}

export default ItemOptimization;
