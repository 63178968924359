import React from 'react';
import './Loader.styles.scss';

const LoaderDots = () => {
  return (
    <div className="loader-dots">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

export default LoaderDots;
