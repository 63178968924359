import React, { useState, useEffect,useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput.component';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import ActionAlerts from '../../components/Alert/Alert.component';
import { useFormValidation } from '../../customHooks/formValidationHook';
import './Login.styles.scss';
import Bugsnag from '@bugsnag/js';
import PixieIcon from '../../images/pixie icon.png';
import SadImage from '../../images/SadImage.png';
import HappyImage from '../../images/right-happy-image.png';
import PixieText from '../../images/Pixie.svg';
import webflowIcon from '../../images/webflow-icon.svg';
import posthog from 'posthog-js'
import LoaderDots from '../../components/DotsLoader/Loader.component';
import SpeechBubble from '../../components/SpeechBubble/SpeechBubble.component';
import { debounce } from 'lodash';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

const clientId = process.env.REACT_APP_WEBFLOW_CLIENT_ID || "";
const url = `https://webflow.com/oauth/authorize?client_id=${clientId}&response_type=code&scope=authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20sites%3Aread`;
function Login({ isSignedIn, setSignedIn }) {
  const [loginDetails, setLoginDetails] = useState({ email: '', password: '' });
  const [message, setMessage] = useState({ msg: '', type: "" });
  const [errorMessage, setErrorMessage] = useState('');
  const [speechBubbleText, setSpeechBubbleText] = useState({ text: 'Kindly enter your Account details :)', type: 'statement' });
  const [userSignedIn, setUserSignedIn] = useState(false);
  const [btnText, setBtnText] = useState('Login');
  const { formErrors, validateForm, checkForFormErrors, btnDisabled, setBtnDisabled } = useFormValidation();
  const history = useHistory();
  const location = useLocation();
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const wrapperRef = useRef(null);
  const bubbleContentRef = useRef(null);
  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (isSignedIn) {
      setUserSignedIn(true)
      history.replace(from);
    }
  }, [from, history, isSignedIn, setUserSignedIn, userSignedIn])


  const handleChange = event => {
    const { value, name } = event.target;
    checkForFormErrors(name, value);
    setLoginDetails(prevDetails => {
      return {
        ...prevDetails,
        [name]: value,
      }
    })
    const filled = Object.values(loginDetails).every(val => val.trim() !== '');
    setAllFieldsFilled(filled);
  }

  const saveAuthTokenInSession = (token) => {
    window.localStorage.setItem('token', token);
  }
  const webflowLogin = async () => {
    window.location = url;
  };
  const onSubmitLogin = (event) => {
    event.preventDefault();
    if(validateForm(formErrors)) {
    const { email, password } = loginDetails;
    setBtnDisabled(true);
    setBtnText(<LoaderDots />);
    setSpeechBubbleText({text: "Kindly Wait..", type: 'statement'})
      fetch(`${process.env.REACT_APP_API_ENDPOINT}user/login`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email, password
        })
      })
        .then(response => response.json())
        .then(data => {
          setLoginDetails({
            email: '',
            password: '',
          })
          if (data.success === true && !data.errors) {
            posthog.capture('SignedIn',{
              $set: {email: email},
              email:email
            });
            setMessage({ msg: 'Login Successfull', type: "success" })
            saveAuthTokenInSession(data.token)
            setSignedIn(true)
            history.replace(from);
          } else {
            if (data?.message === 'Please Login With Webflow') {
              errHandle(data?.message)
            } else {
              setErrorMessage('Incorrect email or password.');
              errHandle();
            }
          }
        }).catch(err => {
          setErrorMessage('Error in logging you in! Please try again.');
          errHandle();
          Bugsnag.notify(err);
        })
    }
  }

  useEffect(() => {
    if (formErrors.email) {
        setSpeechBubbleText({ text: formErrors.email, type: 'error' });
    } else if (formErrors.password) {
        setSpeechBubbleText({ text: formErrors.password, type: 'error' });
    } else {
        setSpeechBubbleText({ text: 'Kindly enter your Account details :)', type: 'statement' });
    }
}, [formErrors]);

  const errHandle = (errorMsg = null) => {
    setMessage({ msg: errorMsg ? errorMsg : 'Incorrect email or password.', type: "error" })
    setBtnText('Login');
    setSpeechBubbleText({text: errorMsg ? errorMsg: 'Please enter valid Email Id & Password', type: 'error'});
  }

  return (
    <div className="signup-container">
      <div className='signup-container-left'>
        <div className="signup-form">
          <div className='logo'>
            <img className="icon" src={PixieIcon}/>
            <img src={PixieText}/>
          </div>
          <div className='heading-conatiner'>
            <h2 class="title">Welcome Back!</h2>
            <p>Enter your credentials to create your account.</p>
          </div>
          <div className='form-container'>
              <button className="webflow-button-container" onClick={webflowLogin}>
                <img className="webflow-icon" src={webflowIcon}/>
                Login with Webflow
              </button>
          <div className="or-text">or</div>
          <form onSubmit={onSubmitLogin} className="signup-fields">
            <div className='input-fields'>
              <label htmlFor="email" className='label-text'>Email*</label>
              <FormInput name="email" type="email" value={loginDetails.email} handleChange={handleChange} className={`box-input-signup ${formErrors.email || errorMessage ? 'error-box' : ''}`} required />
              {/* {formErrors.email && <span className='error'>{formErrors.email}</span>} */}
            </div>
            <div className='input-fields'>
              <label htmlFor="password" className='label-text'>Password*</label>
              <FormInput name="password" type="password" value={loginDetails.password} handleChange={handleChange} className={`box-input-signup ${formErrors.password || errorMessage ? 'error-box' : ''}`} required />
              {/* {formErrors.password && <span className='error'>{formErrors.password}</span>} */}
            </div>
            <div>
              <CustomButton type="submit" disabled={btnDisabled}>{btnText}</CustomButton>
            </div>
          </form>
          {/* {errorMessage && <span className="error-text">{errorMessage}</span>} */}
          <div className="links-container">
            <p className="non-user">
              Not a member? <Link className="link" to='/signup'>Sign Up</Link>
            </p>
            <p className="non-user-forgot-pass">
              <Link className="link" to='/reset-password'>Forgot Password?</Link>
            </p>
          </div>
        </div>
      </div>
      </div>
      <div className="signup-container-right">
        <div className='content-container'>
          {/* <div className="speech-bubble">
            Kindly enter your Account details :)
          </div> */}
          <div ref={wrapperRef} className="speech-bubble-wrapper">
            <SpeechBubble
              className={"speech-bubble"}
              fillColor={speechBubbleText.type === 'error' ? '#FFB8B8' : '#FFECBF'}
            />
              <div className='speech-bubble-content-wrapper'
                style={{ padding: speechBubbleText.text === "Kindly Wait.." && '3.5vh 3.906vw 8.5365vh 3.97135vw'}}>
                <p className='speech-bubble-content'>{speechBubbleText.text}</p>
              </div>
          </div>
          {
            speechBubbleText.type === 'error' ? 
            <img src={SadImage} className='right-image' alt="Right Image"></img>:
            <img src={HappyImage} className='right-image' alt="Right Image"></img>
          }
        </div>
      </div>
    </div>
  )
  
}



export default Login;