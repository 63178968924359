import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import rotatingImage from '../../images/uiw_loading.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    background: 'transparent',
  },
  rotatingImage: {
    background: 'transparent',
    // width: '50px',
    // height: '50px',
    backgroundImage: `url(${rotatingImage})`, 
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    animation: '$rotate 2s linear infinite',
  },
  // circularProgress: {
  //   position: 'relative',
  //   animation: 'rotate 2s linear infinite',
  // },
  // progressText: {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '60%',
  //   transform: 'translate(-50%, -50%)',
  //   zIndex: 1,
  //   fontSize: '0.65rem',
  // },
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
}));

export default function CircularProgressIndicator({ width = '50px', height = '50px' }) {
  const classes = useStyles();
  // const { value, ...otherProps } = props;
  return (
    <div className={classes.root}>
      {/* <CircularProgress className={classes.circularProgress} {...props}/>
      {value == null ? '' : (
      <div className={classes.progressText}>{`${(value)}%`}</div>)} */}
            <div         style={{
          width: width,
          height: height,
        }} className={classes.rotatingImage}></div>
    </div>
  );
}
