import React, { useEffect, useState } from "react";
import './straightForwardPricing.css';
import circleImg from '../../images/circle.svg';
import { getTokenFromLS } from "../../lib/helpers";
import { Link, useParams, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import setting from '../../images/setting.svg';
import TickIcon from '../../images/weui_done2-filled.svg';
import deleteIcon from '../../images/delete.svg';
import DeleteIcon from '../../images/material-symbols_delete (1).svg';
import refreshIcon from '../../images/refresh.svg';
import starterPlanIcon from '../../images/starter_plan_icon.svg';
import DeleteAccountDialog from "../../components/DialogBox/DialogBox.component";
import RenewImage from '../../images/Vector (24).svg';
import starterPlusPlanIcon from '../../images/starter_plus_plan_icon.svg';
import professionalPlanIcon from '../../images/professional_plan_icon.svg';
import enterprisePlanIcon from '../../images/enterprise_plan_icon.svg';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import DeleteBtnImage from '../../images/material-symbols_delete.svg';
import tick from '../../images/tick.svg';
import settingsIcon from '../../images/ic_baseline-settings.svg';
import Back from "../../images/Arrow back.svg"
import Swal from "sweetalert2";
import posthog from 'posthog-js';

const moment = require('moment');

const StraightForwardPricing = ({ user, currentSubscription, upcomingSubscription,creditsUsed,remainingCredits }) => {
    console.log(creditsUsed);
    const [monthlySelected, setMonthlySelected] = useState(true);
    const [allPlan, setAllPlan] = useState([]);
    const [loading, setLoading] = useState({ click: false, index: -1 });
    const [heightOfSFP, setHeight] = useState(window.innerHeight - 70);
    const [loadSetting, setLoadSetting] = useState('');
    const [subscription, setSubscription] = useState(currentSubscription);
    const [deleteUpcomingSubLoader, setDeleteUpcomingSubLoader] = useState('');
    const [showSurvey, setShowSurvey] = useState(false);
    const [surveyData, setSurveyData] = useState(null);
    const [surveyResponse, setSurveyResponse] = useState('');
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isRenewOpen, setRenewOpen] = useState(false);
    const [isDialogMessaggeOpen, setIsDialogMessageOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const history = useHistory();
    const [upcomingSub, setUpCommingSub] = useState({
        upcomingSubscription: {
            planId: null,
            planName: null,
            startDate: null
        }
    })
    const handleOpenDialog = () => setDialogOpen(true);
    const handleCloseDialog = () => setDialogOpen(false);
    const handleOpenRenewBox = () => setRenewOpen(true);
    const handlCloseRenewBox = () => setRenewOpen(false);
    const handleOpenMessageBox = () => setIsDialogMessageOpen(true);
    const handleCloseMessageBox = () => setIsDialogMessageOpen(false);

    const surveyId = process.env.REACT_APP_PUBLIC_POSTHOG_SURVEY_ID || "";
    const pricingPlanImages = [starterPlanIcon,starterPlusPlanIcon,professionalPlanIcon,enterprisePlanIcon];
    const detectHeight = () => {
        setHeight(window.innerHeight - 70)
    };
    // const goBackBtn = () => {    
    //     window.location.href = '/'
    //   };
    useEffect((() => {
        window.addEventListener('resize', detectHeight);
        setSubscription(currentSubscription);
        return () => {
            window.addEventListener('resize', detectHeight);
        }

    }), [heightOfSFP, currentSubscription])
    const goBackBtn = () => {

        window.location.href = '/pixie';
      };
    const fetchAllPlans = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}subscription/getAllPlans`);
            const data = await response.json();

            if (data?.success) {
                // data.plans.forEach((ele, index) => {
                //     if (ele.billing_period === 1) {
                //         setMonthlyPlan(monthlyPlan => [...monthlyPlan, ele]);
                //     } else if (ele.billing_period === 12) {
                //         setYearlyPlan(yearlyPlan => [...yearlyPlan, ele]);
                //     }
                // })
                setAllPlan(data.plans);
                if (upcomingSubscription?.planId) {
                    setUpCommingSub({
                        upcomingSubscription: {
                            planId: upcomingSubscription.planId,
                            planName: data.plans.find(ele => ele._id === upcomingSubscription.planId)?.name,
                            startDate: upcomingSubscription.startDate
                        }
                    })
                }
            }

            return 0;
        } catch (error) {
            console.log(error)
        }
    }

    useEffect((() => {
        fetchAllPlans();
        window.addEventListener('resize', detectHeight);
    }), []);

    const onClickBtn = (ele, index) => {
        if (!loading.click) {
            setLoading({ click: true, index: index });
            checkout(ele._id)
        }
    }

    const onClickBtnUpgradePlan = (ele, index) => {
        if (upcomingSub.upcomingSubscription?.planId === ele._id) { } else {
            if (!loading.click) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You want to upgrade this plan!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Upgrade it!',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => {},
                    preConfirm: (login) => {
                        setLoading({ click: true, index: index });
                        checkout(ele._id)
                    },
                })
            }
        }
    }

    const deleteScheduledSubscription = async () => {
        setDeleteUpcomingSubLoader('settingIcon-CP-img-click');
        try {
            setDeleteUpcomingSubLoader('settingIcon-CP-img-click');
            const token = window.localStorage.getItem("token");
            if (token) {
                const result = await fetch(`${process.env.REACT_APP_API_ENDPOINT}subscription/cancel-upcoming`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        token: token,
                    },
                });
                const res = await result.json();
                if (res.success) {
                    Swal.fire({
                        position: 'top-cneter',
                        icon: 'success',
                        title: 'Done',
                        text: 'Upcoming plan has been cancelled!',
                    });
                    setSubscription(prevValue => ({ ...prevValue, cancelsAt: res.data.cancelsAt, status: res.data.status }));
                    setUpCommingSub({
                        upcomingSubscription: {
                            planId: null
                        }
                    })
                } else {
                    Swal.fire({
                        position: 'top-cneter',
                        icon: 'error',
                        title: 'Oops...',
                        text: res?.message || 'Something went wrong!',
                    });
                }
                setDeleteUpcomingSubLoader('');
            }
        } catch (error) {
            console.log("Error while cancelling the upcoming plan =>", error);
            setDeleteUpcomingSubLoader('');
            Swal.fire({
                position: 'top-cneter',
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    }
    const onClickCancelScheduledPlan = (ele, index) => {
        if (!deleteUpcomingSubLoader) { 
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to cancel this Upcoming plan!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Cancel it!',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => {},
                preConfirm: () => {
                    deleteScheduledSubscription();
                },
            })
        }
    }


    const cancelCurrentSubscription = async () => {
        setDeleteUpcomingSubLoader('settingIcon-CP-img-click');
        try {
            setDeleteUpcomingSubLoader('settingIcon-CP-img-click');
            const token = window.localStorage.getItem("token");
            if (token) {
                const result = await fetch(`${process.env.REACT_APP_API_ENDPOINT}subscription/cancel-current-plan`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        token: token,
                    },
                });
                const res = await result.json();
                if (res.success) {
                    // Swal.fire({
                    //     position: 'top-cneter',
                    //     icon: 'success',
                    //     title: 'Done',
                    //     text: 'Your plan has been cancelled!',
                    // });
                    setDialogMessage('Your plan has been cancelled!');
                    setIsDialogMessageOpen(true);
                    setDialogOpen(false);
                    posthog.capture('PlanCancelled',{
                        $set: {planPrice: null,planActive: false},
                        planPrice:allPlan.find(ele => ele._id === res.data.planId)?.price.toString()
                      });
                      posthog.getActiveMatchingSurveys((surveys) => {
                        const targetSurvey = surveys.find(survey => survey.id === surveyId);
                        if (targetSurvey) {
                            setSurveyData(targetSurvey);
                            setShowSurvey(true);
                            posthog.capture("survey shown", {
                                $survey_id: targetSurvey.id
                            }) 
                        }
                    });
                    console.log('here is the cancelled Subscription ===>>', res.data);
                    setSubscription(prevValue => ({ ...prevValue, cancelsAt: res.data.cancelsAt, status: res.data.status }));
                    // fetchAllPlans();
                } else {
                    setDialogMessage(res?.message || 'Something went wrong!');
                    setIsDialogMessageOpen(true);
                    setDialogOpen(false);
                    // Swal.fire({
                    //     position: 'top-cneter',
                    //     icon: 'error',
                    //     title: 'Oops...',
                    //     text: res?.message || 'Something went wrong!',
                    // });
                }
                setDeleteUpcomingSubLoader('');
                setTimeout(() => {
                    setIsDialogMessageOpen(false);
                }, 2000);
            }
        } catch (error) {
            console.log("Error while cancelling the upcoming plan =>", error);
            setDeleteUpcomingSubLoader('');
            // Swal.fire({
            //     position: 'top-cneter',
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!',
            // })
            setDialogOpen(false);
            setDialogMessage('Something went wrong!');
            setIsDialogMessageOpen(true);
    
            setTimeout(() => {
                setIsDialogMessageOpen(false);
            }, 2000);
        }
    }
    const renewCurrentSubscription = async () => {
        setDeleteUpcomingSubLoader('settingIcon-CP-img-click');
        try {
            setDeleteUpcomingSubLoader('settingIcon-CP-img-click');
            const token = window.localStorage.getItem("token");
            if (token) {
                const result = await fetch(`${process.env.REACT_APP_API_ENDPOINT}subscription/renew-current-plan`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        token: token,
                    },
                });
                const res = await result.json();
                if (res.success) {
                    // Swal.fire({
                    //     position: 'top-cneter',
                    //     icon: 'success',
                    //     title: 'Done',
                    //     text: 'Your plan has been renewed!',
                    // });
                    setDialogMessage('Your plan has been renewed!');
                    setIsDialogMessageOpen(true);
                    setRenewOpen(false);
                    posthog.capture('PlanRenewal',{
                        $set: {planPrice: allPlan.find(ele => ele._id === res.data.planId)?.price,planActive: true},
                        planPrice:allPlan.find(ele => ele._id === res.data.planId)?.price.toString()
                    });
                    setSubscription(prevValue => ({ ...prevValue, cancelsAt: res.data.cancelsAt, status: res.data.status }));
                } else {
                    // Swal.fire({
                    //     position: 'top-cneter',
                    //     icon: 'error',
                    //     title: 'Oops...',
                    //     text: res?.message || 'Something went wrong!',
                    // });
                    setDialogMessage(res?.message || 'Something went wrong!');
                    setIsDialogMessageOpen(true);
                    setRenewOpen(false);
                }
                setDeleteUpcomingSubLoader('');
                setTimeout(() => {
                    setIsDialogMessageOpen(false);
                }, 2000);
            }
        } catch (error) {
            console.log("Error while renewing the upcoming plan =>", error);
            setDeleteUpcomingSubLoader('');
            // Swal.fire({
            //     position: 'top-cneter',
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!',
            // })
            setDialogMessage('Something went wrong!');
            setIsDialogMessageOpen(true);
            setRenewOpen(false);
            setTimeout(() => {
                setIsDialogMessageOpen(false);
            }, 2000);
        }
    }
    const onClickUpdateCurrentPlan = (ele, index) => {
        if (!deleteUpcomingSubLoader) {
            if (subscription.cancelsAt) {
                Swal.fire({
                    title: 'Are you sure you want to renew your plan?',
                    text: `This plan will no longer be canceled. It will renew on ${moment(subscription.endDate).format("DD MMM YYYY")}.`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes, Renew it!',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => { },
                    preConfirm: () => {
                        renewCurrentSubscription();
                    },
                });
            } else {
                Swal.fire({
                    title: 'Are you sure you want to cancel your plan?',
                    text: `Your plan will be canceled, but is still available until the end of your billing period on ${moment(subscription.endDate).format("DD MMM YYYY")}.`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes, Cancel it!',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => { },
                    preConfirm: () => {
                        cancelCurrentSubscription();
                    },
                });
            }
        }
    }
    const onClickSelectedPlan = async (index) => {
        try {
            setLoading({ click: true, index: index });
            const token = window.localStorage.getItem("token");
            if (token) {
                await fetch(`${process.env.REACT_APP_API_ENDPOINT}manage-payment-portal`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        token: token,
                    },
                }).then(async (result) => {
                    const res = await result.json()
                    if (res.url) {
                        window.location.href = res.url;
                        setTimeout(() => {
                            setLoading({ click: false, index: -1 })
                        }, 2000);


                    }
                }).catch(err => console.log("error: fetch API on selected plan =>", err))
            }
        } catch (error) {
            console.log("error on click selected plan =>", error)
        }
    }

    const onClickLoadSetting = async () => {
        try {
            if (!loadSetting) {
                setLoadSetting('settingIcon-CP-img-click');
                const token = window.localStorage.getItem("token");
                if (token) {
                    await fetch(`${process.env.REACT_APP_API_ENDPOINT}manage-payment-portal`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            token: token,
                        },
                    }).then(async (result) => {
                        const res = await result.json()
                        if (res.url) {
                            window.location.href = res.url;
                            setTimeout(() => {
                                setLoadSetting('');
                            }, 2000);


                        }
                    }).catch(err => console.log("error: fetch API on selected plan =>", err))
                }
            }
        } catch (error) {
            console.log("error on click selected plan =>", error)
        }
    }

    const checkout = async (planId) => {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}create-checkout-session`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'token': getTokenFromLS(),
            },
            body: JSON.stringify({ planId })
        })
        const data = await response.json();
        if (data.status) {

            if (data.status === "failed") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            } else {
                setLoading({ click: false, index: -1 });
                const planPrice = allPlan.find(ele => ele._id === planId)?.price
                window.fbq('track', 'Subscribe', {value: planPrice.toString(), currency: 'USD', predicted_ltv: '0.00'});
                posthog.capture('PlanUpgraded',{
                    $set: {planPrice: planPrice,planActive: true},
                    planPrice: planPrice.toString()
                  });
                setUpCommingSub({
                    upcomingSubscription: {
                        planId: planId,
                        planName: allPlan.find(ele => ele._id === planId)?.name,
                        startDate: moment(data.startAt).format("DD MMM YYYY")
                    }
                })
                Swal.fire({
                    position: 'top-cneter',
                    icon: 'success',
                    title: `New plan will start on ${moment(data.startAt).format("DD MMM YYYY")}`,
                    showConfirmButton: false,
                    timer: 5000
                })
                setSubscription(prevValue => ({ ...prevValue, cancelsAt: data.startAt })); 
            }
        }
        else {
            window.location.href = data.url;
            setTimeout(() => {
                setLoading({ click: false, index: -1 })
            }, 2000);
        }
    }

    const plansDesign = (data) => {
        let selectedOne = monthlySelected ? "month" : "year"
        const dataArray = data.filter((ele) => {
            if (selectedOne === "month") {
                if (ele.billing_period === 1) {
                    return ele;
                }
            } else if (selectedOne === "year") {
                if (ele.billing_period === 12) {
                    return ele;
                }
            }
            return false
        })
        console.log(data);
        const index = data.findIndex(ele => subscription.planId === ele._id);
        const subData = index !== -1 ? data[index] : undefined;

        let imageOptimizationsDone;
        let creditsGiven;
        console.log(remainingCredits)
        if(remainingCredits && remainingCredits.length > 0){
          const prvAllowedImages = remainingCredits.map((allowedImages) => allowedImages.creditsLeft);
          const sumOfPrvAllowedImages = prvAllowedImages.reduce((acc, curr) => acc + curr, 0);
          const prvUsedImages = remainingCredits.map((allowedImages) => allowedImages.usedCredits);
          const sumOfprvUsedImages = prvUsedImages.reduce((acc, curr) => acc + curr, 0);
          imageOptimizationsDone = creditsUsed + sumOfprvUsedImages;
          creditsGiven = subData?.images_allowed + sumOfPrvAllowedImages;
        }else{
          imageOptimizationsDone = creditsUsed;
          creditsGiven = subData?.images_allowed
        }

        const creditsRemaning  = creditsGiven - imageOptimizationsDone;
        let creditsLeft;
        if(creditsRemaning < 0){
            creditsLeft = 0;
        }else{
            creditsLeft = creditsRemaning;
        }
        console.log(subData);
        return (
            <div className="main-content" >
                {
                    subscription?.isSubscribed ? (
                        <div className="current-plan">
                            <div className="current-plan-left">
                                <div className="plan-details-left-box">
                                    <div className="active-tag">Active</div>
                                    <div className="box-details">
                                        <div className="box-upper-wrapper">
                                            <div className="plan-heading-container-top">
                                                <img src={pricingPlanImages[index]} className="plan-image"></img>
                                                <div className="plan-name">{subscription.planName}</div>
                                                <div className="plan-specification" style={{width: '21.22vw'}}>{subData?.heading}</div>
                                            </div>
                                            <div className="plan-settings" onClick={onClickLoadSetting}><img className="plan-settings-img" src={settingsIcon}></img></div>
                                        </div>
                                        <div className="plan-payment" style={{alignItems: 'start'}}>
                                            <div className="plan-amount">
                                                <span className="plan-dollar">$</span><span className="price-text">{subData?.price}<span className="per-text">/{subData?.billing_period === 1 ? 'mon' : 'year'}</span></span>
                                            </div>
                                            <div className="plan-amt-detail">
                                                <div className="plan-upperPT">Optimize {subData?.images_allowed} credits</div>
                                            </div>
                                        </div>
                                    </div>
                                    <CustomButton onClick={subscription.cancelsAt? handleOpenRenewBox : handleOpenDialog} type='submit' on className="plan-delete-btn"><img className="delete-btn-img" src={subscription.cancelsAt? RenewImage: DeleteBtnImage}></img>{subscription.cancelsAt? "Renew Plan" :"Delete Plan"}</CustomButton>
                                </div>
                            </div>
                            {/* <div className="current-plan-name"><b>Status  - {subscription.status === "active" ? (<span className="active-green">{subscription.status}</span>) : <span className="active-red">{(subscription.status || '').replace(/_/g, ' ')}</span>} </b></div> */}
                            <div className="current-plan-right">
                                <div className="credits-left">CREDITS LEFT: {creditsLeft}</div>
                                <div className="details-box">
                                    <div className="plan-date-details"><div className="plan-date-heading">START: </div>&nbsp;{moment(subscription.startDate).format("DD MMM YYYY")} </div>
                                    <div className="plan-date-details"><div className="plan-date-heading">END:</div>&nbsp;{moment(subscription.endDate).format("DD MMM YYYY")}</div>
                                    {subscription.cancelsAt ? (<div className="plan-date-details"><div className="plan-date-heading">Cancels At:</div> {moment(subscription.cancelsAt).format("DD MMM YYYY")}</div>) : ''}
                                </div>
                                <div className="plan-detail" style={{paddingTop: '4.74vh'}}>
                                            <div className="detail-header">What’s included?</div>
                                            <div className="detail-content" style={{color: '#000000'}}>
                                                <div className="detailed-content" style={{fontWeight: 700}}><img src={tick} className="tick-img" alt="load" />
                                                    Optimise up to {subData?.images_allowed} images
                                                </div>
                                                <div className="detailed-content"><img src={tick} className="tick-img" alt="load" />
                                                    Unlimited Sites
                                                </div>
                                                <div className="detailed-content"><img src={tick} className="tick-img" alt="load" />
                                                    Upto 80% compression
                                                </div>
                                                <div className="detailed-content"><img src={tick} className="tick-img" alt="load" />
                                                    Webflow CMS Images
                                                </div>
                                                <div className="detailed-content"><img src={tick} className="tick-img" alt="load" />
                                                    Customer Support
                                                </div>
                                                <div className="detailed-content"><img src={tick} className="tick-img" alt="load" />
                                                    Overage: {Math.round(subData?.usage_price * 100)} cents per image
                                                </div>
                                            </div>
                                        </div>
                            </div>
                            {/* <div className="settingIcon-CP" title="Manage Current Plan" onClick={onClickLoadSetting}><img className={`settingIcon-CP-img ${loadSetting}`} src={setting} alt="load" /></div>
                            {upcomingSub.upcomingSubscription.planId ? '' : (<div className="settingIcon-CP right5" title={`${subscription.cancelsAt ? "Renew" : "Cancel"} Current Plan`} onClick={onClickUpdateCurrentPlan}><img className={`settingIcon-CP-img right55 ${deleteUpcomingSubLoader}`} src={subscription.cancelsAt ? refreshIcon : deleteIcon} alt="icon" /></div>)} */}
                        </div>
                    ) : null
                }

                {
                    upcomingSub.upcomingSubscription.planId ? (
                        <div className="current-plan">
                            <div className="recommend-CP">UPCOMING PLAN</div>
                            <div className="current-plan-name"><b>Upcoming Subscription Plan:</b> {upcomingSub.upcomingSubscription.planName} </div>
                            <div className="current-plan-name"> <b> Starts At:</b> {moment(upcomingSub.upcomingSubscription.startDate).format("DD MMM YYYY")} </div>
                            {/* <div className="settingIcon-CP right6" title="Cancel Upcoming Plan" onClick={onClickCancelScheduledPlan}><img className={`settingIcon-CP-img right55 ${deleteUpcomingSubLoader}`} src={deleteIcon} alt="delete" /></div> */}
                        </div>
                    ) : null
                }

                <div className="all-plan">
                    {
                        dataArray.map((ele, index) => {
                            if (subscription?.isSubscribed && subscription.planId === ele._id) {
                                return null;
                            }
                            return (
                                <div className="plans-details-container">
                                    {
                                        ele?.recommended && <div className="recommend">Recommended</div>
                                    }
                                    <div key={uuidv4()} className="main-plan-div">

                                        {/* contain name of the plan */}
                                        <div className="plan-heading-container">
                                            <img src={pricingPlanImages[index]} className="plan-image"></img>
                                            <div className="plan-name">{ele.name}</div>
                                            <div className="plan-specification">{ele?.heading}</div>
                                        </div>

                                        {/* contain plan amount details */}
                                        <div className="plan-payment">
                                            <div className="plan-amount">
                                                <span className="plan-dollar">$</span><span className="price-text">{ele?.price}<span className="per-text">/{monthlySelected ? 'mon' : 'year'}</span></span>
                                            </div>
                                            <div className="plan-amt-detail">
                                                <div className="plan-upperPT">Optimize {ele?.images_allowed} credits</div>
                                            </div>
                                        </div>

                                        {/* GET PLAN button */}
                                        <div className={loading.index === index ? "get-plan-button-click" : "get-plan-button"} onClick={() => onClickBtn(ele, index)} >
                                                    {
                                                        loading.index === index ? (
                                                            <div className="loader"></div>
                                                        ) : ('Get this Plan')
                                                    }
                                        </div>
                                        {/* {subscription?.isSubscribed ?
                                            (
                                                subscription.planId === ele._id ? (
                                                    <div className={loading.index === index ? 'get-plan-button-sub-not-click' : 'get-plan-button-sub-not'} onClick={() => onClickSelectedPlan(index)}>
                                                        {
                                                            loading.index === index ? (
                                                                <div className="loader"></div>
                                                            ) : (
                                                                <div className="currentPlanBtn">
                                                                    <div className="currentPlan">SELECTED PLAN</div>
                                                                    <img className="settingIcon" src={setting} alt="load" />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className={loading.index === index ? 'get-plan-button-sub-click' : 'get-plan-button-sub'}
                                                        onClick={() => onClickBtnUpgradePlan(ele, index)}
                                                        style={{
                                                            background: upcomingSub.upcomingSubscription?.planId === ele._id ? "rgba(255, 216, 3, 1)" : "white"
                                                        }}  >
                                                        {
                                                            loading.index === index ? (
                                                                <div className="loader"></div>
                                                            ) :
                                                                upcomingSub.upcomingSubscription.planId === ele._id ?
                                                                    ('UPCOMING PLAN') : ('UPGRADE PLAN')
                                                        }
                                                    </div>
                                                )

                                            ) : (
                                            ) */}
                                        {/* } */}

                                        {/* contain total details about this plan package */}
                                        <div className="plan-detail">
                                            <div className="detail-header">What’s included?</div>
                                            <div className="detail-content">
                                                <div className="detailed-content" style={{fontWeight: 700}}><img src={tick} className="tick-img" alt="load" />
                                                    Optimise up to {ele?.images_allowed} images
                                                </div>
                                                <div className="detailed-content"><img src={tick} className="tick-img" alt="load" />
                                                    Unlimited Sites
                                                </div>
                                                <div className="detailed-content"><img src={tick} className="tick-img" alt="load" />
                                                    Upto 80% compression
                                                </div>
                                                <div className="detailed-content"><img src={tick} className="tick-img" alt="load" />
                                                    Webflow CMS Images
                                                </div>
                                                <div className="detailed-content"><img src={tick} className="tick-img" alt="load" />
                                                    Customer Support
                                                </div>
                                                <div className="detailed-content"><img src={tick} className="tick-img" alt="load" />
                                                    Overage: {Math.round(ele.usage_price * 100)} cents per image
                                                </div>
                                            </div>
                                        </div>

                                        </div>
                                </div>
                                
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="main-block-pricing">
            {/* <div className="for-yellowish-BG" style={{
                height: '88%',
            }}>         */}
            {/* <Link to="#" className="logo" onClick={() => window.history.back()}> */}
                {/* <button className="back-button" onClick={goBackBtn} style={{marginLeft:"11vw" ,marginTop:"5vh"}} ><img src={Back}></img></button> */}
            {/* </Link> */}
                <div className="main-div">
                    {/* header part */}
                    <div className="header-container">
                        <div className="main-header">
                            Straightforward pricing
                        </div>
                        <div className="btn-wrapper">
                            <div className="btn-div-design">
                                <div className={monthlySelected ? "btn-div-design-select" : "btn-div-design-deselect"} onClick={() => setMonthlySelected(true)}>Monthly</div>
                                <div className={monthlySelected ? "btn-div-design-deselect" : "btn-div-design-select"} onClick={() => setMonthlySelected(false)}>Annually</div>
                            </div>
                        </div>
                    </div>

                    {/* contain semi header and button of monthly and anually changed */}

                    {/* now the all the plans come here */}
                    <div className="plans-div">
                        {
                            plansDesign(allPlan)
                        }
                    </div>

                {/* </div> */}
            </div>
            {showSurvey && surveyData && (
                <div className="survey-overlay">
                    <div className="survey-modal">
                        <div className="survey-header">
                            {surveyData.questions[0].question}
                            <span className="survey-close" onClick={() => {
                                posthog.capture("survey dismissed", {
                                    $survey_id: surveyData.id
                                });
                                setShowSurvey(false);
                            }}>×</span>
                        </div>
                        <div className="survey-body">
                            <form>
                                {surveyData.questions[0].choices.map((choice, index) => (
                                    <label key={index} className="survey-option">
                                        <input
                                            type="radio"
                                            name="reason"
                                            value={choice}
                                            checked={surveyResponse === choice}
                                            onChange={(e) => setSurveyResponse(e.target.value)}
                                        />
                                        {choice}
                                    </label>
                                ))}
                            </form>
                        </div>
                        <div className="survey-footer">
                            <button className="survey-submit" onClick={() => {
                                posthog.capture("survey sent", {
                                    $survey_id: surveyData.id,
                                    $survey_response: surveyResponse
                                });
                                setShowSurvey(false);
                            }}>Submit</button>
                        </div>
                    </div>
                </div>
            )}
            <DeleteAccountDialog isOpen={isDialogOpen} onClose={handleCloseDialog} clickPrimaryButton={cancelCurrentSubscription} dialogBoxText={"Are you sure you want to cancel your plan?"} buttonVisible={true} textPrimaryButton={"Yes, Cancle it!"} textSecondaryButton={"No"} dialogboxIcon={DeleteIcon}/>
            <DeleteAccountDialog isOpen={isRenewOpen} onClose={handlCloseRenewBox} clickPrimaryButton={renewCurrentSubscription} dialogBoxText={"Are you sure you want to renew your plan?"} buttonVisible={true} textPrimaryButton={"Yes, Renew it!"} textSecondaryButton={"No"} dialogboxIcon={RenewImage}/>
            <DeleteAccountDialog isOpen={isDialogMessaggeOpen} onClose={handleCloseMessageBox} dialogBoxText={dialogMessage} buttonVisible={false} dialogboxIcon={TickIcon}/>
        </div>
    );
}


export default StraightForwardPricing;
