import React, { useEffect, useState, useRef } from 'react';
import { getTokenFromLS, parseJSON } from "../../lib/helpers";
import { useParams,useHistory } from 'react-router-dom';
import './LogsDetails.styles.scss';
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs.component";
import { Breadcrumb } from '@bugsnag/js';
import Back from "../../images/Arrow back.svg"
import { constSelector } from 'recoil';
import curvedVector from '../../images/curved-vector.svg';

const UserLogsDetailsPage = () => {
  const [userLogsDetails, setUserLogsDetails] = useState([]);
  let { slugId, index } = useParams();
  const history = useHistory();
  const goBackBtn = () => {
    history.goBack();
  };

  const detailsLineWrapperRef = useRef(null);
  const detailsLineRef = useRef(null);
  const pointerWrapperRefs = useRef([]);

  const skippedLineWrapperRef = useRef(null);
  const skippedLineRef = useRef(null);
  const skippedPointerWrapperRefs = useRef([]);

  useEffect(() => {
    fetchLogs();
  }, []);
  function handleImageError(imgElement,skip) {
    var textElement = document.createElement('p');
    textElement.textContent = "N/A";
    textElement.classList.add('data-text-details');
    if(skip){
      textElement.classList.add('adding-margin-skip');
    }else{
      textElement.classList.add('adding-margin');
    }
    imgElement.replaceWith(textElement);
  }
  const fetchLogs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-user-logs`, {
        headers: {
          'Content-Type': 'application/json',
          token: getTokenFromLS(),
        },
      });
      const data = await response.json();
      setUserLogsDetails(data);
    } catch (error) {
      console.error('Error fetching user logs:', error);
    }
  };

  const updateHeights = () => {
    const adjustLineHeight = (wrapperRef, lineRef, pointerRefs) => {
      if (wrapperRef.current && pointerRefs.current.length > 0) {
        const wrapperHeight = wrapperRef.current.offsetHeight;
        const lastPointerHeight = pointerRefs.current[pointerRefs.current.length - 1]?.offsetHeight || 0;
        const newLineHeight = wrapperHeight - (lastPointerHeight / 2) - 5;

        if (lineRef.current) {
          lineRef.current.style.height = `${newLineHeight}px`;
        }
      }
    };

    adjustLineHeight(detailsLineWrapperRef, detailsLineRef, pointerWrapperRefs);
    adjustLineHeight(skippedLineWrapperRef, skippedLineRef, skippedPointerWrapperRefs);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateHeights);

    if (detailsLineWrapperRef.current) {
      resizeObserver.observe(detailsLineWrapperRef.current);
    }

    if (skippedLineWrapperRef.current) {
      resizeObserver.observe(skippedLineWrapperRef.current);
    }

    updateHeights();

    return () => {
      if (detailsLineWrapperRef.current) {
        resizeObserver.unobserve(detailsLineWrapperRef.current);
      }
      if (skippedLineWrapperRef.current) {
        resizeObserver.unobserve(skippedLineWrapperRef.current);
      }
    };
  }, [userLogsDetails]);

  const log = userLogsDetails && userLogsDetails.find((log) => log.jobId === slugId);
  let imagesData
  let skippedData
  if(index === '00'){
    imagesData = log && log.meta.compressedImageURL;
    skippedData = log && log.meta.skippedImages;
  }else{
    imagesData = log && log.meta[index].compressedImageURL;
    skippedData = log && log.meta[index].skippedImages;
  }
  console.log(imagesData)
  const checkSkippedItem = skippedData && skippedData.length !== 0 && skippedData[0].itemName;
  const logMeta = log && log.meta;
  const slugName = logMeta && (index === '00' ? logMeta.slugName : logMeta[index].slugName);


  const covertToKB = (size) =>{
    return (size/1024).toFixed(2)+'KB'
  }
  const covertToMB = (size) =>{
    return (size / 1024).toFixed(2) + 'MB';
  }
  const extractImageUrl = (imageUrl) => {
    const lastSlashscoreIndex = imageUrl.lastIndexOf('/');
    return imageUrl.substring(lastSlashscoreIndex + 1);
  };
  return (
    <div className='main-box'>
      {/* <div className='breadcrumbs'>
      <Breadcrumbs
        customNames={{
          [slugId]: "details"
        }}
      />
      </div> */}
      <div className='heading-field'>
        {slugName || 'N/A'}
      </div>
    <div className="collection-details-wrapper">
      <div className='collection-box'>
        <h2 className="collection-name">Optimized Images</h2>
        <div className='details-line-wrapper' ref={detailsLineWrapperRef}>
        <div className='details-line' ref={detailsLineRef}></div>
        <div className='collection-box-wrapper margin-top-1vh'>
        {imagesData && imagesData.map((log, logIndex) => (
        <div className='pointer-wrapper' ref={(el) => pointerWrapperRefs.current[logIndex] = el}>
            <img className='details-curved-vector' src={curvedVector}></img>
        <div className='collection-box-image-wrapper'>
          <img  src={log.imageUrl} alt="Image" className="small-image" onError={(e) => handleImageError(e.target,false)}/>
          <div className='collection-box-details-wrapper'>
                <>
                  <div className='text-box'>
                  <p className='text-header'>Image Name:</p>
                  <p className='data-text-details'>{extractImageUrl(log.imageUrl)}</p>
                  </div>
                  {imagesData && imagesData.length != 0 && imagesData[0].itemName && log.itemName && log.itemName.trim() !== '' && (
                    <div className='text-box '>
                      <p className='text-header'>Item Name:</p>
                      <p className='data-text-details'>{log.itemName}</p>
                    </div>
                  )}
                  <div className='text-box'>
                  <p className='text-header'>Original Size:</p>
                  <p className='data-text-details'>{covertToKB(log.originalSize)>10000?covertToMB((log.originalSize/1024)):covertToKB(log.originalSize)}</p>
                  </div>
                  <div className='text-box'>
                  <p className='text-header'>Optimized Size:</p>
                  <p className='data-text-details'>{covertToKB(log.optimizedSize)>10000?covertToMB((log.optimizedSize/1024)):covertToKB(log.optimizedSize)}</p>
                  </div>
                  <div className='text-box'>
                  <p className='text-header'>Saved Size:</p>
                  <p className='data-text-details'>{covertToKB(log.savedBytes)>10000?covertToMB((log.savedBytes/1024)):covertToKB(log.savedBytes)}</p>
                  </div>
                  {/* <div className="data-group" style={{marginLeft:imagesData && imagesData.length !== 0 && imagesData[0].itemName ? '8.8vw':'6.8vw',width:'6.51vw',height:'13.38vh'}}>
                  <img  src={log.imageUrl} style={{height:'auto',width:'100%'}} alt="Image" className="small-image" onError={(e) => handleImageError(e.target,false)}/>
                  </div> */}
                </>
                {/* {logIndex !== imagesData.length - 1 && <div className="divider-line"></div>} */}
        </div>
      </div>
      </div>
      ))}
      </div>
      </div>
      </div>
      <div className='collection-box'>
        <h2 className="collection-name">Skipped Images</h2>
        <div className='details-line-wrapper' ref={skippedLineWrapperRef}>
        <div className='details-line'  ref={skippedLineRef}></div>
        <div className='collection-box-wrapper'>
        {skippedData && skippedData.map((log, logIndex) => (
        <div className='pointer-wrapper' ref={(el) => skippedPointerWrapperRefs.current[logIndex] = el}>
            <img className='details-curved-vector' src={curvedVector}></img>
        <div className='skip-tag-wrapper margin-top-1vh'>
          <div className='skip-tag'>
            <p className='skip-text'>Reason Skipped:</p>
            <p className='skip-text'>{log.skipReason.match(/^file type (.+) is not supported.$/)
                      ? `Image type not supported.`
                      : log.skipReason === 'Size increased after compression'
                      ? 'Already compressed'
                      : log.skipReason} 
            </p>
          </div>
        <div className='collection-box-image-wrapper'>
          <img  src={log.imageUrl} alt="Image" className="small-image" onError={(e) => handleImageError(e.target,false)}/>
          <div className='collection-box-details-wrapper'>
                <>
                  <div className='text-box'>
                  <p className='text-header'>Image Name:</p>
                  <p className='data-text-details'>{extractImageUrl(log.imageUrl)}</p>
                  </div>
                  {skippedData && skippedData.length != 0 && skippedData[0].itemName && log.itemName && log.itemName.trim() !== '' && (
                    <div className='text-box '>
                      <p className='text-header'>Item Name:</p>
                      <p className='data-text-details'>{log.itemName}</p>
                    </div>
                  )}
                  <div className='text-box'>
                  <p className='text-header'>Original Size:</p>
                  <p className='data-text-details'>{covertToKB(log.originalSize)>10000?covertToMB((log.originalSize/1024)):covertToKB(log.originalSize)}</p>
                  </div>
                  <div className='text-box'>
                  <p className='text-header'>Optimized Size:</p>
                  <p className='data-text-details'>{covertToKB(log.optimizedSize)>10000?covertToMB((log.optimizedSize/1024)):covertToKB(log.optimizedSize)}</p>
                  </div>
                  <div className='text-box'>
                  <p className='text-header'>Saved Size:</p>
                  <p className='data-text-details'>{covertToKB(log.savedBytes)>10000?covertToMB((log.savedBytes/1024)):covertToKB(log.savedBytes)}</p>
                  </div>
                  {/* <div className="data-group" style={{marginLeft:imagesData && imagesData.length !== 0 && imagesData[0].itemName ? '8.8vw':'6.8vw',width:'6.51vw',height:'13.38vh'}}>
                  <img  src={log.imageUrl} style={{height:'auto',width:'100%'}} alt="Image" className="small-image" onError={(e) => handleImageError(e.target,false)}/>
                  </div> */}
                </>
                {/* {logIndex !== imagesData.length - 1 && <div className="divider-line"></div>} */}
        </div>
      </div>
      </div>
      </div>
      ))}
      </div>
      </div>
      </div>
  </div>
  </div>
  );
};

export default UserLogsDetailsPage;

