import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ActionAlerts from '../../components/Alert/Alert.component';
import FormInput from '../../components/FormInput/FormInput.component';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import { useFormValidation } from '../../customHooks/formValidationHook';
import PixieIcon from '../../images/pixie icon.png';
import HappyImage from '../../images/right-happy-image.png';
import SadImage from '../../images/SadImage.png';
import PixieText from '../../images/Pixie.svg';
import webflowIcon from '../../images/webflow-icon.svg';
import SpeechBubble from '../../components/SpeechBubble/SpeechBubble.component';
import { debounce } from 'lodash';
import posthog from 'posthog-js';
import LoaderDots from '../../components/DotsLoader/Loader.component';
import Bugsnag from '@bugsnag/js';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import './SignUp.styles.scss';

const clientId = process.env.REACT_APP_WEBFLOW_CLIENT_ID || "";
const url = `https://webflow.com/oauth/authorize?client_id=${clientId}&response_type=code&scope=authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20sites%3Aread`;

function SignUp({ isSignedIn, setSignedIn }) {
    const [signUpDetails, setSignUpDetails] = useState({
        name: '',
        email: '',
        password: '',
        // confirmPassword: '',
    });
    const [message, setMessage] = useState({ msg: '', type: "" });
    const [errorMessage, setErrorMessage] = useState('');
    const [userSignedIn, setUserSignedIn] = useState(false);
    const [btnText, setBtnText] = useState('Sign Up');
    const [speechBubbleText, setSpeechBubbleText] = useState({ text: 'Enter your details and create an Account with us !', type: 'statement' });
    const { formErrors, validateForm, checkForFormErrors, btnDisabled, setBtnDisabled } = useFormValidation();
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const [wrapperHeight, setWrapperHeight] = useState('auto');
    const [wrapperWidth, setWrapperWidth] = useState('auto');
    const wrapperRef = useRef(null);
    const bubbleContentRef = useRef(null);
    const { from } = location.state || { from: { pathname: "/" } };

    useEffect(() => {
        if (isSignedIn) {
            setUserSignedIn(true);
            history.replace(from);
        }
    }, [from, history, isSignedIn, userSignedIn]);

    const webflowLogin = async () => {
        window.location = url;
    };

    useEffect(() => {
        const { password, confirmPassword } = signUpDetails;
        if (!password.length) {
            setBtnDisabled(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpDetails]);

    const saveAuthTokenInSession = (token) => {
        window.localStorage.setItem('token', token);
    };

    const onSubmitSignUp = (event) => {
        event.preventDefault();
        const { name, email, password, confirmPassword } = signUpDetails;

        if (validateForm(formErrors)) {
            setBtnDisabled(true);
            setBtnText(<LoaderDots />);
            setSpeechBubbleText({ text: "Kindly Wait..", type: "statement" });
            fetch(`${process.env.REACT_APP_API_ENDPOINT}user/signup`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    username: name
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true && !data.errors) {
                        setMessage('Successfully Created Account');
                        window.fbq('track', 'StartTrial', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
                        posthog.capture('SignedUp', {
                            $set: { email: email },
                            email: email
                        });
                        saveAuthTokenInSession(data.token);
                        setSignedIn(true);
                        history.replace(from);
                        setSignUpDetails({
                            name: '',
                            email: '',
                            password: '',
                            // confirmPassword: '',
                        });
                    } else {
                        errHandle(data?.msg || 'Error in Signing you in! Please try again');
                        setErrorMessage('Error in Signing you in! Please try again');
                    }
                }).catch(err => {
                    errHandle('Error in Signing you in! Please try again');
                    setErrorMessage('Error in Signing you in! Please try again');
                    Bugsnag.notify(err);
                });
        } else {
            setBtnDisabled(true);
        }
    };

    const errHandle = (errorMsg) => {
        setMessage({ msg: errorMsg, type: "error" });
        setBtnText('Signup');
        setSpeechBubbleText({ text: errorMsg, type: 'error' });
    };

    useEffect(() => {
        if (formErrors.userName) {
            setSpeechBubbleText({ text: formErrors.userName, type: 'error' });
        } else if (formErrors.email) {
            setSpeechBubbleText({ text: formErrors.email, type: 'error' });
        } else if (formErrors.password) {
            setSpeechBubbleText({ text: formErrors.password, type: 'error' });
        } else {
            setSpeechBubbleText({ text: 'Enter your details and create an Account with us!', type: 'statement' });
        }
    }, [formErrors]);

    const handleChange = event => {
        const { name, value } = event.target;
        checkForFormErrors(name, value);
        setSignUpDetails(prevDetails => {
            return {
                ...prevDetails,
                [name]: value,
            };
        });
        const confirmEmpty = signUpDetails.password.trim() === '';
        const filled = Object.values(signUpDetails).every(val => val.trim() !== '');
        const isValid = !confirmEmpty && filled;
        setAllFieldsFilled(isValid);
    };

    const { name, email, password, confirmPassword } = signUpDetails;
    return (
        <div className="signup-container">
            <div className='signup-container-left'>
                <div className="signup-form">
                    <div className='logo'>
                        <img className="icon" src={PixieIcon} />
                        <img src={PixieText} />
                    </div>
                    <div className='heading-conatiner'>
                        <h2 className="title">Hello!</h2>
                        <p className='signup-subtext'>Enter your credentials to create your account.</p>
                    </div>
                    {
                        message.msg ?
                            <ActionAlerts text={message.msg} type={message.type} /> :
                            null
                    }
                    <div className='form-container'>
                        <button className="webflow-button-container" onClick={webflowLogin}>
                            <img className="webflow-icon" src={webflowIcon} />
                            Signup with Webflow
                        </button>
                        <div className="or-text">or</div>
                        <form className='signup-fields' onSubmit={onSubmitSignUp}>
                            <div className='input-fields'>
                                <label htmlFor="name" className='label-text'>Name*</label>
                                <FormInput type='text' name='name' value={name} onChange={handleChange}
                                    className={`box-input-signup ${formErrors.userName || errorMessage ? 'error-box' : ''}`} required />
                            </div>
                            <div className='input-fields'>
                                <label htmlFor="email" className='label-text'>Email*</label>
                                <FormInput type='email' name='email' value={email} onChange={handleChange} className={`box-input-signup ${formErrors.email || errorMessage ? 'error-box' : ''}`} required />
                            </div>
                            <div className='input-fields'>
                                <label htmlFor="password" className='label-text'>Password*</label>
                                <FormInput type='password' name='password' value={password} onChange={handleChange} className={`box-input-signup ${formErrors.password || errorMessage ? 'error-box' : ''}`} required />
                            </div>
                            <div>
                                <CustomButton type='submit' disabled={btnDisabled}>{btnText}</CustomButton>
                            </div>
                        </form>
                        <div className="links-container">
                            <div className="non-user">
                                Already have an account? <Link className="link" to='/login'>Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="signup-container-right">
                <div className='content-container'>
                    <div className="speech-bubble-wrapper" >
                        <SpeechBubble
                            className={"speech-bubble"}
                            fillColor={speechBubbleText.type === 'error' ? '#FFB8B8' : '#FFECBF'}
                        />
                        <div className='speech-bubble-content-wrapper'
                            style={{ padding: speechBubbleText.text === "Kindly Wait.." && '3.5vh 3.906vw 8.5365vh 3.97135vw' }}>
                            <p className='speech-bubble-content'>{speechBubbleText.text}</p>
                        </div>
                    </div>
                    {
                        speechBubbleText.type === 'error' ? 
                        <img src={SadImage} className='right-image' alt="Right Image"></img>:
                        <img src={HappyImage} className='right-image' alt="Right Image"></img>
                    }
                </div>
            </div>
        </div>
    );
}

export default SignUp;
