import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";
import { Link, useParams, useHistory } from "react-router-dom";
import ActionAlerts from "../../components/Alert/Alert.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import FormInput from "../../components/FormInput/FormInput.component";
import PixieIcon from '../../images/pixie icon.png';
import HappyImage from '../../images/right-happy-image.png';
import SadImage from '../../images/SadImage.png';
import PixieText from '../../images/Pixie.svg';
import { useFormValidation } from "../../customHooks/formValidationHook";
import SpeechBubble from '../../components/SpeechBubble/SpeechBubble.component';
import LoaderDots from '../../components/DotsLoader/Loader.component';
import AlertMessage from "../../components/AlertMessage/AlertMessage.component";
import "./VerifyResetPassword.styles.scss";

const VerifyResetPassword = ({ isSignedIn }) => {
  const [userDetails, setUserDetails] = useState({
    password: '',
    confirmPassword: '',
  });
  const [isPasswordMismatch, setIsPasswordMismatch] = useState(false);
  const [titleText, setTitleText] = useState("Please Wait while we verify your token...");
  const [btnText, setBtnText] = useState("Submit");
  const [message, setMessage] = useState({ msg: "", type: "" });
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [speechBubbleText, setSpeechBubbleText] = useState({ text: 'Enter your new password!', type: 'statement' });
  let { token } = useParams();
  const history = useHistory();
  const {
    formErrors,
    validateForm,
    checkForFormErrors,
    btnDisabled,
    setBtnDisabled,
  } = useFormValidation();

  const { password, confirmPassword } = userDetails;

  useEffect(() => {
    if (isSignedIn) {
      localStorage.clear();
    } else if (token) {
      verifyToken(token);
    }
  }, [isSignedIn, token]);

  const verifyToken = (uniKey) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}user/reset/${uniKey}`)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setShowForm(true);
          setTitleText("Enter Your Details Below");
        } else {
          setMessage({ msg: data.message, type: "error" });
          setSpeechBubbleText({ text: "Reset Token is Invalid, please try again.", type: 'error' });
          setTitleText("Reset Token is Invalid, Please try to reset again");
        }
      })
      .catch((err) => {
        Bugsnag.notify(err);
      });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    checkForFormErrors(name, value);
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    console.log(name);
    if (name === 'password' || name === 'confirmPassword') {
      console.log(userDetails);
      if (name === 'confirmPassword' && userDetails.password !== value) {
        console.log('enter 1');
        setSpeechBubbleText({ text: "Passwords do not match.", type: "error" });
        setIsPasswordMismatch(true);
      } else if (name === 'password' && userDetails.confirmPassword && value !== userDetails.confirmPassword) {
        console.log('enter 2');
        setSpeechBubbleText({ text: "Passwords do not match.", type: "error" });
        setIsPasswordMismatch(true);
      } else {
        setSpeechBubbleText({ text: "Enter your new password!", type: "statement" });
        setIsPasswordMismatch(false);
      }
    }
    console.log(btnDisabled)
  };
  useEffect(() => {
    if (formErrors.password) {
      setSpeechBubbleText({ text: formErrors.password, type: 'error' });
    }
  }, [formErrors]);

  const onSubmit = (event) => {
    event.preventDefault();
    if (validateForm(formErrors) && !isPasswordMismatch) {
      setBtnDisabled(true);
      setBtnText(<LoaderDots />);
      setSpeechBubbleText({ text: "Processing your request...", type: "statement" });
      fetch(`${process.env.REACT_APP_API_ENDPOINT}user/reset/${token}`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setUserDetails({
            password: '',
            confirmPassword: ''
          });
          if (data.success) {
            setBtnText("Done");
            setShowForm(false);
            setMessage({ msg: 'Password changed successfully, please login', type: "success" });
            setSpeechBubbleText({ text: "Password changed successfully!", type: 'statement' });
          } else {
            setMessage({ msg: data.message, type: "error" });
            setSpeechBubbleText({ text: data.message, type: 'error' });
            setBtnText("Submit");
          }
        })
        .catch((err) => {
          Bugsnag.notify(err);
        });
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-container-left">
        <div className="signup-form">
          <div className="logo">
            <img className="icon" src={PixieIcon} alt="Pixie Icon" />
            <img src={PixieText} alt="Pixie Text" />
          </div>
          <div className="heading-container">
            <h2 className="title">Reset Password</h2>
            <p>Enter your new password below</p>
          </div>
          {showForm ? (
            <div className="form-container">
              <form onSubmit={onSubmit} className="signup-fields">
                <>
                  <label htmlFor="password" className="label-text">Password*</label>
                  <FormInput
                    type="password"
                    className={`box-input-signup`}
                    name="password"
                    value={password}
                    onChange={handleChange}
                    required
                  />
                  
                  <label htmlFor="confirmPassword" className="label-text">Confirm Password*</label>
                  <FormInput
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleChange}
                    className={`box-input-signup`}
                    required
                  />
                </>
                <div className="buttons">
                  <div className="center tb-margin-20">
                    <CustomButton type="submit" disabled={btnDisabled || isPasswordMismatch}>
                      {btnText}
                    </CustomButton>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            message.msg !== "" && (
              <div className="message-alert">
                <AlertMessage message={message.msg} error={message.type} />
                <div className="buttons">
                  <div className="center tb-margin-20">
                    <CustomButton onClick={() => history.push('/login')}>
                      Click here to Login
                    </CustomButton>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div className="signup-container-right">
        <div className="content-container">
          <div className="speech-bubble-wrapper">
            <SpeechBubble
              className="speech-bubble"
              fillColor={speechBubbleText.type === 'error' ? '#FFB8B8' : '#FFECBF'}
            />
            <div className="speech-bubble-content-wrapper">
              <p className="speech-bubble-content">{speechBubbleText.text}</p>
            </div>
          </div>
          <img
            src={speechBubbleText.type === 'error' ? SadImage : HappyImage}
            className="right-image"
            alt="Illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default VerifyResetPassword;
