/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, useEffect,useRef  } from 'react';
import { getTokenFromLS, parseJSON } from '../lib/helpers';
import { setJobIdsToLS } from '../lib/jobIdToLS';
import { useHistory } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { v4 } from 'uuid';
import { log } from 'logrocket';
import posthog from 'posthog-js'
import { saveData,getData,removeFromIndexedDB } from '../dataFiles/indexedDB';

const CollectionContextProvider = ({ children }) => {
  const history = useHistory();

  const [collectionData, setCollectionData] = useState([]);

  const [noImgFieldsCollections, setNoImgFieldsCollections] = useState([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [message, setMessage] = useState({ msg: '', type: '' });
  const [showPricingBtn, setShowPricingBtn] = useState(false);
  const [showOptimizeBtn, setShowOptimizeBtn] = useState(true);
  const [typeOfOptimization, setTypeOfOptimization] = useState('lossy');
  const [btnText, setBtnText] = useState('Optimize');
  const [appApiKey, setAppApiKey] = useState('');
  const [appSiteId, setAppSiteId] = useState('');
  const [toOptimizeData, setToOptimizeData] = useState(null);
  const [selectedMultiImage,setSelectedMultiImage] = useState([])
  const collectionSuccessRef = useRef(false);

  const addAdditionalFields = obj => {
    const fieldsToAdd = {
      isChecked: false,
      isCheckedItems: [],
      resizeStrategy: null,
      imageSizeToResize: null,
      lossy: true,
      selectValue: 'NULL',
      isEcomProd: false,
      isAutoOptimize: false
    };
    return { ...obj, ...fieldsToAdd };
  };

  const updateCollectionData = async (arr) => {
    // const localStorageData = localStorage.getItem('collectionDataState');
    const optimizationStatus = localStorage.getItem('optimizationStatus')
    const localStorageData = await getData('collectionDataState')
    if(localStorageData && optimizationStatus){
      const parsedData = JSON.parse(localStorageData);
      setCollectionData(parsedData.collectionData)
    }
    else if (Array.isArray(arr)) {
      setCollectionData(arr);
    }
  };

  const loopAndUpdateData = async (arr, siteId) => {
    const updatedData = await arr.map(ele => {
      if (ele.collectionFields.length) {
        ele.collectionFields = ele.collectionFields.map(fieldData => {
          fieldData = addAdditionalFields(fieldData);
          if (ele.collectionDetails.slug === 'sku') {
            fieldData.isEcomProd = true;
            fieldData.prodSiteId = siteId;
          }
          return fieldData;
        });
        ele.itemData = ele.itemData.map(item => {
          item.isChecked = false
          return item
        });
      } else {
        setNoImgFieldsCollections(prev => [...prev, ele.name]);
      }
      return ele;
    }).filter(daEle => daEle.collectionFields.length);
    // const localStorageData = localStorage.getItem('collectionDataState');
    const localStorageData = await getData('collectionDataState')
    const optimizationStatus = localStorage.getItem('optimizationStatus')

    if(localStorageData && optimizationStatus){
      const parsedData = JSON.parse(localStorageData);
      console.log("parsedData.collectionData: ",parsedData.collectionData)
      setCollectionData(parsedData.collectionData)
    }else{
      console.log("parsedData.collectionData: ",updatedData)
      setCollectionData(updatedData);
    }
  };

  const fetchCollections = ({ api, siteId }) => {
    console.log('collection fetch');
    setMessage({ msg: 'Fetching collections from Webflow CMS which contain images.', type: 'info' });
  
    const fetchWithRetry = (url, options) => {
      return fetch(url, options)
        .then(response => {
          if (response.status === 429) {
            return new Promise(resolve => {
              setTimeout(() => {
                resolve(fetchWithRetry(url, options));
              }, 5000);
            });
          } else {
            return response;
          }
        });
    };
  
    const fetchAndHandleResponse = () => {
      fetchWithRetry(
        `${process.env.REACT_APP_API_ENDPOINT}webflow/${api}/collections/${siteId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token': getTokenFromLS()
          }
        }
      )
        .then(parseJSON)
        .then(data => {
          console.log(data);
          const { success, jobId } = data;
          console.log("collectionDetailsArr: ", jobId);
          if (success && jobId) {
            let intervalId = setInterval(() => {
              fetch(
                `${process.env.REACT_APP_API_ENDPOINT}api/optimize-collection/${jobId}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    token: getTokenFromLS(),
                  },
                }
              )
                .then(parseJSON)
                .then(data => {
                  console.log(data);
                  if(success === true){
                    if (data.state === "completed") {
                      clearInterval(intervalId);
                      setMessage({ msg: 'Collections Loaded', type: 'success' });
                      const collectionDetailsArr = data.returnvalue
                      console.log(collectionSuccessRef.current);
                      if (!collectionSuccessRef.current) {
                        loopAndUpdateData(collectionDetailsArr, siteId);
                        collectionSuccessRef.current = true;
                        console.log(collectionSuccessRef.current);
                    }
                    }else if(data.state === "failed"){
                      clearInterval(intervalId);
                      if(data.reason === 'Invalid Site key'){
                        setMessage({
                          msg: 'Token is invalid',
                          type: 'error'
                        });
                      }else{
                        setMessage({
                          msg: 'Unable to find collections in the site. Make sure the site has CMS collections.',
                          type: 'error'
                        });
                      }
                    }
                  }else{
                    clearInterval(intervalId);
                    setMessage({ msg: 'Internal Server Error', type: 'error' });
                  }
                })
                .catch(err => {
                  clearInterval(intervalId);
                  setMessage({ msg: 'This token is invalid, Please add new API key', type: 'error' });
                  Bugsnag.notify(err);
                  console.error("Error fetching job status:", err);
                });
            }, 3000);
            // loopAndUpdateData(collectionDetailsArr, siteId);
          } else if (success === false && data.msg === 'Invalid Site key') {
            setMessage({
              msg: 'Token is invalid',
              type: 'error'
            });
          } else {
            setMessage({
              msg: 'Unable to find collections in the site. Make sure the site has CMS collections.',
              type: 'error'
            });
          }
        })
        .catch(err => {
          setMessage({ msg: 'This token is invalid, Please add new API key', type: 'error' });
          Bugsnag.notify(err);
        });
    };
  
    fetchAndHandleResponse();
  };
  

  useEffect(() => {
    if (noImgFieldsCollections.length) {
      const message = `${noImgFieldsCollections.join(
        ', ')} collections doesn't include any image fields.`;
      setMessage({ msg: message, type: 'info' });
    }
  }, [noImgFieldsCollections]);

  const checkIfAnySelected = () => {
    const isSelected = collectionData.map(ele => ele.collectionFields)
                                     .flat()
                                     .find(obj => obj.isChecked === true);
    return !!isSelected;
  };

  useEffect(() => {
    const isSelected = checkIfAnySelected();
    if (isSelected) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [collectionData]);

  //auto optimze
  const autoOptimizeFields = async (fieldsData, isLossy,itemOptimization) => {
    let autoOptimizedFields = [];
      Object.keys(fieldsData).forEach(fieldName => {
        fieldsData[fieldName].fields.filter(field => field.isAutoOptimize).map(field => {
          return autoOptimizedFields = [
            ...autoOptimizedFields, {
              fieldId: field.id,
              imageSizeToResize: field.imageSizeToResize,
              resizeStrategy: field.resizeStrategy
            }];
        });
      });


    if (autoOptimizedFields && autoOptimizedFields.length) {
      try {
        await fetch(`${process.env.REACT_APP_API_ENDPOINT}api/auto-optimize-fields`, {
          method: 'POST', headers: {
            'Content-Type': 'application/json', token: getTokenFromLS()
          }, body: JSON.stringify({
            localSiteId: appApiKey, autoOptimizedFields, lossy: isLossy
          })
        });
      } catch (error) {
        setMessage({
          msg: 'Something Went Wrong.. Dont worry we are handling at Backend.', type: 'info'
        });
        Bugsnag.notify(error);
      }
    }
  };

  const disableAutoOptimizeField = async (fieldId) => {
    await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}api/disable/${appApiKey}/auto-optimized-field/${fieldId}`,
      {
        method: 'DELETE', headers: {
          'Content-Type': 'application/json', token: getTokenFromLS()
        }
      })
      .then(parseJSON) // parse it to Json
      .then((data) => {
        if (!data.success) return setMessage({
          msg: 'Something Went Wrong.. Dont worry we are handling at Backend.', type: 'error'
        });

        setMessage({ msg: 'Deleted', type: 'success' });
      })
      .catch((err) => {
        setMessage({
          msg: 'Something Went Wrong.. Dont worry we are handling at Backend.', type: 'info'
        });
        Bugsnag.notify(err);
      })
  };

  // Optimize Collection Function
  const addCollectionForOptimization = async (fieldsData,itemOptimization,conversion) => {
    const isLossy = typeOfOptimization === 'lossy';

    await autoOptimizeFields(fieldsData, isLossy,itemOptimization);
    const optimizationId = v4();
    let numberOfOptimization = 0;
    let itemOptimizationCount = 0;
    let bulkOptimizationCount = 0;
    return Promise.all(Object.keys(fieldsData)
                             .map((field) => fetch(
                               `${process.env.REACT_APP_API_ENDPOINT}api/optimize-collection`, {
                                 method: 'POST', headers: {
                                   'Content-Type': 'application/json', token: getTokenFromLS()
                                 }, body: JSON.stringify({
                                   secret: appApiKey,
                                   collection: fieldsData[field],
                                   collectionName: field,
                                   lossy: isLossy,
                                   itemOptimization: itemOptimization,
                                   multiImageIds: selectedMultiImage,
                                   optimizationId: optimizationId,
                                   conversion: conversion
                                 })
                               })
                               .then(parseJSON) // parse it to Json
                               .then(data => {
                                 console.log(data);
                                 if (data.length>0) {
                                   setMessage({
                                     msg: 'You have finished your quota for the month. Upgrade to continue optimizing images or reach out to us via chat to add more quota for the month.',
                                     type: 'error'
                                   });
                                 }
                                 numberOfOptimization = data.numberOfOptimizations;
                                 itemOptimizationCount = data.itemOptimizationCount;
                                 bulkOptimizationCount = data.bulkOptimizationCount;
                                 return data.jobIdsArr || [];
                               })
                               .catch((err) => {
                                 setMessage({
                                   msg: 'Something Went Wrong.. Dont worry we are handling at Backend.',
                                   type: 'info'
                                 });
                                 Bugsnag.notify(err);
                               })))
                  .then((data) => {
                      posthog.capture('OptimizationDone',{
                        $set: {totalOptimization: numberOfOptimization,itemOptimization:itemOptimizationCount,bulkOptimization:bulkOptimizationCount}
                      });
                    if (data.length > 0) {
                      const jobIds = data.flat().filter(ech => ech !== undefined);
                      return jobIds;
                    } else {
                      setMessage({
                        msg: 'Something Went Wrong.. Dont worry we are handling at Backend.',
                        type: 'info'
                      });
                    }
                  })
                  .catch((err) => {
                    setMessage({
                      msg: 'Something Went Wrong.. Dont worry we are handling at Backend.',
                      type: 'info'
                    });
                    Bugsnag.notify(err);
                  });
  };

  const submitChange = async (itemOptimization,conversion) => {
    setIsBtnDisabled(true);
    setBtnText('Please Wait..');
    var filteredItems;
    if (itemOptimization === true) {
      filteredItems = collectionData
        .filter(echCol => {
          const hasCheckFields = echCol.collectionFields.find(field => field.isChecked);
          const hasCheckItems = echCol.itemData.find(item => item.isChecked);
          return !!hasCheckFields || !!hasCheckItems;
        })
        .reduce((acc, ele) => {
          const collectionName = ele.collectionDetails.displayName;
          if (acc[collectionName]) {
            const oldFields = acc[collectionName].fields;
            const newFields = ele.collectionFields.filter(field => field.isChecked);
            acc[collectionName].fields = [...oldFields, ...newFields.map(field => {
              const convertedItems = [];
              ele.itemData.forEach(item => {
                if (field.type === 'Image' && item.fieldData?.[field.slug] && item.fieldData[field.slug].pixieConverted) {
                  convertedItems.push({ itemName: item.fieldData.slug, fileIds: item.fieldData[field.slug].fileId });
                } else if (field.type === 'MultiImage') {
                  const fieldsData = item.fieldData[field.slug];
                  if (fieldsData) {
                    fieldsData.forEach(fieldData => {
                      if (fieldData.pixieConverted) {
                        convertedItems.push({ itemName: item.fieldData.slug, fileIds: fieldData.fileId });
                      }
                    });
                  }
                } else if (field.type === 'RichText') {
                  const fieldsData = item.fieldData[`${field.slug}-pixie-optimized`];
                  if (fieldsData) {
                    fieldsData.forEach(fieldData => {
                      if (fieldData.pixieConverted) {
                        convertedItems.push({ itemName: item.fieldData.slug, urls: [fieldData.url] });
                      }
                    });
                  }
                }
              });
              return { ...field, convertedItems };
            })];
            // Update other fields as needed
  
            // Update items array with newItems directly from itemData
            const itemData = ele.itemData.filter(item => item.isChecked);
            const oldItems = acc[collectionName].items;
            const newItems = itemData.map(item => item.id);
            acc[collectionName].items = [...oldItems, ...newItems];
          } else {
            const itemData = ele.itemData.filter(item => item.isChecked);
            acc[collectionName] = {
              fields: ele.collectionFields.filter(field => field.isChecked).map(field => {
                const convertedItems = [];
                itemData.forEach(item => {
                  if (field.type === 'Image' && item.fieldData?.[field.slug] && item.fieldData[field.slug].pixieConverted) {
                    convertedItems.push({ itemName: item.fieldData.slug, fileIds: item.fieldData[field.slug].fileId });
                  } else if (field.type === 'MultiImage') {
                    const fieldsData = item.fieldData[field.slug];
                    if (fieldsData) {
                      fieldsData.forEach(fieldData => {
                        if (fieldData.pixieConverted) {
                          convertedItems.push({ itemName: item.fieldData.slug, fileIds: fieldData.fileId });
                        }
                      });
                    }
                  } else if (field.type === 'RichText') {
                    const fieldsData = item.fieldData[`${field.slug}-pixie-optimized`];
                    if (fieldsData) {
                      fieldsData.forEach(fieldData => {
                        if (fieldData.pixieConverted) {
                          convertedItems.push({ itemName: item.fieldData.slug, urls: [fieldData.url] });
                        }
                      });
                    }
                  }
                });
                return { ...field, convertedItems };
              }),
              items: itemData.map(item => item.id),
              itemsToOptimize: itemData,
            };
          }
          return acc;
        }, {});
  }else{
      filteredItems = collectionData.filter(echCol => {
        const hasCheckFields = echCol.collectionFields.find(field => field.isChecked);
        return !!hasCheckFields;
    }).reduce((acc, ele) => {
      console.log(acc);
        const collectionName = ele.collectionDetails.displayName;
        if(!acc[collectionName]){
          acc[collectionName] = {};
        }
        acc[collectionName].itemsToOptimize = ele.itemData;
        acc[collectionName].fields = ele.collectionFields.filter(field => field.isChecked).map(field => {
            const optimizedItems = [];
            const convertedItems = [];
            ele.itemData.forEach(item => {
                if (field.type === 'Image' && item.fieldData?.[field.slug]) {
                  if(field.imageSizeToResize === null){
                    if(item.fieldData[field.slug].pixieOptimized){
                      optimizedItems.push({ itemName: item.fieldData.slug, fileIds: item.fieldData[field.slug].fileId });
                    }else if(item.fieldData[field.slug].pixieConverted){
                      convertedItems.push({ itemName: item.fieldData.slug, fileIds: item.fieldData[field.slug].fileId })
                    }
                  }else {
                    let size = field.imageSizeToResize.height ?? field.imageSizeToResize.width;
                    console.log(size);
                    if(item.fieldData[field.slug].resize && size > item.fieldData[field.slug].resize){
                      if(item.fieldData[field.slug].pixieOptimized){
                        optimizedItems.push({ itemName: item.fieldData.slug, fileIds: item.fieldData[field.slug].fileId });
                      }else if(item.fieldData[field.slug].pixieConverted){
                        convertedItems.push({ itemName: item.fieldData.slug, fileIds: item.fieldData[field.slug].fileId })
                      }
                    }
                  }
                } else if (field.type === 'MultiImage') {
                    const fieldsData = item.fieldData[field.slug];
                    let fileIdsOptimized = [];
                    let fileIdsConverted = [];
                    if(field.resizeStrategy === null){
                      if (fieldsData) {
                          fieldsData.forEach(filedData => {
                              if(filedData.pixieConverted){
                                fileIdsConverted.push(filedData.fileId);
                              }
                              else if (filedData.pixieOptimized) {
                                fileIdsOptimized.push(filedData.fileId);
                              }
                          });
                          if (fileIdsOptimized.length > 0) {
                              optimizedItems.push({ itemName: item.fieldData.slug, fileIds: fileIdsOptimized });
                          }
                          if(fileIdsConverted.length > 0){
                            convertedItems.push({ itemName: item.fieldData.slug, fileIds: fileIdsConverted });
                          }
                      }
                    }else {
                      let size = field.imageSizeToResize.height ?? field.imageSizeToResize.width;
                      if (fieldsData) {
                        fieldsData.forEach(filedData => {
                            if(filedData.resize && size > filedData.resize){
                              if(filedData.pixieConverted){
                                fileIdsConverted.push(filedData.fileId);
                              }
                              else if (filedData.pixieOptimized) {
                                fileIdsOptimized.push(filedData.fileId);
                              }
                            }
                        });
                        if (fileIdsOptimized.length > 0) {
                            optimizedItems.push({ itemName: item.fieldData.slug, fileIds: fileIdsOptimized });
                        }
                        if(fileIdsConverted.length > 0){
                          convertedItems.push({ itemName: item.fieldData.slug, fileIds: fileIdsConverted });
                        }
                    }
                    }
    
                } else if (field.type === 'RichText') {
                    const fieldsData = item.fieldData[field.slug + '-pixie-optimized'];
                    let urlsOptimized = [];
                    let urlsConverted = [];
                    if(field.resizeStrategy === null){
                      if (fieldsData) {
                          fieldsData.forEach(filedData => {
                            
                              if (filedData.pixieOptimized) {
                                urlsOptimized.push(filedData.url);
                              }else if (filedData.pixieConverted) {
                                urlsConverted.push(filedData.url);
                              }
                          });
                          if (urlsOptimized.length > 0) {
                              optimizedItems.push({ itemName: item.fieldData.slug, urls: urlsOptimized });
                          }
                          if(urlsConverted.length > 0){
                            convertedItems.push({ itemName: item.fieldData.slug, urls: urlsConverted });
                          }
                      }
                    }else {
                      let size = field.imageSizeToResize.height ?? field.imageSizeToResize.width;
                      if (fieldsData) {
                        fieldsData.forEach(filedData => {
                          if(filedData.resize && size > filedData.resize){
                            if (filedData.pixieOptimized) {
                              urlsOptimized.push(filedData.url);
                            }else if (filedData.pixieConverted) {
                              urlsConverted.push(filedData.url);
                            }
                          }
                        });
                        if (urlsOptimized.length > 0) {
                            optimizedItems.push({ itemName: item.fieldData.slug, urls: urlsOptimized });
                        }
                        if(urlsConverted.length > 0){
                          convertedItems.push({ itemName: item.fieldData.slug, urls: urlsConverted });
                        }
                    }
                    }
                }
            });
            return { ...field, optimizedItems,convertedItems };
        },
        // acc[collectionName].itemsToOptimize = ele.itemData
      );
        return acc;
    }, {});
    
    }


    //send user collection optimized to BE
    // sendCollectionData(filteredItems);

    //Send Request to Optimize Collection
    console.log('FI: ',filteredItems);
    setToOptimizeData(filteredItems);
    let jobsArray;
      const filteredItemsForAPI = Object.entries(filteredItems).reduce((acc, [key, value]) => {
        acc[key] = { ...value };
        delete acc[key].itemsToOptimize;
        return acc;
      }, {});
      console.log('FI After: ',filteredItemsForAPI);
      jobsArray = await addCollectionForOptimization(filteredItemsForAPI,itemOptimization,conversion);

    if (jobsArray && jobsArray.length) {
      // Save to local Storage
      setJobIdsToLS(jobsArray);
      // Push Route using Dynamic Data
      // history.push(`/pixie/optimize-fields?jobs=${jobsArray.join(',')}`);
      return jobsArray;
    } else {
      setBtnText('Error');
      setShowPricingBtn(true);
      setShowOptimizeBtn(false);
    }
  };

  return (<CollectionContext.Provider value={{
    collectionData,
    setAppApiKey,
    setAppSiteId,
    submitChange,
    btnText,
    message,
    isBtnDisabled,
    updateCollectionData,
    loopAndUpdateData,
    fetchCollections,
    disableAutoOptimizeField,
    showOptimizeBtn,
    showPricingBtn,
    typeOfOptimization,
    setTypeOfOptimization,
    toOptimizeData,
    setSelectedMultiImage
  }}>
    {children}
  </CollectionContext.Provider>);
};

export const CollectionContext = createContext();

export default CollectionContextProvider;
