import React from 'react';

import './SecondaryCustomButton.styles.scss';

const CustomButton = ({ children,customClasses, orange, ...otherProps }) => (
    <button className={`${orange ? 'orange-color ' : ''}secondary-custom-button ${customClasses}`} {...otherProps}>
        {children}
    </button>
);

export default CustomButton;