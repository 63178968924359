import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ActionAlerts from "../../components/Alert/Alert.component"
import { parseJSON, checkStatus } from '../../lib/helpers'
import StepThreeImage from '../../images/step3_icon.svg';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import SecondaryCustomButton from '../../components/SecondaryCustomButton/SecondaryCustomButton.component';
import posthog from 'posthog-js'

import './AddApiKey.styles.scss';
import Bugsnag from '@bugsnag/js';

function AddApiKey() {
    const [key, setKey] = useState('');
    const [message, setMessage] = useState({ msg: '', type: "" });
    const [btnText, setBtnText] = useState('Submit');
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);

    const history = useHistory();

    useEffect(() => {
        if(key.length) {
            setIsBtnDisabled(false);
        } else {
            setIsBtnDisabled(true);
        }
    }, [key])

    const onErrorHandle = (msg) => {
        setMessage({ msg: msg || "Unable to authicate API key. Make sure the Key is correct.", type: "error" })
        setBtnText("Retry");
        setKey('');
    }

    const handleSubmit = event => {
        event.preventDefault();
        const token = window.localStorage.getItem('token');
        if (token) {
            setBtnText("Loading...");
            setIsBtnDisabled(true);
            fetch(`${process.env.REACT_APP_API_ENDPOINT}api/user-full-info`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                },
                body: JSON.stringify({ secret: key })
            })
                .then(parseJSON)
                .then(data => {
                    if (data.success && data.siteInfo) {
                        posthog.capture('WebflowSiteAdded',{
                            $set: {totalSitesAdded: data.totalSites}
                        });
                        const {siteUniqueId, siteId} = data.siteInfo;
                        setMessage({ msg: 'Sucessfully Added Key', type: "success" })
                        setKey('');
                        history.push(`/pixie/${siteId}/collections/${siteUniqueId}`);
                    } else {
                        onErrorHandle(data.msg);
                    }
                })
                .catch(err => {
                    onErrorHandle();
                    Bugsnag.notify(err);
                })
        } else {
            history.push('/login');
        }
    }

    const closeBtn = () => {
        history.push('/pixie');
    }

    const handleChange = event => {
        const key = event.target.value;
        setKey(key);
    }

    return (
        <div className="main-block">
            <div className="content-container width-817px">
                <img src={StepThreeImage} className="step-image" alt="Step Image" />
                <div className="add-site-block ">
                    <p className="block-text">Enter API Key to add a Webflow Project</p>
                    <div className='input-container'>
                        <form onSubmit={handleSubmit} className="add-api-block">
                            <div className="api-key-input">
                                <div className="api-key-label">API key</div>
                                <input
                                    type="text"
                                    name="key"
                                    value={key}
                                    onChange={handleChange}
                                    className="api-key-textbox"
                                />
                            </div>
                            <SecondaryCustomButton disabled={isBtnDisabled}>{btnText}</SecondaryCustomButton>
                        </form>
                        <p className='api-key-sub-text'><a href='https://university.webflow.com/article/using-the-webflow-cms-api#:~:text=Go%20to%20your%20project%20settings%20%E2%86%92%20Integrations%20%E2%86%92%20API%20Access' target="blank" className='api-key-steps-link'>Here’s</a> how to find your API key.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default AddApiKey;